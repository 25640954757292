/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 15/04/2024 - 12:09:59
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 15/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TextField, InputAdornment } from '@mui/material';
const customDatePickerStyles = {
  width: '100%',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  padding: '0.375rem 0.75rem',
  fontSize: '1rem',
  lineHeight: '1.5',
  color: '#495057',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  boxShadow: 'none',
};



const CustomDatePicker = ({handleDateChange,startDate,endDate}) => {
  return (
    <div>
    {/* <label htmlFor="customDatePicker">Select Date</label> */}
    <DatePicker
     sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '8rem',
      },
      height: '50px', 
    }}
    id="customDatePicker"
      startDate={startDate}
      endDate={endDate}
      selectsRange
      label='Select Date'
      dateFormat="MM/dd/yyyy"
      onChange={handleDateChange}
      className="custom-react-datepicker" // Apply custom class name
      wrapperClassName="react-datepicker-wrapper" // Apply wrapper class name
      popperClassName="react-datepicker-popper" // Apply popper class name
      style={customDatePickerStyles} // Apply custom styles
      customInput={
        <TextField
          variant="outlined"
          sx={{ borderRadius: '8rem'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
      }

    />
    </div>
  );
};

export default CustomDatePicker;
