/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 16/04/2024 - 18:14:08
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 16/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useEffect, useState } from "react";
import KeenSliderWrapper from "../../@core/styles/libs/keen-slider";
import ApexChartWrapper from "../../@core/styles/libs/react-apexcharts";
import InventorybyTranscation from "../Layouts/dashboards/ecommerce/TopCustomerTable";
import TopCustomerByTransactions from "../Layouts/dashboards/ecommerce/TopCustomerByTransactions";
import CustomerByAmount from "../Layouts/dashboards/ecommerce/EcommerceTable1";
import EcommerceSalesOverview from "../Layouts/dashboards/ecommerce/EcommerceSalesOverview";
import EcommerceActivityTimeline from "../Layouts/dashboards/ecommerce/EcommerceActivityTimeline";
import TopInventoryAmount from "../Layouts/dashboards/ecommerce/TopSellingProducts";
import AnalyticsTotalRevenue from "../Layouts/dashboards/ecommerce/AnalyticsTotalRevenue";
import CardStatisticsVertical from "../../@core/components/card-statistics/card-stats-vertical";
import Icon from "../../@core/components/icon";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import { getRandomProducts } from "../../utils/functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDashbordDataTransactions } from "../../actions/transactionsAction";
import { getInventory } from "../../actions/inventoryAction";
import { getStores } from "../../actions/storeAction";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions } from "../../actions/transactionsAction";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const MainDataList = ({ activeTab, children }) => {
  const dispatch = useDispatch();
  const [topCustomerTableData, setTopCustomerTableData] = useState([]);
  const state = useSelector((state) => state);
  const { TranscationDashbordData } = state?.transactionsDashbordData
  let { inventorys } = state?.inventory;
  let { transactions } = state?.transactions;
  const { stores } = state?.store;
  let totalAmount = 0;
  let totalSuccess = 0;
  let totalRefund = 0;
  let totalFraud = 0;
  let totalPending = 0;
  const storeStats = {};
  const inventoryMap = {};
// ================================================================
  const successTranscationDailySet = new Set();
  const ExpenseTranscationDailySet = new Set();
  let totalAmountDailySet = 0;
  let ExpenseTotalAmountDailySet = 0;
  const refundDailySet = new Set();
  const fraudExpenseDailySet = new Set();

  const successTranscationMonthSet = new Set();
  const ExpenseTranscationMonthSet = new Set();
  let totalAmountMonthSet = 0;
  let ExpenseTotalAmountMonthSet = 0;
  const refundMonthSet = new Set();
  const fraudExpenseMonthSet = new Set();

  const successTranscationYearSet = new Set();
  const ExpenseTranscationYearSet = new Set();
  let totalAmountYearSet = 0;
  let ExpenseTotalAmountYearSet = 0;
  const refundYearSet = new Set();
  const fraudExpenseYearSet = new Set();
  // ================================================================
  inventorys?.forEach((inventory) => {
    inventoryMap[inventory._id] = {
      name: inventory.name,
      inventroyTotalAmount: 0,
      totalTranscation: 0,
      _id: inventory._id
    };
  });
  if (transactions?.length > 0) {
    transactions?.forEach((transaction) => {
      if (transaction.tx_status === "Out Of Stock") {
        return;
      }
      const inventoryId = transaction.inventory_id;
      if (inventoryMap[inventoryId] && transaction.tx_status === "Success") {
        inventoryMap[inventoryId].inventroyTotalAmount += transaction.total;
        inventoryMap[inventoryId].totalTranscation++;
      }
      const storeId = transaction.store_id;
      if (!storeStats[storeId]) {
        const store = stores?.find((item) => item._id === storeId);
        if (store) {
          storeStats[storeId] = {
            storeName: store.name,
            totalAmount: 0,
            totalTX: 0,
            totalSuccess: 0,
            totalRefund: 0,
            totalFraud: 0,
            totalPending: 0
          };
        }
      }

      if (storeStats[storeId]) {
        storeStats[storeId].totalAmount +=
          transaction.tx_status === "Success" ? transaction.total : 0;
        storeStats[storeId].totalTX++;

        switch (transaction.tx_status) {
          case "Success":
            storeStats[storeId].totalSuccess++;
            break;
          case "Refund":
            storeStats[storeId].totalRefund++;
            break;
          case "Fraud":
            storeStats[storeId].totalFraud++;
            break;
          case "Prod Sell Res":
            storeStats[storeId].totalPending++;
            break;
          default:
            break;
        }
      }
    });
  }
  const transcation_inventoryData = Object.keys(inventoryMap).map((storeId) => {
    return {
      _id: storeId,
      name: inventoryMap[storeId].name,
      inventroyTotalAmount: `$${inventoryMap[storeId].inventroyTotalAmount}`,
      totalTranscation: inventoryMap[storeId].totalTranscation
    };
  });
  useEffect(() => {
    if (TranscationDashbordData?.transactions?.["daily_data"]) {
      const successfulTransactions = TranscationDashbordData.transactions?.[
        "daily_data"
      ].filter((transaction) => transaction.tx_status === "Success");
      const aggregatedTransactions = successfulTransactions.reduce(
        (acc, transaction) => {
          const {
            billing: { email, first_name },
            total
          } = transaction;

          if (acc.has(email)) {
            acc.get(email).totalAmount += total;
            acc.get(email).transactionCount += 1;
          } else {
            acc.set(email, {
              email: email,
              first_name: first_name,
              totalAmount: total,
              transactionCount: 1,
              id: acc.size + 1
            });
          }
          return acc;
        },
        new Map()
      );

      const topCustomers = Array.from(aggregatedTransactions.values())
        .sort((a, b) => b.totalAmount - a.totalAmount)
        .slice(0, 10);
      const formattedData = topCustomers.map((customer) => ({
        id: customer.id,
        email: customer.email,
        totalAmount: `$${customer.totalAmount.toFixed(2)}`,
        transactionCount: customer.transactionCount
      }));

      setTopCustomerTableData(formattedData);
    }
  }, [TranscationDashbordData]);

  useEffect(() => {
    dispatch(getDashbordDataTransactions());
    dispatch(getStores());
    dispatch(getInventory());
    dispatch(getTransactions());
  }, [dispatch]);
  const StoresData = Object.values(storeStats).map((storeStat) => ({
    storeName: storeStat.storeName,
    totalAmount: `Total Amount $${storeStat.totalAmount}`,
    totalTX: `Total TX ${storeStat.totalTX}`,
    refund: `Refund ${storeStat.totalRefund}`,
    fraud: `Fraud ${storeStat.totalFraud}`,
    totalPending: `Pending ${storeStat.totalPending}`,
    totalSuccess: `Success ${storeStat.totalSuccess}`
  }));

  const processTransactions = (transactions, stores) => {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0)); // Midnight of today
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago from today
    const startOfYear = new Date(today.getFullYear(), 0, 1); // Start of the current year
    let inventoryMap = {}; 
    let storeStats = {};
    if (transactions?.length > 0) {
      transactions?.forEach((transaction) => {
        if (transaction.tx_status === "Out Of Stock") {
          return;
        }
        const inventoryId = transaction.inventory_id;
        if (inventoryMap[inventoryId] && transaction.tx_status === "Success") {
          inventoryMap[inventoryId].inventroyTotalAmount += transaction.total;
          inventoryMap[inventoryId].totalTranscation++;
        }
        const storeId = transaction.store_id;
        if (!storeStats[storeId]) {
          const store = stores?.find((item) => item._id === storeId);
          if (store) {
            storeStats[storeId] = {
              storeName: store.name,
              totalAmount: 0,
              totalTX: 0,
              totalSuccess: 0,
              totalRefund: 0,
              totalFraud: 0,
              totalPending: 0
            };
          }
        }
        if (storeStats[storeId]) {
          storeStats[storeId].totalAmount +=
            transaction.tx_status === "Success" ? transaction.total : 0;
          storeStats[storeId].totalTX++;
          switch (transaction.tx_status) {
            case "Success":
              storeStats[storeId].totalSuccess++;
              break;
            case "Refund":
              storeStats[storeId].totalRefund++;
              break;
            case "Fraud":
              storeStats[storeId].totalFraud++;
              break;
            case "Prod Sell Res":
              storeStats[storeId].totalPending++;
              break;
            default:
              break;
          }
        }
        // Process daily data
        if (new Date(transaction.date_created) >= startOfToday) {
          if (transaction.tx_status === "Success") {
            successTranscationDailySet.add(transaction._id);
            totalAmountDailySet += transaction.total;
          } else {
            ExpenseTranscationDailySet.add(transaction._id);
            ExpenseTotalAmountDailySet += transaction.total;
          }
          if (transaction.tx_status === "Refund") {
            refundDailySet.add(transaction._id);
          }
          if (transaction.tx_status === "Fraud") {
            fraudExpenseDailySet.add(transaction._id);
          }
        }
        // Process monthly data
        if (new Date(transaction.date_created) >= thirtyDaysAgo) {
          if (transaction.tx_status === "Success") {
            successTranscationMonthSet.add(transaction._id);
            totalAmountMonthSet += transaction.total;
          } else {
            ExpenseTranscationMonthSet.add(transaction._id);
            ExpenseTotalAmountMonthSet += transaction.total;
          }
          if (transaction.tx_status === "Refund") {
            refundMonthSet.add(transaction._id);
          }
          if (transaction.tx_status === "Fraud") {
            fraudExpenseMonthSet.add(transaction._id);
          }
        }
  
        // Process yearly data
        if (new Date(transaction.date_created) >= startOfYear) {
          if (transaction.tx_status === "Success") {
            successTranscationYearSet.add(transaction._id);
            totalAmountYearSet += transaction.total;
          } else {
            ExpenseTranscationYearSet.add(transaction._id);
            ExpenseTotalAmountYearSet += transaction.total;
          }
          if (transaction.tx_status === "Refund") {
            refundYearSet.add(transaction._id);
          }
          if (transaction.tx_status === "Fraud") {
            fraudExpenseYearSet.add(transaction._id);
          }
        }
      });
    }
  };
   processTransactions(transactions, stores);

   const generateInventoryData = (transactions, inventorys) => {
    const today = new Date();
    const startOf28DaysAgo = new Date(today.getTime() - 28 * 24 * 60 * 60 * 1000);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfYear = new Date(today.getFullYear(), 0, 1); 
    let inventoryMap = {};
    inventorys?.forEach((inventory) => {
      inventoryMap[inventory._id] = {
        name: inventory.name,
        inventroyTotalAmount: 0,
        totalTranscation: 0,
        last28Days: {
          totalAmount: 0,
          totalTranscation: 0
        },
        lastMonth: {
          totalAmount: 0,
          totalTranscation: 0
        },
        lastYear: {
          totalAmount: 0,
          totalTranscation: 0
        }
      };
    });
    transactions?.forEach((transaction) => {
      const transactionDate = new Date(transaction?.date_created);
      const inventoryId = transaction?.inventory_id;
      if (!inventoryMap[inventoryId]) return;
      if (transaction?.tx_status === "Success") {
        inventoryMap[inventoryId].inventroyTotalAmount += transaction.total;
        inventoryMap[inventoryId].totalTranscation++;
      }
      if (transactionDate >= startOf28DaysAgo) {
        if (transaction?.tx_status === "Success") {
          inventoryMap[inventoryId].last28Days.totalAmount += transaction.total;
          inventoryMap[inventoryId].last28Days.totalTranscation++;
        }
      }
      if (transactionDate >= startOfMonth) {
        if (transaction?.tx_status === "Success") {
          inventoryMap[inventoryId].lastMonth.totalAmount += transaction.total;
          inventoryMap[inventoryId].lastMonth.totalTranscation++;
        }
      }
      if (transactionDate >= startOfYear) {
        if (transaction?.tx_status === "Success") {
          inventoryMap[inventoryId].lastYear.totalAmount += transaction.total;
          inventoryMap[inventoryId].lastYear.totalTranscation++;
        }
      }
    });
    const data = {
      last28day: Object.keys(inventoryMap).map((inventoryId) => ({
        _id: inventoryId,
        name: inventoryMap[inventoryId].name,
        inventroyTotalAmount: `$${inventoryMap[inventoryId].last28Days.totalAmount.toFixed(2)}`,
        totalTranscation: inventoryMap[inventoryId].last28Days.totalTranscation
      })),
      montly: Object.keys(inventoryMap).map((inventoryId) => ({
        _id: inventoryId,
        name: inventoryMap[inventoryId].name,
        inventroyTotalAmount: `$${inventoryMap[inventoryId].lastMonth.totalAmount.toFixed(2)}`,
        totalTranscation: inventoryMap[inventoryId].lastMonth.totalTranscation
      })),
      yearly: Object.keys(inventoryMap).map((inventoryId) => ({
        _id: inventoryId,
        name: inventoryMap[inventoryId].name,
        inventroyTotalAmount: `$${inventoryMap[inventoryId].lastYear.totalAmount.toFixed(2)}`,
        totalTranscation: inventoryMap[inventoryId].lastYear.totalTranscation
      })),
      allData: Object.keys(inventoryMap).map((inventoryId) => ({
        _id: inventoryId,
        name: inventoryMap[inventoryId].name,
        inventroyTotalAmount: `$${inventoryMap[inventoryId].inventroyTotalAmount.toFixed(2)}`,
        totalTranscation: inventoryMap[inventoryId].totalTranscation
      }))
    };
    return data;
  };
  const transcation_inventory = generateInventoryData(transactions, inventorys);
  return (
    <>
      <ApexChartWrapper>
        <KeenSliderWrapper>
          <Grid container spacing={3} className="match-height">
            <Grid item xs={12} md={9}>
              <EcommerceSalesOverview />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardStatisticsVertical
                stats={totalPending || 0}
                color="primary"
                title="On Hold"
                icon={<Icon icon="mdi:error" />}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Slider {...settings} className="slider-wrapper">
                {getRandomProducts(StoresData).map((item, i) => (
                  <div key={i}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <AnalyticsTotalRevenue
                          TranscationData={totalAmount}
                          title={item?.totalAmount}
                          storeName={item?.storeName}
                          all_daysData={{
                            Earning: [
                              totalAmountDailySet,
                              totalAmountMonthSet,
                              totalAmountYearSet
                            ],
                            Expense: [
                              ExpenseTotalAmountDailySet,
                              ExpenseTotalAmountMonthSet,
                              ExpenseTotalAmountYearSet
                            ]
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <AnalyticsTotalRevenue
                          TranscationData={totalSuccess}
                          title={item?.totalTX}
                          storeName={item?.storeName}
                          all_daysData={{
                            Earning: [
                              successTranscationDailySet?.size,
                              successTranscationMonthSet?.size,
                              successTranscationYearSet?.size
                            ],
                            Expense: [
                              ExpenseTranscationDailySet?.size,
                              ExpenseTranscationMonthSet?.size,
                              ExpenseTranscationYearSet?.size
                            ]
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <AnalyticsTotalRevenue
                          TranscationData={totalRefund}
                          title={item?.refund}
                          storeName={item?.storeName}
                          all_daysData={{
                            Earning: [
                              refundDailySet?.size,
                              refundMonthSet?.size,
                              refundYearSet?.size
                            ],
                            Expense: ""
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <AnalyticsTotalRevenue
                          TranscationData={totalFraud}
                          title={item?.fraud}
                          storeName={item?.storeName}
                          all_daysData={{
                            Earning: [
                              fraudExpenseDailySet?.size,
                              fraudExpenseMonthSet?.size,
                              fraudExpenseYearSet?.size
                            ],
                            Expense: ""
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Slider>
            </Grid>

            <Grid item xs={12} md={6}>
              <TopInventoryAmount
                transcation_inventory={transcation_inventory}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InventorybyTranscation topCustomer={transcation_inventoryData} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomerByAmount AllTranscationData={topCustomerTableData} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TopCustomerByTransactions
                AllTranscationData={topCustomerTableData}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <EcommerceActivityTimeline />
            </Grid>
          </Grid>
        </KeenSliderWrapper>
      </ApexChartWrapper>
    </>
  );
};

export default MainDataList;
