/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 12/04/2024 - 12:02:40
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 12/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useState,useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid } from '@mui/material';
import './AddStorePopup.css'; 
import { useNavigate } from 'react-router-dom';
import { NEW_STORE_RESET } from '../../../constants/storeConstants';
import { clearErrors, addStore } from "../../../actions/storeAction";
import { fetchStoreProduct } from "../../../actions/productAction";
import { useDispatch, useSelector } from 'react-redux';
import BackdropLoader from '../../Layouts/BackdropLoader';
const AddStorePopup = ({ onCancel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.user);
    const { loading, addstore, error,stores } = useSelector((state) => state.store);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [storeurl, setStoreUrl] = useState('');
    const [consumerKey, setConsumerKey] = useState('');
    const [consumerSecret, setConsumerSecret] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [consumerKeyError, setConsumerKeyError] = useState(false);
    const [consumerSecretError, setConsumerSecretError] = useState(false);
    const [open, setOpen] = useState(true);

    const handleSave = (e) => {
        e.preventDefault();
        if (!name) {
            setNameError(true);
            return;
        }
        if (!email) {
            setEmailError(true);
            return;
        }
        if (!storeurl) {
            setUrlError(true);
            return;
        }
        if (!consumerKey) {
            setConsumerKeyError(true);
            return;
        }
        if (!consumerSecret) {
            setConsumerSecretError(true);
            return;
        }
        dispatch(addStore(user?._id, '629743b4c798c617e89d826e',name, email, storeurl, consumerKey, consumerSecret));
    };

    const handleClose = () => {
        onCancel();
        setOpen(false);
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (addstore) {
            enqueueSnackbar("Store Added", { variant: "success" });
            dispatch(fetchStoreProduct(
                user?._id, 
                '629743b4c798c617e89d826e', 
                stores?._id,
                name, 
                email, 
                storeurl, 
                consumerKey, 
                consumerSecret
            ));
            setName('');
            setEmail('');
            setStoreUrl('');
            setConsumerKey('');
            setConsumerSecret('');
            onCancel();
            setOpen(false);
            dispatch({ type: NEW_STORE_RESET });
        }
    }, [
        dispatch, 
        error, 
        addstore, 
        navigate, 
        enqueueSnackbar, 
        user?._id, 
        stores?._id, 
        name, 
        email, 
        storeurl, 
        consumerKey, 
        consumerSecret, 
        onCancel
    ]);
    

    return (
        <Dialog open={open} onClose={handleClose}>
            {loading && <BackdropLoader />}
            <DialogTitle>Add New Store</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Store Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={nameError}
                            helperText={nameError ? 'Store Name is required' : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Store Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={emailError}
                            helperText={emailError ? 'Store Email is required' : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Store URL"
                            variant="outlined"
                            value={storeurl}
                            onChange={(e) => setStoreUrl(e.target.value)}
                            error={urlError}
                            helperText={urlError ? 'Store URL is required' : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Consumer Key"
                            variant="outlined"
                            value={consumerKey}
                            onChange={(e) => setConsumerKey(e.target.value)}
                            error={consumerKeyError}
                            helperText={consumerKeyError ? 'Consumer Key is required' : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Consumer Secret"
                            variant="outlined"
                            value={consumerSecret}
                            onChange={(e) => setConsumerSecret(e.target.value)}
                            error={consumerSecretError}
                            helperText={consumerSecretError ? 'Consumer Secret is required' : ''}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddStorePopup;
