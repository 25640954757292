/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 11/06/2024 - 16:20:59
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/06/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  CardHeader,
  Box,
  InputAdornment,
  IconButton,
  Paper,
} from "@mui/material";
import { updateSmtpData,getSmtpData } from "../../actions/smtpAction";
import { useParams, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const initialData = {
  smtpname: "",
  from: "", // Corrected 'form' to 'from'
  replyto: "",
  cc: "",
  host: "",
  username: "",
  Password: "",
  port: "",
  encryption: "tls",
  status: "Active",
};

const SmtpForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialData);
  const [showPassword, setShowPassword] = useState(false);
  const state = useSelector((state) => state);
  const { allSmtpData } = state?.smtpAllData;
  const { smtpData } = allSmtpData ? allSmtpData : "";
  const { user } = useSelector((state) => state.user);
  const user_id = user ? user?._id : "";
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    setTimeout(() => {
      dispatch(getSmtpData(user_id));
    }, 500);
  }, [dispatch, user_id]);

  const smtpId = params.id;
  const result = smtpData?.find((item) => item._id === smtpId);

  useEffect(() => {
    if (result) {
      setFormData(result);
    }
  }, [result, smtpId]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFormData = (event) => {
    event.preventDefault();
    dispatch(updateSmtpData(formData, smtpId));
     navigate("/settings/smtp");
    enqueueSnackbar("Update SMTP Data successful", { variant: "success" });
  };

  const isDefaultSmtp = formData.smtpname.toLowerCase() === "default";
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <CardHeader title="SMTP UPDATE" />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <form onSubmit={handleFormData} style={{ margin: "5px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="SMTP Name"
                    name="smtpname"
                    value={formData.smtpname}
                    onChange={handleFormChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="From"
                    name="from"
                    value={formData.from}
                    onChange={handleFormChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Reply To"
                    name="replyto"
                    value={formData.replyto}
                    onChange={handleFormChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CC"
                    name="cc"
                    value={formData.cc}
                    onChange={handleFormChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                
                {!isDefaultSmtp && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Host"
                        name="host"
                        value={formData.host}
                        onChange={handleFormChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={handleFormChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Password"
                        name="Password"
                        value={formData.Password}
                        onChange={handleFormChange}
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Port"
                        name="port"
                        value={formData.port}
                        onChange={handleFormChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Encryption</InputLabel>
                        <Select
                          label="Encryption"
                          name="encryption"
                          value={formData.encryption}
                          onChange={handleFormChange}
                        >
                          <MenuItem value="none">No encryption</MenuItem>
                          <MenuItem value="ssl">SSL</MenuItem>
                          <MenuItem value="tls">TLS</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="status"
                      value={formData.status}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                    Submit
                  </Button>
                  <Button type="reset" variant="outlined" color="secondary">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SmtpForm;
