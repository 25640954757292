/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 17:42:52
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../actions/userAction";
// // ** Third Party Imports
import { useSnackbar } from "notistack";
// // ** Icon Imports
import Icon from "../../../src/@core/components/icon";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
const initialData = {
  state: "",
  username: "",
  phone: "",
  address: "",
  zipcode: "",
  lastname: "",
  currency: "",
  firstname: "",
  language: "",
  timezone: "America/Chicago",
  country: "",
  email: "",
  organization: "",
  profile: "/images/avatars/1.png"
};
const ImgStyled = styled("img")(({ theme }) => ({
  width: 120,
  height: 120,
  marginRight: theme.spacing(5),
  borderRadius: theme.shape.borderRadius
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center"
  }
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
    textAlign: "center",
    marginTop: theme.spacing(4)
  }
}));

const Settings = () => {
  // ** State
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userInput, setUserInput] = useState("yes");
  const [formData, setFormData] = useState(initialData);
  const [imgSrc, setImgSrc] = useState("/images/avatars/1.png");
  const [secondDialogOpen, setSecondDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = user;
        const mergedData = { ...initialData, ...fetchedData };
        setFormData(mergedData);
        if (
          mergedData?.profile &&
          mergedData?.profile !== "/images/avatars/1.png"
        ) {
          setImgSrc(mergedData?.profile);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchData();
  }, [user]);
  const handleClose = () => setOpen(false);
  const handleSecondDialogClose = () => setSecondDialogOpen(false);
  const handleConfirmation = (value) => {
    handleClose();
    setUserInput(value);
    setSecondDialogOpen(true);
  };

  const handleInputImageChange = (file) => {
    const reader = new FileReader();
    const { files } = file.target;
    if (files && files.length !== 0) {
      reader.onload = () => setImgSrc(reader.result);
      reader.readAsDataURL(files[0]);
      setFormData({ ...formData, profile: files[0] });
      if (reader.result !== null) {
        setInputValue(reader.result);
      }
    }
  };

  const handleInputImageReset = () => {
    setInputValue("");
  };

  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  const updateProfileHandler = (e) => {
    e.preventDefault();
    try {
      dispatch(updateProfile(formData)); // Dispatch the updateProfile action with form data
      enqueueSnackbar("User Update Successful", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("An error occurred while updating profile", {
        variant: "success"
      });
    }
  };
  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={6}>
        {/* Account Details Card */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Profile Details" />
            <form onSubmit={updateProfileHandler} encType="multipart/form-data">
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ImgStyled src={imgSrc} alt="Profile Pic" />
                  <div>
                    <ButtonStyled
                      component="label"
                      variant="contained"
                      htmlFor="account-settings-upload-image"
                    >
                      Upload New Photo
                      <input
                        hidden
                        type="file"
                        value={inputValue}
                        accept="image/png, image/jpeg"
                        onChange={handleInputImageChange}
                        id="account-settings-upload-image"
                      />
                    </ButtonStyled>
                    <ResetButtonStyled
                      color="secondary"
                      variant="outlined"
                      onClick={handleInputImageReset}
                    >
                      Reset
                    </ResetButtonStyled>
                    <Typography sx={{ mt: 5, color: "text.disabled" }}>
                      Allowed PNG or JPEG. Max size of 800K.
                    </Typography>
                  </div>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      placeholder="John"
                      value={formData.username}
                      onChange={(e) =>
                        handleFormChange("username", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      placeholder="Doe"
                      value={formData.lastname}
                      onChange={(e) =>
                        handleFormChange("lastname", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="email"
                      label="Email"
                      value={formData.email}
                      placeholder="john.doe@example.com"
                      onChange={(e) =>
                        handleFormChange("email", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Address1"
                      placeholder="Address1"
                      value={formData.organization}
                      onChange={(e) =>
                        handleFormChange("organization", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="tel"
                      label="Phone Number"
                      value={formData.phone}
                      placeholder="202 555 0111"
                      onChange={(e) =>
                        handleFormChange("phone", e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            US (+1)
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Address2"
                      placeholder="Address2"
                      value={formData.address}
                      onChange={(e) =>
                        handleFormChange("address", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="State"
                      placeholder="California"
                      value={formData.state}
                      onChange={(e) =>
                        handleFormChange("state", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Zip Code"
                      placeholder="231465"
                      value={formData.zipcode}
                      onChange={(e) =>
                        handleFormChange("zipcode", e.target.value)
                      }
                      inputProps={{
                        maxLength: 6,
                        pattern: "[0-9]*",
                        title: "Zip code should be 5 digits"
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Country</InputLabel>
                      <Select
                        label="Country"
                        value={formData.country}
                        onChange={(e) =>
                          handleFormChange("country", e.target.value)
                        }
                      >
                        <MenuItem value="australia">Australia</MenuItem>
                        <MenuItem value="canada">Canada</MenuItem>
                        <MenuItem value="france">France</MenuItem>
                        <MenuItem value="united-kingdom">
                          United Kingdom
                        </MenuItem>
                        <MenuItem value="united-states">United States</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Language</InputLabel>
                      <Select
                        label="Language"
                        value={formData.language}
                        onChange={(e) =>
                          handleFormChange("language", e.target.value)
                        }
                      >
                        <MenuItem value="arabic">Arabic</MenuItem>
                        <MenuItem value="english">English</MenuItem>
                        <MenuItem value="french">French</MenuItem>
                        <MenuItem value="spanish ">Spanish </MenuItem>
                        <MenuItem value="polish">Polish</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Timezone</InputLabel>
                      <Select
                        label="Timezone"
                        value={formData.timezone}
                        onChange={(e) =>
                          handleFormChange("timezone", e.target.value)
                        }
                      >
                        <MenuItem value="America/Chicago">
                          (GMT-06:00) America/Chicago
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Currency</InputLabel>
                      <Select
                        label="Currency"
                        value={formData.currency}
                        onChange={(e) =>
                          handleFormChange("currency", e.target.value)
                        }
                      >
                        <MenuItem value="usd">USD</MenuItem>
                        <MenuItem value="eur">EUR</MenuItem>
                        <MenuItem value="pound">Pound</MenuItem>
                        <MenuItem value="uae">UAE</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                      Save Changes
                    </Button>
                    <Button
                      type="reset"
                      variant="outlined"
                      color="secondary"
                      onClick={() => setFormData(formData)}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        </Grid>
        {/* Deactivate Account Dialogs */}
        <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
          <DialogContent
            sx={{
              pb: (theme) => `${theme.spacing(6)} !important`,
              px: (theme) => [
                `${theme.spacing(5)} !important`,
                `${theme.spacing(15)} !important`
              ],
              pt: (theme) => [
                `${theme.spacing(8)} !important`,
                `${theme.spacing(12.5)} !important`
              ]
            }}
          >
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                "& svg": { mb: 6, color: "warning.main" }
              }}
            >
              <Icon icon="mdi:alert-circle-outline" fontSize="5.5rem" />
              <Typography>
                Are you sure you would like to cancel your subscription?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              px: (theme) => [
                `${theme.spacing(5)} !important`,
                `${theme.spacing(15)} !important`
              ],
              pb: (theme) => [
                `${theme.spacing(8)} !important`,
                `${theme.spacing(12.5)} !important`
              ]
            }}
          >
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => handleConfirmation("yes")}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleConfirmation("cancel")}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={secondDialogOpen}
          onClose={handleSecondDialogClose}
        >
          <DialogContent
            sx={{
              pb: (theme) => `${theme.spacing(6)} !important`,
              px: (theme) => [
                `${theme.spacing(5)} !important`,
                `${theme.spacing(15)} !important`
              ],
              pt: (theme) => [
                `${theme.spacing(8)} !important`,
                `${theme.spacing(12.5)} !important`
              ]
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                "& svg": {
                  mb: 8,
                  color: userInput === "yes" ? "success.main" : "error.main"
                }
              }}
            >
              <Icon
                fontSize="5.5rem"
                icon={
                  userInput === "yes"
                    ? "mdi:check-circle-outline"
                    : "mdi:close-circle-outline"
                }
              />
              <Typography variant="h4" sx={{ mb: 5 }}>
                {userInput === "yes" ? "Deleted!" : "Cancelled"}
              </Typography>
              <Typography>
                {userInput === "yes"
                  ? "Your subscription cancelled successfully."
                  : "Unsubscription Cancelled!!"}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              px: (theme) => [
                `${theme.spacing(5)} !important`,
                `${theme.spacing(15)} !important`
              ],
              pb: (theme) => [
                `${theme.spacing(8)} !important`,
                `${theme.spacing(12.5)} !important`
              ]
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSecondDialogClose}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default Settings;
