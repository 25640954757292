/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:20:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import axios from "axios";
import {
  FETCH_STORE_PRODUCT_REQUEST,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  VENDOR_PRODUCTS_REQUEST,
  VENDOR_PRODUCTS_SUCCESS,
  VENDOR_PRODUCTS_FAIL,
  CLEAR_ERRORS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  FETCH_STORE_PRODUCT_FAIL
} from "../constants/productConstants";

// Fetch Store Product
export const fetchStoreProduct =
  (
    user_id,
    platform_id,
    store_id,
    name,
    email,
    store_url,
    consumer_key,
    consumer_secret
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_STORE_PRODUCT_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      await axios.post(
        "/api/v1/fetch/products/all",
        {
          user_id,
          platform_id,
          store_id,
          name,
          email,
          store_url,
          consumer_key,
          consumer_secret
        },
        config
      );
    } catch (error) {
      dispatch({
        type: FETCH_STORE_PRODUCT_FAIL,
        payload: error.response.data.message
      });
    }
  };

// Fetch All Store Product
export const fetchAllStoreProduct = (stores) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_STORE_PRODUCT_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post(
      "/api/v1/fetch/storeproducts/all",
      {
        stores
      },
      config
    );
    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: FETCH_STORE_PRODUCT_FAIL,
      payload: error.response.data.message
    });
  }
};

// Get All Products
export const getVendorProducts = () => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_PRODUCTS_REQUEST });

    const { data } = await axios.get("/api/v1/vendor/products");

    dispatch({
      type: VENDOR_PRODUCTS_SUCCESS,
      payload: data.products
    });
  } catch (error) {
    dispatch({
      type: VENDOR_PRODUCTS_FAIL,
      payload: error.response.data.message
    });
  }
};

// Get All Products
export const getStoreProducts = (store) => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_PRODUCTS_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post(
      "/api/v1/fetch/storewise/products/all",
      {
        store
      },
      config
    );
    dispatch({
      type: VENDOR_PRODUCTS_SUCCESS,
      payload: data.products
    });
  } catch (error) {
    dispatch({
      type: VENDOR_PRODUCTS_FAIL,
      payload: error.response.data.message
    });
  }
}

// Update Product ---ADMIN
export const updateProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const config = { header: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/v1/vendor/product/${productData?._id}`,
      productData,
      config
    );
    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message
    });
  }
};

// Get Product Details
export const getProductDetails = (_id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/product/${_id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message
    });
  }
};

// Delete Product ---ADMIN
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message
    });
  }
};

// Clear All Errors
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
