/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 07/06/2024 - 16:08:13
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 07/06/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Paper,
  ButtonGroup
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import { styled } from "@mui/system";
import {
  getTemplates,
  updateTemplateList
} from "../../actions/templatesAction";
import { getInventory } from "../../actions/inventoryAction";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getSmtpData } from "../../actions/smtpAction";
const UpdateTemplatePopUp = () => {
  const navigate = useNavigate();
  const { templates } = useSelector((state) => state.templates);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { allSmtpData } = state?.smtpAllData ? state?.smtpAllData : "";
  const { smtpData } = allSmtpData ? allSmtpData : "";
  const { inventorys } = state.inventory;
  const inventory_itemData = inventorys.reduce((acc, value) => {
    if (value && value.length !== 0 && value.inventory_item !== "undefined") {
      const inventory_fileData = value?.inventory_item || [];
      inventory_fileData.forEach((inventory_item_values) => {
        if (inventory_item_values.file_type === "Files") {
          const item = {
            value: inventory_item_values.value,
            file_type: inventory_item_values.file_type,
            send_first: inventory_item_values.send_first,
            status: inventory_item_values.status,
            expiration_date: inventory_item_values.expiration_date
          };
          acc.push(item);
        }
      });
    }
    return acc;
  }, []);

  const { user } = state.user;
  const user_id = user?._id;

  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getInventory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSmtpData(user_id));
  }, [user_id, dispatch]);

  const [formData, setFormData] = useState({
    name: "",
    type: "Html",
    status: "Active",
    subject: "",
    smtp: "default",
    description: "",
    user_id: user_id,
    default: false,
    attachment: false
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorStateChange = (state) => {
    setEditorState(state);
    setFormData((prevState) => ({
      ...prevState,
      description: draftToHtml(convertToRaw(state.getCurrentContent()))
    }));
  };

  const params = useParams();
  const ApiId = params.id;
  const [checked, setChecked] = useState(false);
  const result = templates?.find((item) => item._id === ApiId);
  useEffect(() => {
    if (result) {
      setFormData(result);
      const content = result.description || "<p>Sample HTML Content</p>";
      setEditorState(EditorState.createWithContent(stateFromHTML(content)));
    }
  }, [result]);

  const handleFormChange = (name, value) => {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0
    }
  }));

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setFormData((prevState) => ({
      ...prevState,
      attachment: event.target.checked
    }));
    if (!checked) {
      setFormData((prevState) => ({
        ...prevState,
        inventory_item: inventory_itemData
      }));
    }
  };

  const updateData = (event) => {
    event.preventDefault();
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    const updatedFormData = { ...formData, description: htmlContent };
    dispatch(updateTemplateList(updatedFormData));
    enqueueSnackbar("Update Successfully", { variant: "success" });
    navigate("/templates");
  };
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <form onSubmit={updateData} encType="multipart/form-data">
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <label htmlFor="empty-text-field">
                <span style={{ color: "gray" }}>Name:</span>
              </label>
              <TextField
                fullWidth
                label=""
                variant="outlined"
                value={formData.name}
                onChange={(e) => handleFormChange("name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formData.attachment ? formData.attachment : checked
                      }
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Send File as Attachment"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="empty-text-field">
                <span style={{ color: "gray" }}>Subject:</span>
              </label>
              <TextField
                fullWidth
                label=""
                variant="outlined"
                value={formData.subject}
                onChange={(e) => handleFormChange("subject", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="empty-text-field">
                <span style={{ color: "gray" }}>Type:</span>
              </label>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="send-type-label"
                  value={formData.type}
                  onChange={(e) => handleFormChange("type", e.target.value)}
                  label=""
                >
                  <MenuItem value="Html">Html</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{ border: "1px solid #ccc", borderRadius: "4px" }}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorStateChange}
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor-content"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <label htmlFor="empty-text-field">
                  <span style={{ color: "gray" }}>Status:</span>
                </label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="send-type-label"
                    value={formData.status}
                    onChange={(e) => handleFormChange("status", e.target.value)}
                    label=""
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">InActive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <label htmlFor="empty-text-field">
                  <span style={{ color: "gray" }}>Smtp:</span>
                </label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="send-type-label"
                    value={formData.smtp}
                    onChange={(e) => handleFormChange("smtp", e.target.value)}
                    label=""
                  >
                    {smtpData && smtpData.length > 0
                      ? smtpData.map((item) => (
                          <MenuItem key={item._id} value={item.smtpname}>
                            {item.smtpname}
                          </MenuItem>
                        ))
                      : [
                          <MenuItem disabled key="no-smtp">
                            No SMTP Available
                          </MenuItem>
                        ]}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", mt: 2 }}>
                <ButtonGroup>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </StyledButton>
                  <StyledButton
                    type="reset"
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </StyledButton>
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default UpdateTemplatePopUp;
