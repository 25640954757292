/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 16:31:50
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import CustomChip from "../../@core/components/mui/chip";
import TableHeader from "../Layouts/Customer/list/TableHeader";
// import AddUserDrawer from "../Layouts/Customer/list/AddUserDrawer";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import { getStores } from "../../actions/storeAction";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import Menu from "@mui/material/Menu";
import {
  clearErrors,
  getTransactions,
  getWoocommerceTransactions,
  getMassActionDataUpdate,
  ResendCode,
} from "../../actions/transactionsAction";
import { FETCH_TRANSCATIONS_RESET } from "../../constants/transactionsConstants";
import {
  Grid,
  Card,
} from "@mui/material";

const ProductCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.name}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const AmountCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.total}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const QuantityCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.quantity}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const TransactionsPending = () => {
  const dispatch = useDispatch();
  const { stores } = useSelector((state) => state.store);
  const {error, transactions } = useSelector(
    (state) => state.transactions
  );
  const transactionsData = transactions.filter(
    (transaction) => transaction.tx_status === 'Out Of Stock' ||
    transaction.tx_status === 'Prod Sell Res' ||
    transaction.tx_status === 'Blacklist'
  );
  const { Fetch } = useSelector((state) => state.fetchtransaction);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState("");
  const [valueorderId, setValueOrderID] = useState("");
  const [valueproduct, setValueProduct] = useState("");
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [searchstatus, setSearchStatus] = useState(false);
  const [updateTranscation, setUpdateTranscation] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [rowData, setRow] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const userStatusObj = {
    Success: "success",
    failed: "error"
  };

  const handleFilter = useCallback(
    (val, type) => {
      setSearchStatus(false);
      setValueOrderID("");
      setValueProduct("");
      var filteredData;
      if (type === "email") {
        const queryLowered = val.toLowerCase();
        setValue(val);
        filteredData = transactionsData.filter((transaction) =>
          transaction?.billing?.email.toLowerCase().includes(queryLowered)
        );
      } else if (type === "orderId") {
        setValueOrderID(val);
        filteredData = transactionsData.filter(
          (transaction) => transaction?.order_id === val
        );
      } else if (type === "product") {
        const queryLowered = val.toLowerCase();
        setValueProduct(val);
        filteredData = transactionsData.filter((transaction) =>
          transaction?.line_items?.some((item) =>
            item.name.toLowerCase().includes(queryLowered)
          )
        );
      } else {
        const queryLowered = val.toLowerCase();
        setValue(val);
        filteredData = transactionsData.filter((transaction) =>
          transaction?.billing?.email.toLowerCase().includes(queryLowered)
        );
      }
      if (filteredData.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setUpdateTranscation(filteredData.length > 0 ? filteredData : []);
    },
    [transactionsData]
  );

  const handleSelectionChange = (selectionModel) => {
    const selectedRows = selectionModel.map((selectedRowId) =>
      transactionsData.find((row) => row._id === selectedRowId)
    );
    setRow(selectedRows.length > 0 ? selectedRows[0] : selectedRows);
    setCheckedData(selectedRows.length > 0 ? selectedRows : selectedRows);
  };

  const handleMaskAction = (actionType) => {
    let updateStatus;
    if (actionType === "Cancel") {
      updateStatus = "Cancelled";
    } else if (actionType === "Buyer") {
      updateStatus = "Success";
    } else if (actionType === "Refund") {
      updateStatus = "Refund";
    } else if (actionType === "Fraud") {
      updateStatus = "Fraud";
    }
    const filteredData = checkedData.filter((transaction) => {
     if (
        transaction.tx_status === "Out Of Stock" ||
        transaction.tx_status === "Prod Sell Res" ||
        transaction.tx_status === "Blacklist"
      ) {
        return true;
      } else {
        transaction.tx_status = updateStatus;
        return true;
      }
    });
    dispatch(
      getMassActionDataUpdate(
        filteredData.length > 0 ? filteredData : [],
        updateStatus
      )
    );
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const toggleAddUserDrawer = () => setAddUserOpen(!addUserOpen);

  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccept = () => {
    dispatch(ResendCode(rowData?._id, rowData?.billing?.email));
    handleRowOptionsClose();
  };


  const RowOptions = ({ _id, status }) => {
    const handleRowOptionsClose = () => {
      setAnchorEl(null);
    };
    const handleSendReplacement = () => {
      handleRowOptionsClose();
    };
    const handleResend = () => {
      handleRowOptionsClose();
    };
    const handleShowDetails = () => {
      handleRowOptionsClose();
    };

    return (
      <>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={rowOptionsOpen}
          onClose={handleRowOptionsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{ style: { minWidth: "8rem" } }}
        >
          <MenuItem onClick={handleSendReplacement} sx={{ "& svg": { mr: 2 } }}>
            Send replacement
          </MenuItem>
          <MenuItem onClick={handleResend} sx={{ "& svg": { mr: 2 } }}>
            Resend
          </MenuItem>
          <MenuItem onClick={handleShowDetails} sx={{ "& svg": { mr: 2 } }}>
            Show details
          </MenuItem>
        </Menu>
      </>
    );
  };


  const handleExportAction = () => {
    let csvContent = "";
    csvContent +=
      "Plateform,Store,Order ID,Email,Product,Amount,Qty,Time,Status\n";
    var ExportData =
    searchstatus === true
    ? []
    : updateTranscation.length > 0
    ? updateTranscation.slice(
        paginationModel.page * paginationModel.pageSize,
        (paginationModel.page + 1) * paginationModel.pageSize
      )
    : transactionsData.slice(
        paginationModel.page * paginationModel.pageSize,
        (paginationModel.page + 1) * paginationModel.pageSize
      )
    ExportData.forEach((transaction) => {
      var store;
      if (stores) {
        store = stores.find((store) => store._id === transaction.store_id);
      } else {
        store = "";
      }
      var Amount = "";
      var Product = "";
      var Quantity = "";

      transaction.line_items.forEach((item) => {
        Amount = item?.total ?? 0;
        Product = item?.name ?? "";
        Quantity = item?.quantity ?? "";
      });

      csvContent += `${"Woocommerce"},${store ? store?.name : ""},${
        transaction.order_id
      },${transaction.billing.email},${Product},${Amount},${Quantity},${
        transaction && transaction.date_created
          ? format(new Date(transaction.date_created), "yyyy-MM-dd HH:mm:ss")
          : ""
      },${transaction.tx_status}\n`;
    });
    // Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create URL for Blob
    const url = window.URL.createObjectURL(blob);
    // Create anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "Pendingtransactions.csv";
    // Simulate click on anchor element
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
  };

  const columns = [
    {
      flex: 0.25,
      field: "plateform",
      minWidth: 50,
      headerName: "Platform",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {/* {row.plateform} */}
                Woocommerce
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.3,
      minWidth: 50,
      field: "store",
      headerName: "Store",
      renderCell: ({ row }) => {
        var store;
        if (stores) {
          store = stores.find((store) => store._id === row.store_id);
        } else {
          store = "";
        }
        return (
          <Typography variant="body2">{store ? store?.name : ""}</Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 40,
      field: "order_id",
      headerName: "Order ID",
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.order_id}</Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: "billing",
      headerName: "Email",
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.billing?.email}</Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "line_items",
      headerName: "Product",
      renderCell: ({ row }) => <ProductCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 40,
      field: "total",
      headerName: "Amount",
      renderCell: ({ row }) => <AmountCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 10,
      field: "quantity",
      headerName: "Qty",
      renderCell: ({ row }) => <QuantityCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 155,
      field: "date_created",
      headerName: "Time",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row && row.date_created
            ? format(new Date(row.date_created), "yyyy-MM-dd HH:mm:ss")
            : ""}
        </Typography>
      )
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: "tx_status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <>
            <CustomChip
              onClick={handleRowOptionsClick}
              skin="light"
              size="small"
              label={row.tx_status}
              color={
                row.tx_status === "Out Of Stock" ||
                row.tx_status === 'Prod Sell Res' ||
                row.tx_status === 'Blacklist'
                  ? "error"
                  : userStatusObj[row.tx_status]
              }
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { lineHeight: "18px" }
              }}
            />
            <RowOptions _id={row._id} status={row.tx_status} />
          </>
        );
      }
    }
  ];

  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };

  useEffect(() => {
    dispatch(getStores());
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getTransactions());
  }, [error, dispatch, enqueueSnackbar]);

  useEffect(() => {
    dispatch(getWoocommerceTransactions());
  }, [dispatch]);

  if (Fetch) {
    dispatch(getTransactions());
    dispatch({ type: FETCH_TRANSCATIONS_RESET });
  }

  return (
    <>
      <RouterBreadcrumbs />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>          
            <TableHeader
              value={value}
              valueorderId={valueorderId}
              valueproduct={valueproduct}
              handleFilter={handleFilter}
              handleExportAction={handleExportAction}
              toggle={toggleAddUserDrawer}
              handleMaskAction={handleMaskAction}
              handleAccept={handleAccept}
            />
            <div style={{ fontSize: "5px" }}>
              <DataGrid
                autoHeight
                rows={
                  searchstatus === true
                    ? []
                    : updateTranscation.length > 0
                    ? updateTranscation.slice(
                        paginationModel.page * paginationModel.pageSize,
                        (paginationModel.page + 1) * paginationModel.pageSize
                      )
                    : transactionsData.slice(
                        paginationModel.page * paginationModel.pageSize,
                        (paginationModel.page + 1) * paginationModel.pageSize
                      )
                }
                getRowId={(row) => row._id}
                columns={columns}
                checkboxSelection
                onSelectionModelChange={(selectionModel) =>
                  handleSelectionChange(selectionModel)
                }
                disableRowSelectionOnClick
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onPageChange={(page) => handlePageChange(page)}
                getRowClassName={(params) =>
                  params.row.line_items.length > 1 ? "single-item-row" : ""
                }
                sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
              />
            </div>
          </Card>
        </Grid>
        {/* <AddUserDrawer open={addUserOpen} toggle={toggleAddUserDrawer} /> */}
      </Grid>
    </>
  );
};

export default TransactionsPending;
