/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 04/06/2024 - 13:42:10
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/06/2024
    * - Author          : admin
    * - Modification    : 
**/
import {
    DOMAINIP_API_REQUEST,
    DOMAINIP_API_SUCCESS,
    DOMAINIP_API_FAIL,

    GETDOMAINIPData_API_REQUEST,
    GETDOMAINIPData_API_SUCCESS,
    GETDOMAINIPData_API_FAIL,

    UPDATE_DOMAIN_API_REQUEST,
   UPDATE_DOMAIN_API_SUCCESS,
   UPDATE_DOMAIN_API_FAIL,

   DELETE_DOMAIN_API_REQUEST,
   DELETE_DOMAIN_API_SUCCESS,
   DELETE_DOMAIN_API_FAIL
  } from "../constants/domainIpConstants.js";
  import axios from "axios";
  export const DomainipApi = (Domainipdata) => async (dispatch) => {
    console.log("Action",Domainipdata)
    try {
      dispatch({ type: DOMAINIP_API_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/Domainipapi", Domainipdata, config);
      dispatch({
        type: DOMAINIP_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: DOMAINIP_API_FAIL,
        payload: error.response.data.message
      });
    }
  };


  export const getDomainApiData = () => async (dispatch) => {
    try {
      dispatch({ type: GETDOMAINIPData_API_REQUEST });
  
      const { data } = await axios.get("/api/v1/DomainIpapi/all");
  
      dispatch({
        type: GETDOMAINIPData_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: GETDOMAINIPData_API_FAIL,
        payload: error.response.data.message
      });
    }
  };

  export const updateDomainIpData = (updateData,DomainIpId) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DOMAIN_API_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/updateDomainApi/"+DomainIpId, updateData, config);
  
      dispatch({
        type: UPDATE_DOMAIN_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: UPDATE_DOMAIN_API_FAIL,
        payload: error.response.data.message
      });
    }
  };
  
  export const deleteDomainAPiData = (DomainIpId) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_DOMAIN_API_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/deleteDomainApi/"+DomainIpId, config);
  
      dispatch({
        type: DELETE_DOMAIN_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: DELETE_DOMAIN_API_FAIL,
        payload: error.response.data.message
      });
    }

  };
  