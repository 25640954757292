/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 15/04/2024 - 12:45:27
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 15/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Box,
  Card,
  CardContent,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import { clearErrors, addTransactions } from "../../actions/transactionsAction";
import { getInventory } from "../../actions/inventoryAction";
import { getStores } from "../../actions/storeAction";
import { getTemplates } from "../../actions/templatesAction";
import { getStoreProducts } from "../../actions/productAction";
import BackdropLoader from "../Layouts/BackdropLoader";
import { getIntegrationsStore } from "../../actions/userAction";
const NewTransactions = ({ apiData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { products } = useSelector((state) => state.products);
  const { stores } = useSelector((state) => state.store);
  const { templates } = useSelector((state) => state.templates);
  const { getIntegrationData } = useSelector((state) => state.getintegration);
  const { success, intregationData } = getIntegrationData;
  const { error, loading, addtransactions } = useSelector(
    (state) => state.transactions
  );
  const { inventorys } = useSelector((state) => state.inventory);
  const [email, setEmail] = useState("");
  const [inventoryValue, setInventory] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [emailTemplatevalue, setEmailTemplate] = useState("");
  const [platform, setPlatform] = useState("629743b4c798c617e89d826e");
  const [store, setStore] = useState([]);
  const [body, setBody] = useState("");
  const [emailError, setEmailError] = useState("");
  
  let isDisabled = false;
  if(intregationData && success){
      intregationData.forEach(item => {
      isDisabled = item?.status;
    })     
  } 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      dispatch(
        addTransactions(
          email,
          inventoryValue,
          itemName,
          quantity,
          price,
          emailTemplatevalue,
          body,
          platform,
          store
        )
      );
    }
  };
  useEffect(() => {
    dispatch(getInventory());
    dispatch(getStores());
    dispatch(getTemplates());
    dispatch(getIntegrationsStore());
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (addtransactions) {
      setEmail("");
      setItemName("");
      setQuantity();
      setPrice();
      setEmailTemplate("");
      setBody("");
      setInventory("");
      setStore([]);
      navigate(`/transactions`);
    }
  }, [error, addtransactions, navigate, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (store.length > 0) {
      dispatch(getStoreProducts(store));
    }
  }, [store, dispatch]);

  return (
    <>
      <RouterBreadcrumbs />
      {loading && <BackdropLoader />}
      <Card elevation={3}>
        <CardContent>
          <Box p={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={Boolean(emailError)}
                    helperText={emailError}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="product-select-label">Inventory</InputLabel>
                    <Select
                      labelId="product-select-label"
                      label="Product"
                      value={inventoryValue}
                      onChange={(e) => setInventory(e.target.value)}
                      required
                    >
                      {inventorys &&
                        inventorys.map((inventory) => {
                          const availableItems =
                            inventory.inventory_item.filter(
                              (item) => item.status === "available"
                            );
                          return (
                            <MenuItem key={inventory._id} value={inventory._id}>
                              {inventory.name} ({availableItems.length})
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="template-select-label">Store</InputLabel>
                    <Select
                      labelId="template-select-label"
                      label="Email Template"
                      value={store.length > 0 ? store[0] : ""}
                      onChange={(e) => setStore([e.target.value])}
                      required
                    >
                      {stores &&
                        stores.map((store) => (
                          <MenuItem key={store._id} value={store}>
                            {store?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="product-select-label">Product</InputLabel>
                    <Select
                      labelId="product-select-label"
                      label="Product"
                      value={itemName}
                      onChange={(e) => setItemName(e.target.value)}
                      required
                    >
                      {products &&
                        products.map((product) => {
                          return (
                            <MenuItem key={product._id} value={product._id}>
                              {product.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Price"
                    variant="outlined"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="template-select-label">
                      Email Template
                    </InputLabel>
                    <Select
                      labelId="template-select-label"
                      label="Email Template"
                      value={emailTemplatevalue}
                      onChange={(e) => setEmailTemplate(e.target.value)}
                      required
                    >
                      {templates &&
                        templates.map((store) => {
                          return (
                            <MenuItem key={store._id} value={store._id}>
                              {store.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="template-select-label">Platform</InputLabel>
                    <Select
                      labelId="template-select-label"
                      label="Platform"
                      value={platform}
                      onChange={(e) => setPlatform(e.target.value)}
                      required
                    >
                      <MenuItem key={platform} value={platform}>
                        Woocommerce
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Body"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default NewTransactions;
