/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 14:23:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const ADD_STORE_REQUEST = "ADD_STORE_REQUEST";
export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";
export const ADD_STORE_FAIL = "ADD_STORE_FAIL";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";
export const NEW_STORE_RESET="NEW_STORE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";