/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:16:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/

import {
  ADD_CUSTOMERS_REQUEST,
  UPDATE_CUSTOMERS_REQUEST,
  ADD_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMERS_SUCCESS,
  DELETE_CUSTOMERS_SUCCESS,
  ADD_CUSTOMERS_FAIL,
  UPDATE_CUSTOMERS_FAIL,
  DELETE_CUSTOMERS_FAIL,
  CLEAR_ERRORS,
  NEW_CUSTOMERS_RESET,
} from "../constants/customerConstants";

export const customerReducer = (
  state = { customers: [] },
  { type, payload }
) => {
  switch (type) {
    case ADD_CUSTOMERS_REQUEST:
    case UPDATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_CUSTOMERS_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        addcustomers: payload?.addcustomers,
        customers: payload.customers
      };
    case UPDATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: payload.success
      };
    case DELETE_CUSTOMERS_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case ADD_CUSTOMERS_FAIL:
    case UPDATE_CUSTOMERS_FAIL:
    case DELETE_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case NEW_CUSTOMERS_RESET:
      return {
        loading: false,
        addcustomers: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};


