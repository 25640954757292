/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 15/04/2024 - 12:24:12
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 15/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { Box, Button, MenuItem, Menu } from "@mui/material";
import Icon from "../../../../@core/components/icon"; // Import the Icon component
import { useState } from "react";
const TableHeader = ({
  value,
  valueorderId,
  valueproduct,
  handleFilter,
  handleReset,
  toggle,
  handleMaskAction,
  handleExportAction,
  handleAccept
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        p: 3,
        pb: 2,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Button
          sx={{ mr: 1, mb: 2, marginTop: "10px" }}
          color="secondary"
          variant="outlined"
          startIcon={<Icon icon="mdi:export-variant" fontSize={20} />}
          onClick={() => {
            handleExportAction();
            setAnchorEl(null);
          }}
        >
          Export
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          onClick={handleMenuOpen}
        >
          Mass Action
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleMaskAction("Buyer");
              setAnchorEl(null);
            }}
            sx={{ "& svg": { mr: 2 } }}
          >
            Accept buyer
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMaskAction("Cancel");
              setAnchorEl(null);
            }}
            sx={{ "& svg": { mr: 2 } }}
          >
            Mark as cancel
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMaskAction("Refund");
              setAnchorEl(null);
            }}
            sx={{ "& svg": { mr: 2 } }}
          >
            Mark as Refund
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMaskAction("Fraud");
              setAnchorEl(null);
            }}
            sx={{ "& svg": { mr: 2 } }}
          >
            Mark as Fraud
          </MenuItem>
        </Menu>
        {handleReset && (
        <Button
          sx={{ mb: 1 }}
          onClick={() => handleReset()}
          variant="contained"
        >
          Reset
        </Button>
        )}
      </Box>
    </Box>
  );
};

export default TableHeader;
