/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 11/04/2024 - 15:51:15
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 11/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MetaData from "../Layouts/MetaData";
import Icon from "../../@core/components/icon";
import BackdropLoader from "../Layouts/BackdropLoader";
import AddInventoryPopup from "../Layouts/Inventory/AddInventoryPopup";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import CustomChip from "../../@core/components/mui/chip";
import Modal from "@mui/material/Modal";
import { Button, Box, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  clearErrors,
  getInventory,
  deleteInventory,
  updateInventory
} from "../../actions/inventoryAction";
import { NEW_INVENTORY_RESET } from "../../constants/inventoryConstants";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
const Inventory = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, inventorys, isUpdated, isDeleted } = useSelector(
    (state) => state.inventory
  );
  const [openModal, setOpenModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const handleSaveInventory = (data) => {
    setOpenModal(false);
  };

  const RowOptions = ({ id, row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const rowOptionsOpen = Boolean(anchorEl);
    const [openUpdateModal, setUpdateOpenModal] = useState(false);
    const [name, setName] = useState(row?.name);
    const [sku, setSKU] = useState(row?.sku);
    const [type, setType] = useState(row?.type);
    const dispatch = useDispatch();
    const handleRowOptionsClose = () => {
      setAnchorEl(null);
    };
    const handleRowOptionsClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const updateCode = () => {
      navigate(`/update/inventory/${id}`);
      handleRowOptionsClose();
    };
  
    const deleteInventoryHandler = () => {
      dispatch(deleteInventory(id));
      handleRowOptionsClose();
    };
  
    const editInventory = () => {
      setUpdateOpenModal(true);
      handleRowOptionsClose();
    };
  
    const handleSave = () => {
      const updatedInventory = { id, name, sku, type };
       dispatch(updateInventory(updatedInventory));
      setUpdateOpenModal(false);
    };
  
    return (
      <>
        <IconButton size="small" onClick={handleRowOptionsClick}>
          <Icon icon="mdi:dots-vertical" />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={rowOptionsOpen}
          onClose={handleRowOptionsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{ style: { minWidth: "8rem" } }}
        >
          <MenuItem sx={{ "& svg": { mr: 2 } }} onClick={editInventory}>
            <Icon icon="mdi:pencil" fontSize={20} />
            Edit
          </MenuItem>
          <MenuItem sx={{ "& svg": { mr: 2 } }} onClick={updateCode}>
            <Icon icon="mdi:code-tags" fontSize={20} />
            Codes
          </MenuItem>
          <MenuItem
            sx={{ "& svg": { mr: 2 } }}
            onClick={deleteInventoryHandler}
          >
            <Icon icon="mdi:delete-outline" fontSize={20} />
            Delete
          </MenuItem>
        </Menu>
        <Modal
          open={openUpdateModal}
          onClose={() => setUpdateOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-container" style={{ width: "400px" }}>
            <div className="popup-inner">
              <Typography variant="h6">Update Inventory</Typography>
              <div className="input-container">
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="input-container">
                <TextField
                  label="SKU"
                  value={sku}
                  onChange={(e) => setSKU(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="input-container">
                <FormControl fullWidth>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    labelId="type-label"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value="Unique - single unique code per customer">
                      Unique - single unique code per customer
                    </MenuItem>
                    <MenuItem value="Recursive - codes are send in a loop">
                      Recursive - codes are send in a loop
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  style={{ marginRight: "8px" }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setUpdateOpenModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 129,
      flex: 0.5,
      renderCell: (params) => {
        return <div className="flex items-center gap-2">{params.row.name}</div>;
      }
    },
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => {
        return <div className="flex items-center gap-2">{params.row.sku}</div>;
      }
    },
    {
      field: "inventory_item",
      headerName: "Available",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => {
        const availableItems = params.row.inventory_item.filter(
          (item) => item.status === "available"
        );
        const inventoryLength = availableItems.length;
        return (
          <>
            {inventoryLength === 0 ? (
              <div className="text-center">
                <span>0</span>
                <br />
                <CustomChip
                  skin="light"
                  size="small"
                  label="Out Of Stock"
                  color="error"
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    "& .MuiChip-label": { lineHeight: "18px" }
                  }}
                />
              </div>
            ) : (
              <span>{inventoryLength}</span>
            )}
          </>
        );
      }
    },

    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-2">
            {params?.row?.type?.split("-")[0]}
          </div>
        );
      },
      headerAlign: "left",
      align: "left"
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 120,
      flex: 0.5,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <RowOptions id={params.row.id} row={params.row} />
        );
      }
    }
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isDeleted) {
      enqueueSnackbar("Inventory Deleted", { variant: "success" });
      dispatch({ type: NEW_INVENTORY_RESET });
    }
    dispatch(getInventory());
  }, [dispatch,enqueueSnackbar,error, isDeleted, isUpdated]);

  return (
    <>
      <MetaData title="Inventory | EZDZSender" />
      {loading && <BackdropLoader />}
      <RouterBreadcrumbs />
      <Box
        bgcolor="white"
        borderRadius="xl"
        boxShadow={3}
        p={2}
        height="auto"
        mb={2}
      >
        <CardHeader
          title="Inventory"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              New Inventory
            </Button>
          }
        />
        <DataGrid
          autoHeight
          rows={
            inventorys && inventorys.length > 0
              ? inventorys.map((store) => ({ ...store, id: store._id }))
              : []
          }
          getRowId={(row) => row._id}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
        />
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddInventoryPopup
          onSave={handleSaveInventory}
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </>
  );
};

export default Inventory;
