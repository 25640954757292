/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 14:59:00
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/

// ** MUI Components
import { useEffect,useState } from 'react'
import { Link,useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Box, CardContent, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Checkbox, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import { getStores } from "../../actions/storeAction";
// ** Icon Imports
import Icon from '../../@core/components/icon'

// ** Configs
import themeConfig from '../../configs/themeConfig'

// ** Layout Import
import BlankLayout from '../../@core/layouts/BlankLayout'


// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: 480 }
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))
    const Login = () => {
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const { enqueueSnackbar } = useSnackbar();
      const location = useLocation();
        // ** State
        const [values, setValues] = useState({
          password: '',
          email: '',
          showPassword: false,
          emailError: false,
          passwordError: false
        })
      const [rememberMe,setRememberMe]=useState(false);
        const { loading, isAuthenticated, error } = useSelector((state) => state.user);

        // ** Hook
        const theme = useTheme()
      
        const handleChange = prop => event => {
          setValues({ ...values, [prop]: event.target.value })
        }
      
        const handleClickShowPassword = () => {
          setValues({ ...values, showPassword: !values.showPassword })
        }
        const handleLogin = (e) => {
          // navigate(`/admin/dashboard`)
          e.preventDefault()
          if (!values.email || !values.password) {
            setValues({ ...values, emailError: !values.email, passwordError: !values.password });
            return;
          }
          if (values.password.length < 8) {
            enqueueSnackbar("Password length must be atleast 8 characters", { variant: "warning" });
            return;
          }
          dispatch(loginUser(values));

          // Store remember me preference in browser storage
          if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
          } else {
            localStorage.removeItem('rememberMe');
          }
      }

      const handleRememberMeChange =() => {
        setRememberMe(!rememberMe)
      }
      
    const redirect = location.search ? location.search.split("=")[1] : "admin/dashboard";

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
             dispatch(getStores());
            navigate(`/${redirect}`)
        }
    }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

        return (
          <>
          <MetaData title="Login | EZDZsender" />
          {loading && <BackdropLoader />}
          <Box className='content-center'>
            <Card sx={{ zIndex: 1 }}>
              <CardContent sx={{ p: theme => `${theme.spacing(5, 7, 2.5)} !important` }}>
                <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg width={47} fill='none' height={26} viewBox='0 0 268 150' xmlns='http://www.w3.org/2000/svg'>
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fill={theme.palette.primary.main}
                      transform='matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)'
                    />
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fillOpacity='0.4'
                      fill='url(#paint0_linear_7821_79167)'
                      transform='matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)'
                    />
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fill={theme.palette.primary.main}
                      transform='matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)'
                    />
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fill={theme.palette.primary.main}
                      transform='matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)'
                    />
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fillOpacity='0.4'
                      fill='url(#paint1_linear_7821_79167)'
                      transform='matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)'
                    />
                    <rect
                      rx='25.1443'
                      width='50.2886'
                      height='143.953'
                      fill={theme.palette.primary.main}
                      transform='matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)'
                    />
                    <defs>
                      <linearGradient
                        y1='0'
                        x1='25.1443'
                        x2='25.1443'
                        y2='143.953'
                        id='paint0_linear_7821_79167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop />
                        <stop offset='1' stopOpacity='0' />
                      </linearGradient>
                      <linearGradient
                        y1='0'
                        x1='25.1443'
                        x2='25.1443'
                        y2='143.953'
                        id='paint1_linear_7821_79167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop />
                        <stop offset='1' stopOpacity='0' />
                      </linearGradient>
                    </defs>
                  </svg>
                  <Typography variant='h6' sx={{ ml: 2, lineHeight: 1, fontWeight: 700, fontSize: '1.5rem !important' }}>
                    {themeConfig.templateName}
                  </Typography>
                </Box>
                <Box sx={{ mb: 4 }}>
                  <Typography variant='h5' sx={{ mb: 1.5, fontWeight: 600, letterSpacing: '0.18px' }}>
                    {`Welcome to ${themeConfig.templateName}! 👋🏻`}
                  </Typography>
                  <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
                </Box>
                <form autoComplete='on' onSubmit={handleLogin}>
                  <TextField  
                  onChange={handleChange('email')} 
                  value={values.email}  
                  error={values.emailError}
                  autoFocus fullWidth 
                  id='email' 
                  label='Email' 
                  // type="email"
                  sx={{ mb: 4 }}
                  // required
                  />
                  <FormControl fullWidth error={values.passwordError}>
                    <InputLabel htmlFor='auth-login-password'>Password</InputLabel>
                    <OutlinedInput
                      label='Password'
                      value={values.password}
                      id='auth-login-password'
                      onChange={handleChange('password')}
                      type={values.showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onClick={handleClickShowPassword}
                            onMouseDown={e => e.preventDefault()}
                            aria-label='toggle password visibility'
                          >
                            <Icon icon={values.showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Box
                    sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                  >
                    <FormControlLabel
                      label='Remember Me'
                      control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} />}
                      sx={{ '& .MuiFormControlLabel-label': { color: 'text.primary' } }}
                    />
                    <Typography
                      variant='body2'
                      component={Link}
                      to='/password/forgot'
                      sx={{ color: 'primary.main', textDecoration: 'none' }}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>
                  <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 2 }}>
                    Login
                  </Button>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Typography sx={{ mr: 2, color: 'text.secondary' }}>New on our platform?</Typography>
                    <Typography
                      component={Link}
                      to='/register'
                      sx={{ color: 'primary.main', textDecoration: 'none' }}
                    >
                      Create an account
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      '& .MuiDivider-wrapper': { px: 4 },
                      mt: theme => `${theme.spacing(2)} !important`,
                      mb: theme => `${theme.spacing(2.5)} !important`
                    }}
                  >
                    or
                  </Divider>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton to='/' component={Link} sx={{ color: '#497ce2' }} onClick={e => e.preventDefault()}>
                      <Icon icon='mdi:facebook' />
                    </IconButton>
                    <IconButton to='/' component={Link} sx={{ color: '#1da1f2' }} onClick={e => e.preventDefault()}>
                      <Icon icon='mdi:twitter' />
                    </IconButton>
                    <IconButton
                      to='/'
                      component={Link}
                      onClick={e => e.preventDefault()}
                      sx={{ color: theme => (theme.palette.mode === 'light' ? '#272727' : 'grey.300') }}
                    >
                      <Icon icon='mdi:github' />
                    </IconButton>
                    <IconButton to='/' component={Link} sx={{ color: '#db4437' }} onClick={e => e.preventDefault()}>
                      <Icon icon='mdi:google' />
                    </IconButton>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
          </>
        )
      }
      Login.getLayout = page => <BlankLayout>{page}</BlankLayout>
      
      export default Login
