/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 10/04/2024 - 14:42:33
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 10/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
// ** Custom Components
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f5f5f7" // Set your desired background color here
  }
}));

const columns = [
  {
    flex: 0.3,
    minWidth: 200,
    field: "name",
    headerName: "Name",
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.name }</Typography>
    )
  },
  {
    flex: 0.3,
    minWidth: 100,
    field: "Total Transaction",
    headerName: "Total Transaction",
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.totalTranscation}</Typography>
    )
  },
  
];
const convertAmountToNumber = (amount) => {
  return parseFloat(amount.replace(/[^0-9.-]+/g, ""));
};
const TopCustomerTable = ({ topCustomer }) => {
  const rows = topCustomer.slice().sort((a, b) => convertAmountToNumber(b.inventroyTotalAmount) - convertAmountToNumber(a.inventroyTotalAmount));
  return (
    <>
      <CardHeader
        sx={{
          padding: 0,
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px",
          color:"gray",
        }}
        title="Top 5 Inventory by Transaction"
        titleTypographyProps={{ sx: { letterSpacing: '0.15px',fontSize: "1.125rem", fontWeight:"600",color:"#636578"  } }}
      />
      <Card sx={{ padding: 0, borderRadius: 3,height: 333  }}>
        <Box sx={{ maxHeight: 364, overflowY: "auto" }}>
          <StyledDataGrid
            autoHeight
            hideFooter
            rows={rows}
            getRowId={(row) => row._id || row.id}
            columns={columns}
            disableRowSelectionOnClick
            pagination={undefined}
          />
        </Box>
      </Card>
    </>
  );
};

export default TopCustomerTable;
