/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:16:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_TEMPLATES_REQUEST,
  UPDATE_TEMPLATES_REQUEST,
  ADD_TEMPLATES_SUCCESS,
  UPDATE_TEMPLATES_SUCCESS,
  DELETE_TEMPLATES_SUCCESS,
  ADD_TEMPLATES_FAIL,
  UPDATE_TEMPLATES_FAIL,
  DELETE_TEMPLATES_FAIL,
  CLEAR_ERRORS,
  NEW_TEMPLATES_RESET,
} from "../constants/templatesConstants";

export const templatesReducer = (
  state = { templates: [] },
  { type, payload }
) => {
  switch (type) {
    case ADD_TEMPLATES_REQUEST:
    case UPDATE_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_TEMPLATES_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        addtemplates: payload?.addtemplates,
        templates: payload.templates
      };
    case UPDATE_TEMPLATES_SUCCESS:
      return {
        loading: false,
        isUpdated: payload
      };
    case DELETE_TEMPLATES_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case ADD_TEMPLATES_FAIL:
    case UPDATE_TEMPLATES_FAIL:
    case DELETE_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case NEW_TEMPLATES_RESET:
      return {
        loading: false,
        addtemplates: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

