/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 08/04/2024 - 14:27:31
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 08/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgotPasswordReducer,
  profileReducer,
  userReducer,
  allUsersReducer,
  userDetailsReducer,
  integrationChange,
  getintegration
} from "./reducers/userReducer";
import {
  productDetailsReducer,
  productsReducer
} from "./reducers/productReducer";
import {
  inventoryReducer,
  inventoryDetailsReducer
} from "./reducers/inventoryReducer";
import { templatesReducer } from "./reducers/templatesReducer.js";
import {
  transactionsReducer,
  transactionsgetReducer,
  transactionsCheckgetReducer,
  transactionsDashbordData,
  transactionsMassActionReducer
} from "./reducers/transactionsReducer.js";
import { customerReducer } from "./reducers/customerReducer.js";
import { storeReducer } from "./reducers/storeReducer.js";
import {
  smtpDetailReducer,
  alertReducer,
  smtpAllData,
  smtpUpdateData,
  smtpDeleteData
} from "./reducers/smtpReducer.js";
import {
  addipReducer,
  getAddIpData,
  updateAddIpData,
  deleteAddApi
} from "./reducers/addipReducer.js";
import {
  DomainipReducer,
  getDomainIpData,
  updateDomainIpData,
  deleteDomainApi
} from "./reducers/domainIpReducer.js";
const reducer = combineReducers({
  user: userReducer,
  store: storeReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  products: productsReducer,
  productDetails: productDetailsReducer,
  inventoryDetails: inventoryDetailsReducer,
  inventory: inventoryReducer,
  templates: templatesReducer,
  transactions: transactionsReducer,
  fetchtransaction: transactionsgetReducer,
  customers: customerReducer,
  users: allUsersReducer,
  userDetails: userDetailsReducer,
  smtp: smtpDetailReducer,
  alert: alertReducer,
  addipReducer: addipReducer,
  getAddIpData: getAddIpData,
  updateAddIpData: updateAddIpData,
  deleteAddApi: deleteAddApi,
  DomainipReducer: DomainipReducer,
  getDomainIpData: getDomainIpData,
  updateDomainIpData: updateDomainIpData,
  deleteDomainApi: deleteDomainApi,
  transactionsCheckgetReducer: transactionsCheckgetReducer,
  smtpAllData: smtpAllData,
  smtpUpdateData: smtpUpdateData,
  smtpDeleteData: smtpDeleteData,
  transactionsDashbordData: transactionsDashbordData,
  integrationChange: integrationChange,
  getintegration: getintegration,
  transactionsMassActionReducer: transactionsMassActionReducer
});

let initialState = {
  saveForLater: {
    saveForLaterItems: []
  }
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
