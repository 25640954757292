/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:16:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_TRANSCATIONS_REQUEST,
  FETCH_TRANSCATIONS_REQUEST,
  UPDATE_TRANSCATIONS_REQUEST,
  ADD_TRANSCATIONS_SUCCESS,
  GET_TRANSCATIONS_SUCCESS,
  UPDATE_TRANSCATIONS_SUCCESS,
  FETCH_TRANSCATIONS_SUCCESS,
  DELETE_TRANSCATIONS_SUCCESS,
  ADD_TRANSCATIONS_FAIL,
  UPDATE_TRANSCATIONS_FAIL,
  DELETE_TRANSCATIONS_FAIL,
  CLEAR_ERRORS,
  NEW_TRANSCATIONS_RESET,
  FETCH_TRANSCATIONS_RESET,
  CHECK_TRANSCATIONS_REQUEST,
  CHECK_TRANSCATIONS_SUCCESS,
  CHECK_TRANSCATIONS_FAIL,
  CHECK_TRANSCATIONS_DASHBORD_REQUEST,
  CHECK_TRANSCATIONS_DASHBORD_SUCCESS,
  CHECK_TRANSCATIONS_DASHBORD_FAIL,

  CHECK_TRANSCATIONS_MassAction_REQUEST,
  CHECK_TRANSCATIONS_MassAction_SUCCESS,
  CHECK_TRANSCATIONS_MassAction_FAIL,

  GET_SALESData_REQUEST,
  GET_SALESData_SUCCESS,
  GET_SALESData_FAIL,
} from "../constants/transactionsConstants";

export const transactionsReducer = (
  state = { transactions: [] },
  { type, payload }
) => {
  switch (type) {
    case ADD_TRANSCATIONS_REQUEST:
    case UPDATE_TRANSCATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_TRANSCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: payload.success,
        addtransactions: payload?.addtransactions
      };
      case GET_TRANSCATIONS_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        transactions: payload.transactions
      };
    case UPDATE_TRANSCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: payload.success
      };
    case DELETE_TRANSCATIONS_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case ADD_TRANSCATIONS_FAIL:
    case UPDATE_TRANSCATIONS_FAIL:
    case DELETE_TRANSCATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case NEW_TRANSCATIONS_RESET:
      return {
        loading: false,
        addtransactions: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export const transactionsgetReducer = (
  state = { fetch: false },
  { type }
) => {
  switch (type) {
    case FETCH_TRANSCATIONS_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TRANSCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        Fetch: true
      };
    case FETCH_TRANSCATIONS_RESET:
        return {
          ...state,
          loading: false,
          Fetch: false
        };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};


export const transactionsDashbordData = (state = { TranscationDashbordData: {} }, { type, payload }) => {
  switch (type) {
    case CHECK_TRANSCATIONS_DASHBORD_REQUEST:
      return {
        loading: true,
      };
    case CHECK_TRANSCATIONS_DASHBORD_SUCCESS:
      return {
        ...state,
        TranscationDashbordData: payload,
      };
    case CHECK_TRANSCATIONS_DASHBORD_FAIL:
        return {
          ...state,
          loading: false,
          Fetch: false
        };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};


export const transactionsCheckgetReducer = (state = { checkTranscationData: {} }, { type, payload }) => {
  switch (type) {
      case CHECK_TRANSCATIONS_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case CHECK_TRANSCATIONS_SUCCESS:
          return {
              ...state,
              loading: false,
              checkTranscationData: payload,
          };
      case CHECK_TRANSCATIONS_FAIL:
          return {
              ...state,
              loading: false,
              error: payload,
          };
      default:
          return state;
  }
};

export const transactionsMassActionReducer = (state = { massAction: {} }, { type, payload }) => {
  switch (type) {
      case CHECK_TRANSCATIONS_MassAction_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case CHECK_TRANSCATIONS_MassAction_SUCCESS:
          return {
              ...state,
              loading: false,
              massAction: payload,
          };
      case CHECK_TRANSCATIONS_MassAction_FAIL:
          return {
              ...state,
              loading: false,
              error: payload,
          };
      default:
          return state;
  }
};

export const salesData = (state = { salesData: {} }, { type, payload }) => {
  switch (type) {
      case GET_SALESData_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case GET_SALESData_SUCCESS:
          return {
              ...state,
              loading: false,
              salesData: payload,
          };
      case GET_SALESData_FAIL:
          return {
              ...state,
              loading: false,
              error: payload,
          };
      default:
          return state;
  }
};


