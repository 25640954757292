/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 14:23:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const ADD_INVENTORY_REQUEST = "ADD_INVENTORY_REQUEST";
export const UPLOAD_INVENTORY_REQUEST = "UPLOAD_INVENTORY_REQUEST";
export const INVENTORY_DETAILS_REQUEST = "INVENTORY_DETAILS_REQUEST";
export const UPDATE_INVENTORY_REQUEST = "UPDATE_INVENTORY_REQUEST";
export const ADD_INVENTORY_SUCCESS = "ADD_INVENTORY_SUCCESS";
export const INVENTORY_DETAILS_SUCCESS = "INVENTORY_DETAILS_SUCCESS";
export const UPLOAD_INVENTORY_SUCCESS = "UPLOAD_INVENTORY_SUCCESS";
export const UPLOAD_INVENTORY_ITEMS_SUCCESS = "UPLOAD_INVENTORY_ITEMS_SUCCESS";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const DELETE_INVENTORY_SUCCESS = "DELETE_INVENTORY_SUCCESS";
export const DELETE_INVENTORY_ITEMS_SUCCESS = "DELETE_INVENTORY_ITEMS_SUCCESS";
export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";
export const INVENTORY_DETAILS_FAIL = "INVENTORY_DETAILS_FAIL";
export const ADD_INVENTORY_FAIL = "ADD_INVENTORY_FAIL";
export const DELETE_INVENTORY_FAIL = "DELETE_INVENTORY_FAIL";
export const DELETE_INVENTORY__ITEMS_FAIL = "DELETE_INVENTORY__ITEMS_FAIL";
export const UPLOAD_INVENTORY_FAIL = "UPLOAD_INVENTORY_FAIL";
export const NEW_INVENTORY_RESET="NEW_INVENTORY_RESET";
export const UPLOAD_INVENTORY_RESET="UPLOAD_INVENTORY_RESET";
export const REMOVE_INVENTORY_DETAILS="REMOVE_INVENTORY_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";