/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 10:16:21
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** React Imports
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, registerUser } from "../../actions/userAction";
import { useSnackbar } from "notistack";
import Icon from "../../@core/components/icon";
import themeConfig from "../../configs/themeConfig";
import BlankLayout from "../../@core/layouts/BlankLayout";
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" }
}));

const Register = () => {
  // ** States
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    showPassword: false,
    agreeTerms: true // Track if the user agrees to terms
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );
  const [errors, setErrors] = useState({
    username: false,
    email: false,
    password: false,
    agreeTerms: false
  });

  // ** Hook
  const theme = useTheme();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: false }); // Reset error when user starts typing
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ["username", "email", "password", "agreeTerms"];
    let formIsValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const updatedErrors = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        updatedErrors[field] = true;
        formIsValid = false;
      } else {
        updatedErrors[field] = false;
        if (field === "email" && !emailRegex.test(values[field])) {
          updatedErrors[field] = true;
          formIsValid = false;
        }
      }
    });
    if (values.password.length < 8) {
      updatedErrors.password = true;
      formIsValid = false;
      enqueueSnackbar("Password must be at least 8 characters long", {
        variant: "error"
      });
    }
    setErrors(updatedErrors);

    if (formIsValid) {
      // console.log("Submit", formIsValid);
      // const formData = new FormData();
      // formData.set("username", values?.username);
      // formData.set("email", values?.email);
      // formData.set("password", values?.password);
      // formData.set("agreeTerms", values?.agreeTerms);
      dispatch(registerUser(values));
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      enqueueSnackbar("Register Successful", { variant: "success" });
      navigate("/");
    }
  }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

  return (
    <>
      <MetaData title="Register | EZDZsender" />

      {loading && <BackdropLoader />}

      <Box className="content-center">
        <Card sx={{ zIndex: 1 }}>
          <CardContent
            sx={{ p: (theme) => `${theme.spacing(5, 7, 1.5)} !important` }}
          >
            <Box
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <svg
                width={47}
                fill="none"
                height={26}
                viewBox="0 0 268 150"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fillOpacity="0.4"
                  fill="url(#paint0_linear_7821_79167)"
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fillOpacity="0.4"
                  fill="url(#paint1_linear_7821_79167)"
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
                />
                <defs>
                  <linearGradient
                    y1="0"
                    x1="25.1443"
                    x2="25.1443"
                    y2="143.953"
                    id="paint0_linear_7821_79167"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    y1="0"
                    x1="25.1443"
                    x2="25.1443"
                    y2="143.953"
                    id="paint1_linear_7821_79167"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  lineHeight: 1,
                  fontWeight: 700,
                  fontSize: "1.5rem !important"
                }}
              >
                {themeConfig.templateName}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h5"
                sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
              >
                Adventure starts here 🚀
              </Typography>
              <Typography variant="body2">
                Make your app management easy and fun!
              </Typography>
            </Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                autoFocus
                fullWidth
                id="username"
                label="Username"
                sx={{ mb: 2 }}
                value={values.username}
                onChange={handleChange("username")}
                error={errors.username}
                required
              />
              <TextField
                fullWidth
                type="email"
                label="Email"
                sx={{ mb: 2 }}
                value={values.email}
                onChange={handleChange("email")}
                error={errors.email}
                required
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="auth-register-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  label="Password"
                  value={values.password}
                  id="auth-register-password"
                  onChange={handleChange("password")}
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                        aria-label="toggle password visibility"
                      >
                        <Icon
                          icon={
                            values.showPassword
                              ? "mdi:eye-outline"
                              : "mdi:eye-off-outline"
                          }
                          fontSize={20}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors.password}
                  required
                />
              </FormControl>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mb: 2 }}
              >
                Sign up
              </Button>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center"
                }}
              >
                <Typography sx={{ mr: 2, color: "text.secondary" }}>
                  Already have an account?
                </Typography>
                <Typography
                  component={Link}
                  to="/"
                  sx={{ color: "primary.main", textDecoration: "none" }}
                >
                  Sign in instead
                </Typography>
              </Box>
              <Divider
                sx={{
                  "& .MuiDivider-wrapper": { px: 1 },
                  mt: (theme) => `${theme.spacing(1)} !important`,
                  mb: (theme) => `${theme.spacing(1)} !important`
                }}
              >
                or
              </Divider>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <IconButton
                  to="/"
                  component={Link}
                  sx={{ color: "#497ce2" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Icon icon="mdi:facebook" />
                </IconButton>
                <IconButton
                  to="/"
                  component={Link}
                  sx={{ color: "#1da1f2" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Icon icon="mdi:twitter" />
                </IconButton>
                <IconButton
                  to="/"
                  component={Link}
                  onClick={(e) => e.preventDefault()}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#272727" : "grey.300"
                  }}
                >
                  <Icon icon="mdi:github" />
                </IconButton>
                <IconButton
                  to="/"
                  component={Link}
                  sx={{ color: "#db4437" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Icon icon="mdi:google" />
                </IconButton>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

Register.getLayout = (page) => <BlankLayout>{page}</BlankLayout>;

export default Register;
