/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 24/06/2024 - 11:52:36
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 24/06/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  Typography
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import Icon from "../../@core/components/icon";
import { getStores } from "../../actions/storeAction";
import { getStoreProducts } from "../../actions/productAction";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import TableRow from "@mui/material/TableRow";
import { clearErrors, getTransactions } from "../../actions/transactionsAction";
import { getInventory } from "../../actions/inventoryAction";
import { getTemplates } from "../../actions/templatesAction";
import CustomDatePicker from "../Layouts/forms/form-elements/pickers/CustomDatePicker";
const SalesReport = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products } = state.products;
  const { stores } = useSelector((state) => state.store);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [optionSelected, setOptionSelected] = useState("");
  const { inventorys } = useSelector((state) => state.inventory);
  const [Checkfield, setCheckfield] = useState("");
  const [formData, setFormData] = useState({
    product: "",
    inventory: "",
    stores: "",
    platforms: "",
    customer: "",
    date: ""
  });
  const [secondTableData, setSecondTableData] = useState();
  const [listData, setListData] = useState("");
  const [updateTranscation, setUpdateTranscation] = useState("");
  const [StartDatefirst, setStartDatefirst] = useState("");
  const [EndDatefirst, setEndDatefirst] = useState("");
  const [formDataSecondTable, setFormDataSecondTable] = useState({
    options: "",
    top: 0,
    amount: "",
    platforms: "",
    stores: "",
    products: "",
    startDate: "",
    endDate: ""
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { error, transactions, addtransactions, isUpdated } = useSelector(
    (state) => state.transactions
  );
  const { user } = state?.user;
  const handleOptionChange = (event) => {
    setFormDataSecondTable((prevFormData) => ({
      ...prevFormData,
      options: event.target.value
    }));
    setOptionSelected(event.target.value);
  };
  useEffect(() => {
    if (formDataSecondTable.stores && user?._id) {
      dispatch(
        getStoreProducts({
          store_id: formDataSecondTable.stores,
          user_id: user._id
        })
      );
    }
  }, [formDataSecondTable.stores, user._id, dispatch]);
  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };
  useEffect(() => {
    if (formData.stores && user?._id) {
      dispatch(
        getStoreProducts({ store_id: formData.stores, user_id: user._id })
      );
    }
  }, [formData.stores, user._id, dispatch]);

  useEffect(() => {
    dispatch(getStores());
    dispatch(getTemplates());
    dispatch(getInventory());
    dispatch(getTransactions());
    if (error) {
      dispatch(clearErrors());
    }
    if (addtransactions) {
      dispatch(getTransactions());
    }
  }, [error, isUpdated, addtransactions, dispatch]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFormDataSecondTable((prevFormData) => ({
      ...prevFormData,
      startDate: start,
      endDate: end
    }));
    setStartDate(start);
    setEndDate(end);
  };
  const handleFirstDateChange = (dates) => {
    const [start, end] = dates;
    setStartDatefirst(start);
    setEndDatefirst(end);
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.date_created);
      transactionDate.setHours(0);
      transactionDate.setMinutes(0);
      transactionDate.setSeconds(0);
      return (
        transactionDate >= new Date(start) && transactionDate <= new Date(end)
      );
    });
    setUpdateTranscation(filteredTransactions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: start + "~~" + end
    }));
  };

  const handleDataSales = (name, value) => {
    if (name === "product") {
      setCheckfield("product");
    }
    if (name === "inventory") {
      setCheckfield("inventory");
    }
    var filteredData;
    if (name === "customer") {
      const queryLowered = value.toLowerCase();
      filteredData = transactions.filter((transaction) =>
        transaction?.billing?.email.toLowerCase().includes(queryLowered)
      );
    }

    if (name === "product") {
      const queryLowered = value.toLowerCase();
      filteredData = transactions.filter((transaction) =>
        transaction?.line_items?.some((item) =>
          item.name.toLowerCase().includes(queryLowered)
        )
      );
    }
    if (name === "inventory") {
      filteredData = transactions.filter(
        (transaction) => transaction?.inventory_id === value
      );
    }
    setUpdateTranscation(filteredData?.length > 0 ? filteredData : []);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleReset = () => {
    setStartDatefirst("");
    setEndDatefirst("");
    setFormData({});
    setCheckfield("");
  };
  const handleData = useCallback((name, value) => {
    setFormDataSecondTable((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  }, []);

  const SearchFilterData = () => {
    setListData(updateTranscation);
  };

  const ProductCellRenderer = ({ value }) => {
    return (
      <div>
        <Typography variant="body2" style={{ height: "10" }}>
          {value?.map((item, index) => (
            <React.Fragment key={index}>
              {item.name}
              {index < value.length - 1 && <br />}
            </React.Fragment>
          ))}
        </Typography>
      </div>
    );
  };

  const AmountCellRenderer = ({ value }) => {
    return (
      <div>
        <Typography variant="body2" style={{ height: "10" }}>
          {value?.map((item, index) => (
            <React.Fragment key={index}>
              {item.total}
              {index < value.length - 1 && <br />}
            </React.Fragment>
          ))}
        </Typography>
      </div>
    );
  };

  const QuantityCellRenderer = ({ value }) => {
    return (
      <div>
        <Typography variant="body2" style={{ height: "10" }}>
          {value?.map((item, index) => (
            <React.Fragment key={index}>
              {item.quantity}
              {index < value.length - 1 && <br />}
            </React.Fragment>
          ))}
        </Typography>
      </div>
    );
  };
  const handleExportAction = () => {
    let csvContent = "";
    csvContent +=
      "Plateform,Store,Order ID,Email,Product,Amount,Qty,Time,Status\n";
    var ExportData = updateTranscation?.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );
    if (ExportData) {
      ExportData?.forEach((transaction) => {
        var store;
        if (stores) {
          store = stores.find((store) => store._id === transaction?.store_id);
        } else {
          store = "";
        }
        var Amount = "";
        var Product = "";
        var Quantity = "";

        transaction?.line_items.forEach((item) => {
          Amount = item?.total ?? 0;
          Product = item?.name ?? "";
          Quantity = item?.quantity ?? "";
        });

        const formattedDate = transaction?.date_created
          ? format(new Date(transaction.date_created), "yyyy-MM-dd HH:mm:ss")
          : "";

        csvContent += `${"Woocommerce"},${store ? store?.name : ""},${
          transaction?.order_id
        },${
          transaction?.billing?.email
        },${Product},${Amount},${Quantity},${formattedDate},${
          transaction?.tx_status
        }\n`;
      });
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window?.URL.createObjectURL(blob);
      const a = document?.createElement("a");
      a.href = url;
      a.download = "SalesReport.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };
  const columns = [
    {
      flex: 0.2,
      field: "plateform",
      minWidth: 100,
      headerName: "Platform",
      renderCell: ({ row }) => {
        if (row?.platform_id) {
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  Woocommerce
                </Typography>
              </Box>
            </Box>
          );
        }
      }
    },
    {
      flex: 0.3,
      minWidth: 100,
      field: "store",
      headerName: "Store",
      renderCell: ({ row }) => {
        const store = stores.find((store) => store._id === row.store_id);
        return (
          <Typography variant="body2">{store ? store.name : ""}</Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "billing",
      headerName: "Email",
      renderCell: ({ row }) => {
        if (row?.email) {
          return <Typography variant="body2">{row?.email}</Typography>;
        } else if (row?.billing?.email) {
          return <Typography variant="body2">{row?.billing?.email}</Typography>;
        }
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "line_items",
      headerName: "Product",
      renderCell: ({ row }) => {
        if (row?.line_items) {
          return <ProductCellRenderer value={row?.line_items} />;
        } else if (row?.productName) {
          return <Typography variant="body2">{row?.productName}</Typography>;
        }
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "total",
      headerName: "Amount",
      renderCell: ({ row }) => {
        if (row?.line_items) {
          return <AmountCellRenderer value={row?.line_items} />;
        } else if (row?.total) {
          return <Typography variant="body2">{row?.total}</Typography>;
        }
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "quantity",
      headerName: "Qty",
      renderCell: ({ row }) => {
        if (row?.quantity) {
          return <Typography variant="body2">{row?.quantity}</Typography>;
        } else if (row?.line_items) {
          return <QuantityCellRenderer value={row?.line_items} />;
        }
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "inventory_id",
      headerName: "Inventory",
      renderCell: ({ row }) => {
        const inventoryName = inventorys.find(
          (inventory) => inventory._id === row.inventory_id
        );
        return (
          <Typography variant="body2">
            {inventoryName ? inventoryName.name : ""}
          </Typography>
        );
      }
    },

    {
      flex: 0.2,
      minWidth: 100,
      field: "date_created",
      headerName: "Time",
      renderCell: ({ row }) => {
        if (row?.created_at) {
          return (
            <Typography noWrap variant="body2">
              {row && row.created_at
                ? format(new Date(row?.created_at), "yyyy-MM-dd HH:mm:ss")
                : ""}
            </Typography>
          );
        } else if (row.date_created) {
          return (
            <Typography noWrap variant="body2">
              {row && row.date_created
                ? format(new Date(row?.date_created), "yyyy-MM-dd HH:mm:ss")
                : ""}
            </Typography>
          );
        }
      }
    }
  ];
  const searchSecondTable = () => {
    var sortValue = 0;
    var filteredData;
    if (
      (formDataSecondTable.options === "stores" ||
        formDataSecondTable.options === "platforms") &&
      formDataSecondTable.top > 0 &&
      formDataSecondTable.amount === "Amount" &&
      formDataSecondTable.endDate &&
      formDataSecondTable.startDate
    ) {
      const sortValue = parseInt(formDataSecondTable.top, 10);
      if (!isNaN(sortValue) && sortValue > 0) {
        filteredData = transactions
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            const startDate = new Date(formDataSecondTable.startDate);
            const endDate = new Date(formDataSecondTable.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Set to end of the day

            return transactionDate >= startDate && transactionDate <= endDate;
          })
          .sort((a, b) => b.total - a.total)
          .slice(0, sortValue);
      }
    }
    if (
      (formDataSecondTable.options === "stores" ||
        formDataSecondTable.options === "platforms") &&
      formDataSecondTable.top > 0 &&
      formDataSecondTable.amount === "Qty" &&
      formDataSecondTable.endDate &&
      formDataSecondTable.startDate
    ) {
      sortValue = parseInt(formDataSecondTable.top, 10);
      if (!isNaN(sortValue) && sortValue > 0) {
        filteredData = transactions
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            const startDate = new Date(formDataSecondTable.startDate);
            const endDate = new Date(formDataSecondTable.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Set to end of the day
            return transactionDate >= startDate && transactionDate <= endDate;
          })
          .slice()
          .sort((a, b) => b.line_items.quantity - a.line_items.quantity)
          .slice(0, sortValue);
      }
    }
    if (
      (formDataSecondTable.options === "products" ||
        formDataSecondTable.options === "inventories") &&
      formDataSecondTable.top > 0 &&
      formDataSecondTable.amount === "Qty" &&
      formDataSecondTable.platforms &&
      formDataSecondTable.stores &&
      formDataSecondTable.endDate &&
      formDataSecondTable.startDate
    ) {
      sortValue = parseInt(formDataSecondTable.top, 10);
      if (!isNaN(sortValue) && sortValue > 0) {
        filteredData = transactions
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            const startDate = new Date(formDataSecondTable.startDate);
            const endDate = new Date(formDataSecondTable.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Set to end of the day

            return transactionDate >= startDate && transactionDate <= endDate;
          })
          .filter((platformMatch) => {
            return platformMatch.platform_id === formDataSecondTable.platforms;
          })
          .filter((storeMatch) => {
            return storeMatch.store_id === formDataSecondTable.stores;
          })
          .slice()
          .sort((a, b) => b.line_items.quantity - a.line_items.quantity)
          .slice(0, sortValue);
      }
    }

    if (
      (formDataSecondTable.options === "products" ||
        formDataSecondTable.options === "inventories") &&
      formDataSecondTable.top > 0 &&
      formDataSecondTable.amount === "Amount" &&
      formDataSecondTable.platforms &&
      formDataSecondTable.stores &&
      formDataSecondTable.endDate &&
      formDataSecondTable.startDate
    ) {
      sortValue = parseInt(formDataSecondTable.top, 10);
      if (!isNaN(sortValue) && sortValue > 0) {
        filteredData = transactions
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            const startDate = new Date(formDataSecondTable.startDate);
            const endDate = new Date(formDataSecondTable.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Set to end of the day
            return transactionDate >= startDate && transactionDate <= endDate;
          })
          .filter((platformMatch) => {
            return platformMatch.platform_id === formDataSecondTable.platforms;
          })
          .filter((storeMatch) => {
            return storeMatch.store_id === formDataSecondTable.stores;
          })
          .slice()
          .sort((a, b) => b.line_items.quantity - a.line_items.quantity)
          .slice(0, sortValue);
      }
    }

    if (
      formDataSecondTable.options === "customer" &&
      formDataSecondTable.top > 0 &&
      (formDataSecondTable.amount === "Amount" ||
        formDataSecondTable.amount === "Qty") &&
      formDataSecondTable.platforms &&
      formDataSecondTable.stores &&
      formDataSecondTable.products &&
      formDataSecondTable.endDate &&
      formDataSecondTable.startDate
    ) {
      sortValue = parseInt(formDataSecondTable.top, 10);
      if (!isNaN(sortValue) && sortValue > 0) {
        filteredData = transactions
          .filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            const startDate = new Date(formDataSecondTable.startDate);
            const endDate = new Date(formDataSecondTable.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Set to end of the day

            return transactionDate >= startDate && transactionDate <= endDate;
          })
          .filter((transaction) =>
            transaction.line_items.some((item) =>
              item.name
                .toLowerCase()
                .includes(formDataSecondTable.products.toLowerCase())
            )
          )
          .filter(
            (transaction) =>
              transaction.platform_id === formDataSecondTable.platforms
          )
          .filter(
            (transaction) => transaction.store_id === formDataSecondTable.stores
          )
          .slice() // Create a shallow copy before sorting
          .sort((a, b) => {
            const totalQuantityA = a.line_items.reduce(
              (acc, item) => acc + item.quantity,
              0
            );
            const totalQuantityB = b.line_items.reduce(
              (acc, item) => acc + item.quantity,
              0
            );
            return totalQuantityB - totalQuantityA;
          })
          .slice(0, sortValue);
      }
    }
    const aggregatedTotals = [];
    filteredData?.forEach((filtered) => {
      if (
        formDataSecondTable.options === "stores" &&
        formDataSecondTable.amount === "Amount"
      ) {
        const { _id, store_id, total, created_at, platform_id } = filtered;
        const existingTotal = aggregatedTotals.find(
          (item) => item.store_id === store_id
        );
        if (existingTotal) {
          existingTotal.total += total;
        } else {
          aggregatedTotals.push({
            _id,
            store_id,
            total,
            created_at,
            platform_id
          });
        }
      } else if (
        formDataSecondTable.options === "platforms" &&
        formDataSecondTable.amount === "Amount"
      ) {
        const { _id, store_id, total, platform_id, created_at } = filtered;
        const existingTotal = aggregatedTotals.find(
          (item) => item.store_id === store_id
        );
        if (existingTotal) {
          existingTotal.total += total;
        } else {
          aggregatedTotals.push({
            _id,
            store_id,
            total,
            platform_id,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "platforms" &&
        formDataSecondTable.amount === "Qty"
      ) {
        const { _id, store_id, platform_id, created_at } = filtered;
        let quantity = filtered.line_items.map((items) => items?.quantity);
        quantity = quantity?.[0];
        const existingTotal = aggregatedTotals.find(
          (item) => item.store_id === store_id
        );
        if (existingTotal) {
          existingTotal.quantity += quantity;
        } else {
          aggregatedTotals.push({
            _id,
            store_id,
            platform_id,
            quantity,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "stores" &&
        formDataSecondTable.amount === "Qty"
      ) {
        const { _id, store_id, platform_id, created_at } = filtered;
        let quantity = filtered.line_items.map((items) => items?.quantity);
        quantity = quantity?.[0];
        const existingTotal = aggregatedTotals.find(
          (item) => item.store_id === store_id
        );
        if (existingTotal) {
          existingTotal.quantity += quantity;
        } else {
          aggregatedTotals.push({
            _id,
            store_id,
            platform_id,
            quantity,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "products" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Qty" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores
      ) {
        const { _id, store_id, platform_id, created_at } = filtered;
        let quantity = filtered.line_items.map((items) => items?.quantity);
        quantity = quantity?.[0];
        let productName = filtered.line_items.map((items) => items?.name);
        productName = productName?.[0]?.toLowerCase();
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.productName === productName
        );
        if (existingTotal) {
          existingTotal.quantity += quantity;
        } else {
          aggregatedTotals.push({
            _id,
            productName,
            store_id,
            platform_id,
            created_at,
            quantity
          });
        }
      } else if (
        formDataSecondTable.options === "products" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Amount" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores
      ) {
        const { _id, store_id, total, platform_id, created_at } = filtered;
        let productName = filtered.line_items.map((items) => items?.name);
        productName = productName?.[0]?.toLowerCase();
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.productName === productName
        );
        if (existingTotal) {
          existingTotal.total += total;
        } else {
          aggregatedTotals.push({
            _id,
            productName,
            store_id,
            platform_id,
            total,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "inventories" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Qty" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores
      ) {
        const { _id, store_id, inventory_id, platform_id, created_at } =
          filtered;
        let quantity = filtered.line_items.map((items) => items?.quantity);
        quantity = quantity?.[0];
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.inventory_id === inventory_id
        );
        if (existingTotal) {
          existingTotal.quantity += quantity;
        } else {
          aggregatedTotals.push({
            _id,
            quantity,
            inventory_id,
            store_id,
            platform_id,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "inventories" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Amount" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores
      ) {
        const { _id, store_id, total, inventory_id, platform_id, created_at } =
          filtered;
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.inventory_id === inventory_id
        );
        if (existingTotal) {
          existingTotal.total += total;
        } else {
          aggregatedTotals.push({
            _id,
            total,
            inventory_id,
            store_id,
            platform_id,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "customer" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Amount" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores &&
        formDataSecondTable.products
      ) {
        const { _id, store_id, total, platform_id, created_at } = filtered;
        let email = filtered?.billing?.email.toLowerCase();
        let productName = filtered.line_items.map((items) => items?.name);
        productName = productName?.[0]?.toLowerCase();
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.email === email
        );
        if (existingTotal) {
          existingTotal.total += total;
        } else {
          aggregatedTotals.push({
            _id,
            productName,
            store_id,
            email,
            platform_id,
            total,
            created_at
          });
        }
      } else if (
        formDataSecondTable.options === "customer" &&
        formDataSecondTable.top > 0 &&
        formDataSecondTable.amount === "Qty" &&
        formDataSecondTable.platforms &&
        formDataSecondTable.stores &&
        formDataSecondTable.products
      ) {
        const { _id, store_id, platform_id, created_at } = filtered;
        let email = filtered?.billing?.email.toLowerCase();
        let quantity = filtered.line_items.map((items) => items?.quantity);
        quantity = quantity?.[0];
        let productName = filtered.line_items.map((items) => items?.name);
        productName = productName?.[0]?.toLowerCase();
        const existingTotal = aggregatedTotals.find(
          (transaction) => transaction?.email === email
        );
        if (existingTotal) {
          existingTotal.quantity += quantity;
        } else {
          aggregatedTotals.push({
            _id,
            productName,
            store_id,
            email,
            platform_id,
            created_at,
            quantity
          });
        }
      }
    });
    setSecondTableData(aggregatedTotals);
  };
  const hasStore = secondTableData?.some((row) => row.store_id);
  const hasCustomerEmail = secondTableData?.some((row) => row.email);
  const hasProduct = secondTableData?.some((row) => row.productName);
  const hasAmount = secondTableData?.some((row) => row.total);
  const hasQuantity = secondTableData?.some((row) => row.quantity);
  const hasInventory = secondTableData?.some((row) => row.inventory_id);
  const hasTime = secondTableData?.some((row) => row.created_at);
  return (
    <>
      <RouterBreadcrumbs />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Sales Report"
              sx={{
                color: "gray",
                pb: 4,
                "& .MuiCardHeader-title": { letterSpacing: ".15px" }
              }}
            />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="platform-select">
                      Select Platform
                    </InputLabel>
                    <Select
                      fullWidth
                      value={formData?.platforms}
                      id="platform-select"
                      label="Select Platform"
                      labelId="platform-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Platform" }}
                      onChange={(e) => {
                        handleDataSales("platforms", e.target.value);
                      }}
                    >
                      <MenuItem value="629743b4c798c617e89d826e">
                        Woocommerce
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="store-select">Select Store</InputLabel>
                    <Select
                      fullWidth
                      value={formData?.stores}
                      id="store-select"
                      label="Select Store"
                      labelId="store-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Store" }}
                      onChange={(e) =>
                        handleDataSales("stores", e.target.value)
                      }
                    >
                      {stores && stores.length > 0
                        ? stores.map((store) => (
                            <MenuItem key={store._id} value={store._id}>
                              {store.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Stores Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="product-select">Select Product</InputLabel>
                    <Select
                      fullWidth
                      value={formData?.product}
                      id="product-select"
                      label="Select Product"
                      labelId="product-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Product" }}
                      onChange={(e) =>
                        handleDataSales("product", e.target.value)
                      }
                    >
                      {/* {Checkfield !== "inventory"  */}
                      {Checkfield !== "inventory" &&
                      products &&
                      products.length > 0
                        ? products.map((product) => (
                            <MenuItem key={product._id} value={product.name}>
                              {product.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Stores Available
                            </MenuItem>
                          ]}
                      {/* )} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="inventory-select">
                      Select Inventory
                    </InputLabel>
                    <Select
                      value={formData?.inventory}
                      fullWidth
                      id="inventory-select"
                      label="Select Inventory"
                      labelId="inventory-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Inventory" }}
                      onChange={(e) =>
                        handleDataSales("inventory", e.target.value)
                      }
                    >
                      {Checkfield !== "product" &&
                      inventorys &&
                      inventorys.length > 0
                        ? inventorys.map((inventory) => (
                            <MenuItem key={inventory._id} value={inventory._id}>
                              {inventory.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Inventorys Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextField
                    value={formData?.customer}
                    size="small"
                    sx={{ mr: 2, mb: 2, width: "100%" }}
                    placeholder="Search Customer"
                    onChange={(e) =>
                      handleDataSales("customer", e.target.value)
                    }
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomDatePicker
                    startDate={StartDatefirst}
                    endDate={EndDatefirst}
                    handleDateChange={handleFirstDateChange}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Button
                    sx={{ mb: 1 }}
                    variant="contained"
                    onClick={SearchFilterData}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Button
                    sx={{ mb: 1 }}
                    variant="contained"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Button
                    sx={{ mr: 1, mb: 2 }}
                    color="secondary"
                    variant="outlined"
                    onClick={handleExportAction}
                    startIcon={<Icon icon="mdi:export-variant" fontSize={20} />}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
              {listData?.length > 0 && (
                <DataGrid
                  autoHeight
                  rows={listData?.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )}
                  getRowId={(row) => row._id}
                  columns={columns}
                  disableRowSelectionOnClick
                  pagination={true}
                  onPageChange={(page) => handlePageChange(page)}
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSize={paginationModel.pageSize}
                  paginationMode={paginationModel}
                  rowCount={listData?.length}
                  pageSizeOptions={[10, 25, 50]}
                  sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Top Sales Record Filters"
              sx={{
                pb: 4,
                color: "gray",
                "& .MuiCardHeader-title": { letterSpacing: ".15px" }
              }}
            />
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                {/* Number Field */}
                <Grid item sm={1.5} xs={12}>
                  <TextField
                    fullWidth
                    value={formDataSecondTable.top}
                    label="Top"
                    type="number"
                    variant="outlined"
                    onChange={(e) => handleData("top", e.target.value)}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "90px !important",
                        height: "45px !important",
                        borderRadius: "0.8 rem !important"
                      }
                    }}
                  />
                </Grid>

                {/* Select Option Dropdown */}
                <Grid item sm={2} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="option-select-label">
                      Select Option
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Select Option"
                      labelId="option-select-label"
                      onChange={handleOptionChange}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                    >
                      <MenuItem value="platforms">Platforms</MenuItem>
                      <MenuItem value="stores">Stores</MenuItem>
                      <MenuItem value="products">Products</MenuItem>
                      <MenuItem value="inventories">Inventories</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Qty / Amount Dropdown */}
                <Grid item sm={1.5} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="qty-amount-select">Qty / Amount</InputLabel>
                    <Select
                      fullWidth
                      value={formDataSecondTable.amount}
                      label="Qty / Amount"
                      labelId="qty-amount-select"
                      onChange={(e) => handleData("amount", e.target.value)}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                    >
                      <MenuItem value="Qty">Qty</MenuItem>
                      <MenuItem value="Amount">Amount</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Conditionally Rendered Fields */}
                {optionSelected !== "platforms" &&
                  optionSelected !== "stores" && (
                    <Grid item sm={2} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="plan-select">
                          Select Plateform
                        </InputLabel>
                        <Select
                          fullWidth
                          value={formDataSecondTable.platforms}
                          id="select-plan"
                          label="Select Plan"
                          labelId="plan-select"
                          onChange={(e) => {
                            handleData("platforms", e.target.value);
                          }}
                          sx={{ height: "45px", borderRadius: "1rem" }}
                          inputProps={{ placeholder: "Select Plateform" }}
                        >
                          <MenuItem value="629743b4c798c617e89d826e">
                            Woocommerce
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                {optionSelected !== "platforms" &&
                  optionSelected !== "stores" && (
                    <Grid item sm={2} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="store-select">Stores</InputLabel>
                        <Select
                          fullWidth
                          value={formDataSecondTable.stores}
                          id="select-role"
                          label="Select Store"
                          labelId="role-select"
                          onChange={(e) => {
                            handleData("stores", e.target.value);
                          }}
                          sx={{ height: "45px", borderRadius: "1rem" }}
                          inputProps={{ placeholder: "Select Store" }}
                        >
                          {stores && stores.length > 0
                            ? stores.map((store) => (
                                <MenuItem key={store._id} value={store._id}>
                                  {store.name}
                                </MenuItem>
                              ))
                            : [
                                <MenuItem disabled key="no-inventory">
                                  No Stores Available
                                </MenuItem>
                              ]}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                {optionSelected === "customer" && (
                  <Grid item sm={2.5} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="product-select">Product</InputLabel>
                      <Select
                        fullWidth
                        value={formDataSecondTable.products}
                        id="select-Product"
                        label="Select Product"
                        labelId="Product-select"
                        onChange={(e) => {
                          handleData("products", e.target.value);
                        }}
                        sx={{ height: "45px", borderRadius: "1rem" }}
                        inputProps={{ placeholder: "Select Product" }}
                      >
                        {products && products.length > 0
                          ? products.map((product) => (
                              <MenuItem key={product._id} value={product.name}>
                                {product.name}
                              </MenuItem>
                            ))
                          : [
                              <MenuItem disabled key="no-inventory">
                                No Stores Available
                              </MenuItem>
                            ]}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item sm={3} xs={12}>
                  <CustomDatePicker
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Grid>
                {/* Search Button */}
                <Grid item sm={1.5} xs={12}>
                  <Button
                    sx={{ height: "45px", borderRadius: "1rem" }}
                    variant="contained"
                    onClick={searchSecondTable}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              {secondTableData && secondTableData.length > 0 && (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Platform</TableCell>
                        {hasStore && <TableCell align="right">Store</TableCell>}
                        {hasCustomerEmail && (
                          <TableCell align="right">Customer Email</TableCell>
                        )}
                        {hasProduct && (
                          <TableCell align="right">Product</TableCell>
                        )}
                        {hasAmount && (
                          <TableCell align="right">Amount</TableCell>
                        )}
                        {hasQuantity && (
                          <TableCell align="right">Quantity</TableCell>
                        )}
                        {hasInventory && (
                          <TableCell align="right">Inventory</TableCell>
                        )}
                        {hasTime && <TableCell align="right">Time</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {secondTableData.map((row) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Woocommerce"}
                          </TableCell>
                          {hasStore && (
                            <TableCell align="right">
                              <Typography variant="body2">
                                {stores.find(
                                  (store) => store._id === row.store_id
                                )?.name || ""}
                              </Typography>
                            </TableCell>
                          )}
                          {hasCustomerEmail && (
                            <TableCell align="right">{row.email}</TableCell>
                          )}
                          {hasProduct && (
                            <TableCell align="right">
                              {row.productName}
                            </TableCell>
                          )}
                          {hasAmount && (
                            <TableCell align="right">{row.total}</TableCell>
                          )}
                          {hasQuantity && (
                            <TableCell align="right">{row.quantity}</TableCell>
                          )}
                          {hasInventory && (
                            <TableCell align="right">
                              <Typography variant="body2">
                                {inventorys.find(
                                  (inventory) =>
                                    inventory._id === row.inventory_id
                                )?.name || ""}
                              </Typography>
                            </TableCell>
                          )}
                          {hasTime && (
                            <TableCell align="right">
                              <Typography noWrap variant="body2">
                                {row.created_at
                                  ? format(
                                      new Date(row.created_at),
                                      "yyyy-MM-dd HH:mm:ss"
                                    )
                                  : ""}
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SalesReport;
