/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 04/06/2024 - 13:42:10
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/06/2024
    * - Author          : admin
    * - Modification    : 
**/
import {
    AddIP_API_REQUEST,
    AddIP_API_SUCCESS,
    AddIP_API_FAIL,
    GetIPData_API_REQUEST,
    GetIPData_API_SUCCESS,
    GetIPData_API_FAIL,

    UPDATE_API_REQUEST,
   UPDATE_API_SUCCESS,
   UPDATE_API_FAIL,
   DELETE_API_REQUEST,
   DELETE_API_SUCCESS,
   DELETE_API_FAIL
  } from "../constants/addipConstants";
  import axios from "axios";
  export const AddipApi = (Addipdata) => async (dispatch) => {
    try {
      dispatch({ type: AddIP_API_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/addipapi", Addipdata, config);
      dispatch({
        type: AddIP_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: AddIP_API_FAIL,
        payload: error.response.data.message
      });
    }
  };


  export const getAddApiData = () => async (dispatch) => {
    try {
      dispatch({ type: GetIPData_API_REQUEST });
  
      const { data } = await axios.get("/api/v1/addipapi/all");
  
      dispatch({
        type: GetIPData_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: GetIPData_API_FAIL,
        payload: error.response.data.message
      });
    }
  };

  export const updateAddIpData = (updateData,AddIpId) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_API_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/updateAddApi/"+AddIpId, updateData, config);
  
      dispatch({
        type: UPDATE_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: UPDATE_API_FAIL,
        payload: error.response.data.message
      });
    }
  };
  
  export const deleteAddAPiData = (AddIpId) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_API_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post("/api/v1/deleteAddApi/"+AddIpId, config);
  
      dispatch({
        type: DELETE_API_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: DELETE_API_FAIL,
        payload: error.response.data.message
      });
    }

  };
  