/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 08/04/2024 - 14:11:28
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 08/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState, useCallback, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Actions from "./Actions";
import AddTemplatesPopup from "./Templates/AddTemplatesPopup";
import CustomChip from "../../../src/@core/components/mui/chip";
import {
  clearErrors,
  getTemplates,
  deleteTemplates,
  addTemplates
} from "../../actions/templatesAction";
import {
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  Select
} from "@mui/material";
import { NEW_TEMPLATES_RESET } from "../../constants/templatesConstants";
const userStatusObj = {
  Active: "success",
  Inactive: "error"
};

const ReplacementTemplate = () => {
  const [rowValue, setRowValue] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTemplates, setSearchTemplates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const { loading, error, templates, isDeleted } = useSelector(
    (state) => state.templates
  );
  const { user } = useSelector((state) => state.user);
  const user_id = user ? user?._id : "";
  const [openModal, setOpenModal] = useState(false);
  const deleteAddIpHandler = async (id) => {
    await dispatch(deleteTemplates(id));
    dispatch(getTemplates());
  };

  useEffect(() => {
    dispatch(
      addTemplates({
        user_id: user_id,
        platform_id: "629743b4c798c617e89d826e",
        name: "Default template",
        type: "Html",
        smtp: "Default",
        is_default: "true",
        description: "<h6>Default Html Replacement Body</h6>",
        subject: "Welcome To Our Page",
        status: "Active",
        attachment: false
      })
    );
  }, [dispatch, user_id]);
  const handleFilter = useCallback(
    (val) => {
      setSearchQuery(val);
      const queryLowered = val.toLowerCase();
      const filteredData = templates.filter(
        (template) =>
          template.name.toLowerCase().includes(queryLowered) ||
          template.subject.toLowerCase().includes(queryLowered) ||
          template.description.toLowerCase().includes(queryLowered) ||
          template.smtp.toLowerCase().includes(queryLowered)
      );
      setSearchTemplates(filteredData);
    },
    [templates]
  );
  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.5
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: 80,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          {params.row.subject || "Default Subject"}
        </div>
      )
    },
    {
      field: "body",
      headerName: "Body",
      minWidth: 150,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          {params.row.description || "Dear Buyer..."}
        </div>
      )
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          {params.row.type || "HTML"}
        </div>
      )
    },
    {
      field: "smtp",
      headerName: "Smtp",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          {params.row.smtp || "Default"}
        </div>
      )
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => (
        <CustomChip
          skin="light"
          size="small"
          label={params.row.status || "Active"}
          color={userStatusObj[params.row.status] || "success"}
          sx={{
            textTransform: "capitalize",
            "& .MuiChip-label": { lineHeight: "18px" }
          }}
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <Actions
          editRoute={"update/template"}
          deleteHandler={deleteAddIpHandler}
          id={params.row.id}
        />
      )
    }
  ];
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isDeleted) {
      enqueueSnackbar("Template Deleted", { variant: "success" });
      dispatch({ type: NEW_TEMPLATES_RESET });
    }
    setTimeout(() => {
      dispatch(getTemplates());
    }, [500]);
  }, [dispatch, error, isDeleted, enqueueSnackbar]);
  const getOpenModal = () => {
    setOpenModal(true);
    setRowValue(templates);
  };
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleSearch = (event) => {
    handleFilter(event.target.value);
  };

  return (
    <>
      <MetaData title="Templates | EZDZsender" />
      {loading && <BackdropLoader />}
      <RouterBreadcrumbs />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box bgcolor="#f5f5f5" borderRadius="xl" boxShadow={3} p={2} mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" style={{ color: "grey" }}>
                  Main Templates
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={getOpenModal}
                  >
                    + ADD NEW RECORD
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
              <FormControl size="small">
                <label htmlFor="page-size-select">
                  <Typography variant="h5" style={{ color: "grey" }}>
                    <span style={{ fontSize: "15px" }}>Entities per page</span>
                  </Typography>
                </label>
                <Select
                  labelId="page-size-select-label"
                  id="page-size-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" style={{ marginRight: "8px", color: "grey" }}>
                  Search:
                </Typography>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <DataGrid
                autoHeight
                rows={
                  searchTemplates.length > 0
                    ? searchTemplates
                    : templates && templates.length > 0
                    ? templates.map((template) => ({
                        ...template,
                        id: template._id ? template?._id : template?.id
                      }))
                    : []
                }
                columns={columns}
                getRowId={(row) => (row?._id ? row?._id : row?.id)}
                disableRowSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddTemplatesPopup
          templateData={rowValue}
          onSave={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </>
  );
};
export default ReplacementTemplate;
