/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 21/05/2024 - 12:31:15
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 21/05/2024
 * - Author          : admin
 * - Modification    :
 **/
/**
 * @description      :
 * @autor            : admin
 * @group            :
 * @created          : 21/05/2024 - 11:30:36
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 21/05/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  Typography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { updatePassword } from "../../actions/userAction";
import { useSnackbar } from "notistack";
const initialData = {
  oldPassword: "",
  newPassword: "",
  confirmpassword: ""
};

function Security() {
  const {error } = useSelector((state) => state.profile);
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
    apiSecret: false
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState(initialData);

  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.newPassword !== formData.confirmpassword) {
      enqueueSnackbar("Confirm password does not match", { variant: "error" });
    }
    if (formData.newPassword.length < 8) {
      enqueueSnackbar("Password must be 8 keyword", { variant: "error" });
    } else {
      var errorMessage = error?.errors?.password?.message
        ? error.errors.password.message
        : error;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        dispatch(updatePassword(formData))
          .then(() => {
            enqueueSnackbar("User password update successful", {
              variant: "success"
            });
          })
          .catch(() => {
            enqueueSnackbar("An error occurred while updating profile", {
              variant: "error"
            });
          });
      }
    }
  };
  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={6}>
        {/* Account Details Card */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Change Password" />
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ pt: 0 }} />
              <Divider />
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="empty-text-field">CURRENT PASSWORD</label>
                    <TextField
                      fullWidth
                      label="Current Password"
                      placeholder="Current Password"
                      type={showPassword.currentPassword ? "text" : "password"}
                      value={formData.oldPassword}
                      onChange={(e) =>
                        handleFormChange("oldPassword", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleTogglePasswordVisibility(
                                  "currentPassword"
                                )
                              }
                            >
                              {showPassword.currentPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="empty-text-field">NEW PASSWORD</label>
                    <TextField
                      fullWidth
                      type={showPassword.newPassword ? "text" : "password"}
                      label="New Password"
                      placeholder="New Password"
                      value={formData.newPassword}
                      onChange={(e) =>
                        handleFormChange("newPassword", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleTogglePasswordVisibility("newPassword")
                              }
                            >
                              {showPassword.newPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="empty-text-field">
                      CONFIRM NEW PASSWORD
                    </label>
                    <TextField
                      fullWidth
                      type={showPassword.confirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      value={formData.confirmpassword}
                      onChange={(e) =>
                        handleFormChange("confirmpassword", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleTogglePasswordVisibility(
                                  "confirmPassword"
                                )
                              }
                            >
                              {showPassword.confirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        </Grid>

        {/* API Information Card */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="API Information" />
            <CardContent sx={{ pt: 0 }} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <label htmlFor="empty-text-field">API Key</label>
                  <TextField
                    fullWidth
                    label=""
                    placeholder="API Key"
                    value="83045890823097ade227eedaa8da5fa7"
                    onChange={(e) => handleFormChange("apiKey", e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="empty-text-field">API Secret</label>
                  <TextField
                    fullWidth
                    label=""
                    type={showPassword.apiSecret ? "text" : "password"}
                    placeholder="API Secret"
                    value="83045890823097ade227eedaa8da5fa7"
                    onChange={(e) =>
                      handleFormChange("apiSecret", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleTogglePasswordVisibility("apiSecret")
                            }
                          >
                            {showPassword.apiSecret ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="empty-text-field">API REDIRECT URL</label>
                  <Grid item xs={12}>
                    <Typography
                      // component="a"
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      variant="body1"
                    >
                      Documentation
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Security;
