/**
 * @description      : Advanced table with all features including dropdown, add button, search bar, pagination, and export button.
 * @author           : admin
 * @group            :
 * @created          : 30/05/2024 - 14:37:53
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 30/05/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Icon from "../../@core/components/icon";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import CustomChip from "../../../src/@core/components/mui/chip";
import {
  Button,
  Box,
  MenuItem,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  CardContent
} from "@mui/material";
import Actions from "./Actions";
import {
  DomainipApi,
  getDomainApiData,
  deleteDomainAPiData
} from "../../actions/domainIpAction";
const userStatusObj = {
  Active: "success",
  Inactive: "error"
};
const Emaildomain = () => {
  const dispatch = useDispatch();
  const allData = useSelector((state) => state);
  const { DomainIpAll } = allData?.getDomainIpData;
  const dummyData = DomainIpAll?.data || [];
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [formData, setFormData] = useState({
    domain: "",
    type: "BlackList",
    status: "Active"
  });
  const [pageSize, setPagesize] = useState(10);
  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
 // const handlePaginationModelChange = (newModel) => {
  //  setPaginationModel(newModel);
 // };
  const handleChange = (event) => {
    const newPageSize = event.target.value;
    setPagesize(newPageSize);
    setPaginationModel((prevModel) => ({ ...prevModel, pageSize: newPageSize }));
  };
  const handleFormChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    dispatch(getDomainApiData());
  }, [dispatch]);

  const submitFormData =async (pre) => {
    pre.preventDefault();
    await dispatch(DomainipApi(formData));
    dispatch(getDomainApiData());
    enqueueSnackbar("Inserted data successfully", { variant: "success" });
    setOpenModal(false);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const deleteAddIpHandler = async (id) => {
    await dispatch(deleteDomainAPiData(id));
    enqueueSnackbar("Delete Data Successfully", { variant: "error" });
    dispatch(getDomainApiData());
  };
  const filteredRows = dummyData.filter(
    (row) =>
      row?.domain?.toLowerCase().includes(searchValue.toLowerCase()) ||
      row?.type?.toLowerCase().includes(searchValue.toLowerCase()) ||
      row?.status?.toLowerCase().includes(searchValue.toLowerCase())
  );
  const DialogBoxhandle = () => {
    setOpenModal(true);
  };
  const columns = [
    {
      field: "Email/Domain",
      headerName: "Email/Domain",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.domain}</div>
      )
    },
    {
      field: "TYPE",
      headerName: "TYPE",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.type}</div>
      )
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomChip
          skin="light"
          size="small"
          label={params.row.status}
          color={userStatusObj[params.row.status]}
          sx={{
            textTransform: "capitalize",
            "& .MuiChip-label": { lineHeight: "18px" }
          }}
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      flex: 0.3,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"update/emaildomain"}
            deleteHandler={deleteAddIpHandler}
            id={params.row.id}
          />
        );
      }
    }
  ];

  const handleMenuClick = () => {
    let csvContent = "";
    csvContent += "Email/Domain,TYPE,Status\n";
    var ExportData = filteredRows?.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );

    ExportData?.forEach((transaction) => {
      var Domain = "";
      var Type = "";
      var Status = "";
      var Expires = "";
      Domain = transaction.domain ?? "";
      Type = transaction?.type ?? "";
      Status = transaction?.status ?? "";
      csvContent += `${Domain},${Type},${Status},${Expires}\n`;
    });
    // Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create URL for Blob
    const url = window?.URL.createObjectURL(blob);
    // Create anchor element
    const a = document?.createElement("a");
    a.href = url;
    a.download = "EmailDomain.csv";
    // Simulate click on anchor element
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box bgcolor="#f5f5f5" borderRadius="xl" boxShadow={3} p={2} mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" style={{ color: "grey" }}>
                  Email/Domain List
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Icon icon="mdi:export-variant" fontSize={20} />}
                    onClick={handleMenuClick}
                    style={{ marginRight: 10 }}
                  >
                    EXPORT
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={DialogBoxhandle}
                    component={Link}
                  >
                    + ADD NEW RECORD
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControl size="small">
                <label htmlFor="empty-text-field">
                  <Typography variant="h5" style={{ color: "grey" }}>
                    <span style={{ fontSize: "15px" }}> Entites per page</span>
                  </Typography>
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  style={{ marginRight: "8px", color: "grey" }}
                >
                  Search:
                </Typography>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <DataGrid
                autoHeight
                rows={filteredRows.map((store) => ({
                  ...store,
                  id: store._id
                }))}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                paginationMode="client"
                pagination
                onPageChange={(page) => handlePageChange(page)}
                onPageSizeChange={(size) => setPagesize(size)}
                pageSize={paginationModel.pageSize}
                rowsPerPageOptions={[10, 25, 50]}
                rowCount={filteredRows.length}
                style={{ backgroundColor: "#f5f5f5" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* dialog box */}

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ADD Domain/Email"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            {/* Account Details Card */}
            <Grid item xs={12}>
              <form onSubmit={submitFormData}>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email/Domain"
                        placeholder="Email/Domain"
                        value={formData.domain}
                        onChange={(e) =>
                          handleFormChange("domain", e.target.value)
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Select
                          label="Type"
                          value={formData.type}
                          onChange={(e) =>
                            handleFormChange("type", e.target.value)
                          }
                        >
                          <MenuItem value="WhiteList">WhiteList</MenuItem>
                          <MenuItem value="BlackList">BlackList</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Select
                          label="Type"
                          value={formData.status}
                          onChange={(e) =>
                            handleFormChange("status", e.target.value)
                          }
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Button type="submit" variant="text">
                        Submit
                      </Button>
                      <Button type="reset" variant="text" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Emaildomain;
