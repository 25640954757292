/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 16:31:50
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** React Imports
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Icon from "../../@core/components/icon";
import { useDispatch, useSelector } from "react-redux";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import CustomChip from "../../@core/components/mui/chip";
import CustomDatePicker from "../Layouts/forms/form-elements/pickers/CustomDatePicker";
import { useSnackbar } from "notistack";
import { getStores } from "../../actions/storeAction";
import {
  clearErrors,
  getCustomers,
  deleteCustomers,
  updateCustomers
} from "../../actions/customerAction";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getStoreProducts } from "../../actions/productAction";
import { getTransactions } from "../../actions/transactionsAction";
import {
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  Divider,
  CardActions
} from "@mui/material";
const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main
  }
}));
const userStatusObj = { Success: "success", failed: "error" };
const initialData = {
  id: "",
  email: "",
  state: "",
  fullName: "",
  orders: "",
  amount_spent: "",
  status: ""
};

const RowOptions = ({ id, row, customer_id }) => {
  // ** Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // ** State
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [selectedRow, setselectedRow] = useState(initialData);
  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setopenModal(true);
  };
  const deleteCustomer = async () => {
    await dispatch(deleteCustomers(id));
    setopenModal(false);
    dispatch(getCustomers());
  };

  const handleClose = () => {
    setopenModal(false);
    setOpen(false);
  };
  const handleClickOpen = () => {
    setselectedRow(row);
    setOpen(true);
  };
  const getUpdateData = (field, value) => {
    setselectedRow({ ...selectedRow, [field]: value });
  };

  const updateProfileHandler = (e) => {
    e.preventDefault();
    try {
      setselectedRow({ ...selectedRow, user_id: customer_id });
      dispatch(updateCustomers(selectedRow)); // Dispatch the updateProfile action with form data
      enqueueSnackbar("User Update Successful", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("An error occurred while updating profile", {
        variant: "success"
      });
    }
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <Icon icon="mdi:dots-vertical" />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem
          component={Link}
          sx={{ "& svg": { mr: 2 } }}
          to={"/customer/detail/" + id}
        >
          <Icon icon="mdi:eye-outline" fontSize={20} />
          View
        </MenuItem>
        <MenuItem onClick={handleClickOpen} sx={{ "& svg": { mr: 2 } }}>
          <Icon icon="mdi:pencil-outline" fontSize={20} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ "& svg": { mr: 2 } }}>
          <Icon icon="mdi:delete-outline" fontSize={20} />
          Delete
        </MenuItem>
      </Menu>

      <Dialog open={open}>
        <DialogTitle>Update Table</DialogTitle>
        <form onSubmit={updateProfileHandler}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="empty-text-field">Customer Name</label>
                <TextField
                  fullWidth
                  label=""
                  variant="outlined"
                  value={selectedRow?.fullName ? selectedRow.fullName : ""}
                  onChange={(e) => getUpdateData("fullName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="empty-text-field">Email</label>
                <TextField
                  fullWidth
                  type="email"
                  label=""
                  variant="outlined"
                  value={selectedRow.email}
                  onChange={(e) => getUpdateData("email", e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* delte dilog box */}

      <Dialog open={openModal} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "grey",
              color: "white",
              "&:hover": { backgroundColor: "darkgrey" }
            }}
            onClick={handleClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={deleteCustomer} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const columns = [
  {
    flex: 0.2,
    minWidth: 200,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="body2">
          {row.email}
        </Typography>
      );
    }
  },
  {
    flex: 0.2,
    minWidth: 200,
    field: "fullName",
    headerName: "Customer Name",
    renderCell: ({ row }) => {
      const { fullName } = row;
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <LinkStyled href="/apps/user/view/overview/">{fullName}</LinkStyled>
          </Box>
        </Box>
      );
    }
  },
  {
    flex: 0.15,
    minWidth: 100,
    headerName: "Orders",
    field: "orders",
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: "capitalize" }}
        >
          {row.orders}
        </Typography>
      );
    }
  },
  {
    flex: 0.15,
    minWidth: 100,
    headerName: "Tx No",
    field: "Tx No",
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: "capitalize" }}
        >
          {row.tx_no}
        </Typography>
      );
    }
  },
  {
    flex: 0.15,
    minWidth: 100,
    headerName: "Amount spent",
    field: "amount_spent",
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: "capitalize" }}
        >
          {row.amount_spent}
        </Typography>
      );
    }
  },
  {
    flex: 0.15,
    minWidth: 120,
    headerName: "White/Backlist Status",
    field: "White/Backlist_Status",
    renderCell: ({ row }) => {
      return (
        <div>
          <CustomChip
            skin="light"
            size="small"
            label={row.tx_status}
            color={
              row.tx_status === "Out Of Stock" ||
              row.tx_status === "Prod Sell Res" ||
              row.tx_status === "Blacklist"
                ? "error"
                : userStatusObj[row.tx_status]
            }
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "15px" }
            }}
          />
        </div>
      );
    }
  },
  {
    flex: 0.1,
    minWidth: 90,
    sortable: false,
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => (
      <RowOptions id={row.id} row={row} customer_id={row.customer_id} />
    )
  }
];

const UserTable = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { user } = state?.user;
  const [searchValue, setSearchValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [storeValue, setStore] = useState("");
  const [searchstatus, setSearchStatus] = useState(false);
  const { error, customers } = useSelector((state) => state.customers);
  const { stores } = useSelector((state) => state.store);
  const { transactions } = useSelector((state) => state.transactions);
  const [countCustomer, setCountCustomer] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const [formData, setFormData] = useState({
    stores: "",
    startDate: "",
    endDate: "",
    platforms: ""
  });
  const SearchFilterData = () => {
    var filteredData;
    if (formData.stores && formData.endDate && formData.startDate) {
      filteredData = transactions
        .filter((transaction) => {
          const transactionDate = new Date(transaction.date_created);
          const startDate = new Date(formData.startDate);
          const endDate = new Date(formData.endDate);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999); // Set to end of the day

          return transactionDate >= startDate && transactionDate <= endDate;
        })
        .filter((platformMatch) => {
          return platformMatch.platform_id === formData.platforms;
        })
        .filter((storeMatch) => {
          return storeMatch.store_id === formData.stores;
        })
        .slice()
        .sort((a, b) => b.line_items.quantity - a.line_items.quantity);
    }
    const uniqueEmailCounts = countUniqueEmails(filteredData);
    setCountCustomer(uniqueEmailCounts);
  };
  const countUniqueEmails = (data = "") => {
    const emailSet = new Set();
    data?.forEach((item) => {
      const email = item?.billing?.email;
      if (email) {
        emailSet?.add(email);
      }
    });
    return emailSet.size;
  };

  const handleDataSales = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFirstDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: start,
      endDate: end
    }));
  };

  useEffect(() => {
    if (storeValue && user?._id) {
      dispatch(getStoreProducts({ store_id: storeValue, user_id: user._id }));
    }
  }, [storeValue, user._id, dispatch]);
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setSearchStatus(true);
  };
  const handleReset = useCallback(() => {
    setSearchStatus(false);
    setStore("");
    setStartDate("");
    setEndDate("");
    dispatch(getCustomers());
  }, [dispatch]);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getCustomers());
  }, [error, dispatch, enqueueSnackbar]);

  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStores());
  }, [dispatch]);
  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };
  const handlePageSizeChange = (event) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      pageSize: event.target.value,
      page: 0 // Reset to the first page when page size changes
    }));
  };
  const rows = customers
    ? customers.map((customer) => ({
        id: customer?.id,
        firstname: customer?.first_name,
        lastname: customer?.last_name,
        email: customer?.email,
        fullName: `${customer?.first_name} ${customer?.last_name}`,
        orders: customer?.emailCount,
        tx_no: customer?.tx_no,
        amount_spent: customer?.totalAmount,
        status: customer?.status,
        tx_status: customer?.tx_status
      }))
    : [];
  const searchValueLower = searchValue?.toLowerCase();
  const filteredRows = rows?.filter((row) => {
    const fieldsToCheck = [
      row?.firstname,
      row?.lastname,
      row?.email,
      row?.fullName,
      row?.tx_no,
      row?.amount_spent?.toString(),
      row?.status,
      row?.tx_status
    ];
    return fieldsToCheck.some(
      (field) =>
        typeof field === "string" &&
        field.toLowerCase().includes(searchValueLower)
    );
  });
  return (
    <>
      <RouterBreadcrumbs />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Customer Records"
              sx={{
                pb: 4,
                "& .MuiCardHeader-title": { letterSpacing: ".15px" }
              }}
            />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="platform-select">
                      Select Platform
                    </InputLabel>
                    <Select
                      fullWidth
                      value={formData?.platforms}
                      id="platform-select"
                      label="Select Platform"
                      labelId="platform-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Platform" }}
                      onChange={(e) =>
                        handleDataSales("platforms", e.target.value)
                      }
                    >
                      <MenuItem value="629743b4c798c617e89d826e">
                        Woocommerce
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="store-select">Select Store</InputLabel>
                    <Select
                      fullWidth
                      value={formData?.stores}
                      id="store-select"
                      label="Select Store"
                      labelId="store-select"
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Store" }}
                      onChange={(e) =>
                        handleDataSales("stores", e.target.value)
                      }
                    >
                      {stores && stores.length > 0
                        ? stores.map((store) => (
                            <MenuItem key={store._id} value={store._id}>
                              {store.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Stores Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomDatePicker
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleFirstDateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      mb: 1
                    }}
                    variant="contained"
                    onClick={SearchFilterData}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 2,
                pb: 2
              }}
            >
              <Typography
                variant="body1"
                style={{ marginRight: "8px", color: "grey" }}
              >
                Number of New Customer : {countCustomer}
              </Typography>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {/* <Grid item xs={12}> */}
          <Card>
            <CardHeader
              title="Search Filters"
              sx={{
                pb: 4,
                "& .MuiCardHeader-title": { letterSpacing: ".15px" }
              }}
            />
            <CardContent>
              <Grid container spacing={6} alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      style={{ marginRight: "8px", color: "grey" }}
                    >
                      Search:
                    </Typography>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControl size="small">
                      <label htmlFor="empty-text-field">
                        <Typography variant="h5" style={{ color: "grey" }}>
                          <span style={{ fontSize: "15px" }}>
                            Entities per page
                          </span>
                        </Typography>
                      </label>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paginationModel.pageSize}
                        onChange={handlePageSizeChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Button
                    sx={{ mb: 1 }}
                    onClick={() => handleReset()}
                    variant="contained"
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <DataGrid
              autoHeight
              rows={
                searchstatus
                  ? filteredRows?.length > 0
                    ? filteredRows.slice(
                        paginationModel?.page * paginationModel?.pageSize,
                        (paginationModel?.page + 1) * paginationModel?.pageSize
                      )
                    : []
                  : rows.slice(
                      paginationModel.page * paginationModel.pageSize,
                      (paginationModel.page + 1) * paginationModel.pageSize
                    )
              }
              columns={columns}
              checkboxSelection
              disableRowSelectionOnClick
              onPageChange={(page) => handlePageChange(page)}
              onPaginationModelChange={handlePaginationModelChange}
              pageSize={paginationModel.pageSize}
              paginationMode={paginationModel}
              pageSizeOptions={[10, 25, 50]}
              pagination={true}
              rowCount={rows?.length}
              sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            />
          </Card>
          {/* </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default UserTable;
