/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 14:32:42
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch,useSelector } from "react-redux";
import "./Sidebar.css";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../../actions/userAction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SellIcon from '@mui/icons-material/Sell';
const navMenu = [
  {
    icon: <DashboardIcon />,
    label: "Dashboard",
    ref: "/admin/dashboard",
    submenu: []
  },
  {
    icon: <EmailIcon />,
    label: "Inventory",
    ref: "/inventory",
    submenu: []
  },
  {
    icon: <ShoppingCartIcon />,
    label: "WooCommerce",
    ref: "/woocommerce/stores",
    submenu: [
      {
        label: "Stores",
        ref: "/woocommerce/stores"
      },
      {
        label: "Products",
        ref: "/woocommerce/products"
      }
    ]
  },
  {
    icon: <ListAltIcon />,
    label: "Templates",
    ref: "/templates",
    submenu: [
      {
        label: "Main",
        ref: "/templates"
      },
      {
        label: "Replacement",
        ref: "/templates/replacement"
      }
    ]
  },
  {
    icon: <SettingsIcon />,
    label: "Settings",
    ref: "/settings",
    submenu: [
      {
        label: "Profile",
        ref: "/settings"
      },
      {
        label: "Security",
        ref: "/settings/security"
      },
      {
        label: "SMTP",
        ref: "/settings/smtp"
      },
      {
        label: "Alerts",
        ref: "/settings/alerts"
      }
    ]
  },
  {
    icon: <PaymentIcon />,
    label: "Transactions",
    ref: "/transactions",
    submenu: [
      {
        label: "All",
        ref: "/transactions"
      },
      {
        label: "Pending",
        ref: "/transactions/pending"
      },
      {
        label: "Add Transaction",
        ref: "/transactions/new"
      }
    ]
  },
  {
    icon: <EmailIcon />,
    label: "White/Black",
    ref: "/whiteblackAddIp",
    submenu: [
      {
        label: "Add IP",
        ref: "/whiteblackAddIp"
      },
      {
        label: "Add Email/domin",
        ref: "/whiteblackEmaildomain"
      }
    ]
  },
  {
    icon: <PersonIcon />,
    label: "Customers",
    ref: "/customer",
    submenu: []
  },
  {
    icon: <SellIcon />,
    label: "Sales Report",
    ref: "/Sales",
    submenu: []
  },
  {
    icon: <AccountCircleIcon />,
    label: "Integrations",
    ref: "/integrations",
    submenu: []
  },
  // {
  //   icon: <InventoryIcon />,
  //   label: "FAQ",
  //   ref: "/faq",
  //   submenu: []
  // },
  // {
  //   icon: <LogoutIcon />,
  //   label: "Logout",
  //   submenu: []
  // }
];

const findMatchingIntegration = (navMenu, integrations) => {
  for (let i = 0; i < navMenu?.length; i++) {
    const menuItem = navMenu[i];
    for (let j = 0; j < integrations?.length; j++) {
      const integration = integrations[j];
      if (menuItem.label === integration.name) {
        return { name: integration.name, status: integration.status };
      }
    }
  }
  return null;
};
const Sidebar = ({ activeTab, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const  {getIntegrationData}  = useSelector((state) => state.getintegration);
  const {intregationData} = getIntegrationData;
  const result = findMatchingIntegration(navMenu, intregationData);
  const { enqueueSnackbar } = useSnackbar();
  const [showSubMenu, setShowSubMenu] = useState(true);

  const handleLogout = () => {
    dispatch(logoutUser());
    enqueueSnackbar("Logout Successfully", { variant: "success" });
    navigate("/");
  };

  return (
    <aside className="mt-15 sidebar z-10 sm:z-0 block min-h-screen fixed left-0 pb-14 max-h-screen w-3/4 sm:w-1/6 bg-gray-800 text-white-sidebar overflow-x-hidden border-r">
      <div className="logo-box">
        <span className="app-brand-text demo menu-text fw-bold ms-2">
          {" "}
          <svg
            width="40"
            fill="none"
            height="22"
            viewBox="0 0 268 150"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fill="#666CFF"
              transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
            ></rect>
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fillOpacity="0.4"
              fill="url(#paint0_linear_7821_79167)"
              transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
            ></rect>
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fill="#666CFF"
              transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
            ></rect>
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fill="#666CFF"
              transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
            ></rect>
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fillOpacity="0.4"
              fill="url(#paint1_linear_7821_79167)"
              transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
            ></rect>
            <rect
              rx="25.1443"
              width="50.2886"
              height="143.953"
              fill="#666CFF"
              transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
            ></rect>
            <defs>
              <linearGradient
                y1="0"
                x1="25.1443"
                x2="25.1443"
                y2="143.953"
                id="paint0_linear_7821_79167"
                gradientUnits="userSpaceOnUse"
              >
                <stop></stop>
                <stop offset="1" stopOpacity="0"></stop>
              </linearGradient>
              <linearGradient
                y1="0"
                x1="25.1443"
                x2="25.1443"
                y2="143.953"
                id="paint1_linear_7821_79167"
                gradientUnits="userSpaceOnUse"
              >
                <stop></stop>
                <stop offset="1" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>{" "}
          &nbsp; EZDZSEND
        </span>
      </div>
      <div className="flex flex-col gap-0 my-8">
        <button
          onClick={() => setToggleSidebar(false)}
          className="sm:hidden bg-gray-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center"
        >
          <CloseIcon />
        </button>
        {navMenu.map((item, index) => {
          const { icon, label, ref, submenu } = item;
          let isDisabled = false;
          if(result && result?.['name'] === label && !result?.['status']){
             isDisabled = true;
          }
          return (
            <div className="relative" key={index}>
              {label === "Logout" ? (
                <Link
                  onClick={handleLogout}
                  className="hover:bg-gray-700 flex gap-3 items-center py-3 px-4 font-medium"
                >
                  <span>{icon}</span>
                  <span>{label}</span>
                </Link>
              ) : (
                <Link
                  to={!isDisabled ? ref : '#'}
                  onClick={() => {
                    if (!isDisabled) {
                    setShowSubMenu((prevShowSubMenu) =>
                      activeTab === index ? !prevShowSubMenu : prevShowSubMenu
                    );
                  }
                  }}
                  className={`${
                    activeTab === index ? "bg-gray-701" : "hover:bg-gray-700"
                  } flex gap-3 items-center py-3 px-4 font-medium ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                >
                  <span>{icon}</span>
                  <span className="set-icons-side">
                    {label} {submenu.length > 0 ? <ExpandMoreIcon /> : ""}
                  </span>
                </Link>
              )}

              {submenu.length > 0 && activeTab === index && showSubMenu && (
                <div className="left-0 mt-2 w-100 bg-white rounded-0 border-bottom-1 shadow-lg bg-gray-701 gap-3 font-medium">
                  {submenu.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.ref}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                   {subItem.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default Sidebar;
