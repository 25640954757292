/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 18:00:43
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  SMTP_API_REQUEST,
  SMTP_API_SUCCESS,
  SMTP_API_FAIL,
  Alert_API_REQUEST,
  Alert_API_SUCCESS,
  Alert_API_FAIL,
  SMTP_ALLDATA_REQUEST,
  SMTP_ALLDATA_SUCCESS,
  SMTP_DATA_FAIL,
  SMTP_UPDATE_REQUEST,
  SMTP_UPDATE_SUCCESS,
  SMTP_UPDATE_FAIL,
  SMTP_DELTE_REQUEST,
  DELETE_SMTP_DELTE_SUCCESS,
  DELETE_SMTP_DELTE_FAIL
} from "../constants/smtpConstants";
import axios from "axios";
export const smtpApi = (smtpdata) => async (dispatch) => {
  try {
    dispatch({ type: SMTP_API_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post("/api/v1/smtpapi", smtpdata, config);
    dispatch({
      type: SMTP_API_SUCCESS,
      payload: data.smtpApi
    });
  } catch (error) {
    dispatch({
      type: SMTP_API_FAIL,
      payload: error.response.data.message
    });
  }
};

export const getSmtpData = (id) => async (dispatch) => {
  try {
    dispatch({ type: SMTP_ALLDATA_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.get("/api/v1/getAllData/"+id, config);
    dispatch({
      type: SMTP_ALLDATA_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SMTP_DATA_FAIL,
      payload: error.response.data.message
    });
  }
};

export const updateSmtpData = (updateData, id) => async (dispatch) => {
  try {
    dispatch({ type: SMTP_UPDATE_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post(
      "/api/v1/updatesmtp/" + id,
      updateData,
      config
    );
    dispatch({
      type: SMTP_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SMTP_UPDATE_FAIL,
      payload: error.response.data.message
    });
  }
};

export const alertHandle = (alertdata) => async (dispatch) => {
  try {
    dispatch({ type: Alert_API_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post("/api/v1/alertapi", alertdata, config);
    dispatch({
      type: Alert_API_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: Alert_API_FAIL,
      payload: error.response.data.message
    });
  }
};

export const deleteSmtpData = (id) => async (dispatch) => {
  try {
    dispatch({ type: SMTP_DELTE_REQUEST });
    const { data } = await axios.delete(`/api/v1/smtpDelete/${id}`);

    dispatch({
      type: DELETE_SMTP_DELTE_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_SMTP_DELTE_FAIL,
      payload: error.response.data.message
    });
  }
};
