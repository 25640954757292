/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 14:23:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const ADD_TEMPLATES_REQUEST = "ADD_TEMPLATES_REQUEST";
export const UPDATE_TEMPLATES_REQUEST = "UPDATE_TEMPLATES_REQUEST";
export const UPDATE_TEMPLATES_SUCCESS = "UPDATE_TEMPLATES_SUCCESS";
export const ADD_TEMPLATES_SUCCESS = "ADD_TEMPLATES_SUCCESS";
export const DELETE_TEMPLATES_SUCCESS = "DELETE_TEMPLATES_SUCCESS";
export const ADD_TEMPLATES_FAIL = "ADD_TEMPLATES_FAIL";
export const UPDATE_TEMPLATES_FAIL = "UPDATE_TEMPLATES_FAIL";
export const DELETE_TEMPLATES_FAIL = "DELETE_TEMPLATES_FAIL";
export const NEW_TEMPLATES_RESET = "NEW_TEMPLATES_RESET";


export const CLEAR_ERRORS = "CLEAR_ERRORS";