/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 16:31:50
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import CustomChip from "../../@core/components/mui/chip";
import TableHeader from "../Layouts/Customer/list/TableHeader";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import { getStores } from "../../actions/storeAction";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import IconButton from "@mui/material/IconButton";
import Icon from "../../@core/components/icon";
import Menu from "@mui/material/Menu";
import BackdropLoader from "../Layouts/BackdropLoader";
import { getAddApiData } from "../../actions/addIpAction";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material";
import { getDomainApiData } from "../../actions/domainIpAction";
import { getStoreProducts } from "../../actions/productAction";
import {
  clearErrors,
  getTransactions,
  ResendCode,
  sendReplacement,
  updateTransactions,
  getWoocommerceTransactions,
  getMassActionDataUpdate,
  getCheckAllData
} from "../../actions/transactionsAction";
import { getInventory } from "../../actions/inventoryAction";
import { getTemplates } from "../../actions/templatesAction";
import CustomDatePicker from "../Layouts/forms/form-elements/pickers/CustomDatePicker";
import { FETCH_TRANSCATIONS_RESET } from "../../constants/transactionsConstants";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";

const ProductCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.name}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const AmountCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.total}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const QuantityCellRenderer = ({ value }) => {
  return (
    <div>
      <Typography variant="body2" style={{ height: "10" }}>
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {item.quantity}
            {index < value.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const Transactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleAddUserDrawer = () => setAddUserOpen(!addUserOpen);
  const state = useSelector((state) => state);
  const { addipData } = state?.getAddIpData;
  const addipTableData = addipData?.data;
  const { DomainIpAll } = state?.getDomainIpData;
  const { data } = DomainIpAll ?? "";
  const { user } = state?.user;
  const { products } = state.products;
  const { stores } = useSelector((state) => state.store);
  const { templates } = useSelector((state) => state.templates);
  const { loading, error, transactions, addtransactions, isUpdated } =
    useSelector((state) => state.transactions);
  const { inventorys } = useSelector((state) => state.inventory);
  const { Fetch } = useSelector((state) => state.fetchtransaction);
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [body, setBody] = useState("");
  const [inventoryValue, setInventory] = useState("");
  const [emailTemplatevalue, setEmailTemplate] = useState("");
  const [rowData, setRow] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [storeValue, setStore] = useState("");
  const [plan, setPlan] = useState("");
  const [value, setValue] = useState("");
  const [valueorderId, setValueOrderID] = useState("");
  const [valueProduct, setvalueProduct] = useState("");
  const [status, setStatus] = useState("");
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReplacement, setOpenReplacement] = useState(false);
  const [searchstatus, setSearchStatus] = useState(false);
  const [updateTranscation, setUpdateTranscation] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkProduct, setCheckProduct] = useState("");
  const [checkedData, setcheckedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [valueInventory, setValueInventory] = useState("");

  const userStatusObj = { Success: "success", failed: "error" };
  const transactionChangeData = transactions ? transactions : "";
  const handleFilter = useCallback(
    (val, type) => {
      setSearchStatus(false);
      setStore("");
      setValueOrderID("");
      setvalueProduct("");
      setPlan("");
      setStatus("");
      var filteredData;
      if (type === "email") {
        const queryLowered = val.toLowerCase();
        setValue(val);
        setCheckProduct("");
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter((transaction) =>
              transaction?.billing?.email.toLowerCase().includes(queryLowered)
            )
          : [];
      } else if (type === "orderId") {
        setCheckProduct("");
        setValueOrderID(val);
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter(
              (transaction) => transaction?.order_id === val
            )
          : [];
      } else if (type === "product") {
        setCheckProduct("product");
        const queryLowered = val.toLowerCase();
        setvalueProduct(val);
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter((transaction) =>
              transaction?.line_items?.some((item) =>
                item.name.toLowerCase().includes(queryLowered)
              )
            )
          : [];
      } else if (type === "Inventory") {
        setCheckProduct("Inventory");
        setValueInventory(val);
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter(
              (transaction) => transaction?.inventory_id === val
            )
          : [];
      } else {
        const queryLowered = val.toLowerCase();
        setValue(val);
        setCheckProduct("");
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter((transaction) =>
              transaction?.billing?.email.toLowerCase().includes(queryLowered)
            )
          : [];
      }
      if (filteredData.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setUpdateTranscation(filteredData.length > 0 ? filteredData : []);
    },
    [transactionChangeData]
  );

  const handleDateChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      const filteredTransactions = Array.isArray(transactionChangeData)
        ? transactionChangeData?.filter((transaction) => {
            const transactionDate = new Date(transaction.date_created);
            transactionDate.setHours(0);
            transactionDate.setMinutes(0);
            transactionDate.setSeconds(0);
            return (
              transactionDate >= new Date(start) &&
              transactionDate <= new Date(end)
            );
          })
        : [];
      if (filteredTransactions.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setUpdateTranscation(
        filteredTransactions.length > 0 ? filteredTransactions : []
      );
    },
    [transactionChangeData]
  );

  const handleReset = useCallback(() => {
    setSearchStatus(false);
    setUpdateTranscation([]);
    setPlan("");
    setValue("");
    setValueOrderID("");
    setvalueProduct("");
    setStatus("");
    setStore("");
    setStartDate("");
    setEndDate("");
    setValueInventory("");
    setCheckProduct("");
    dispatch(getTransactions());
  }, [dispatch]);

  const handleStoreChange = useCallback(
    (e) => {
      setSearchStatus(false);
      setValue("");
      setValueOrderID("");
      setvalueProduct("");
      setStore(e.target.value);
      const filteredData = Array.isArray(transactionChangeData)
        ? transactionChangeData?.filter(
            (transaction) => transaction.store_id === e.target.value
          )
        : [];
      if (filteredData.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setUpdateTranscation(filteredData.length > 0 ? filteredData : []);
    },
    [transactionChangeData]
  );

  const handlePlatformChange = useCallback((e) => {
    setPlan(e.target.value);
  }, []);

  const handleStatusChange = useCallback(
    (e) => {
      setStatus(e.target.value);
      setSearchStatus(false);
      setValue("");
      setValueOrderID("");
      setvalueProduct("");
      setStore("");
      let filteredData;
      if (e.target.value === "Pending") {
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter(
              (transaction) =>
                transaction.tx_status === "Out Of Stock" ||
                transaction.tx_status === "Prod Sell Res" ||
                transaction.tx_status === "Blacklist"
            )
          : [];
      } else {
        filteredData = Array.isArray(transactionChangeData)
          ? transactionChangeData?.filter(
              (transaction) => transaction.tx_status === e.target.value
            )
          : [];
      }
      if (filteredData.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setUpdateTranscation(filteredData.length > 0 ? filteredData : []);
    },
    [transactionChangeData]
  );

  useEffect(() => {
    if (storeValue && user?._id) {
      dispatch(getStoreProducts({ store_id: storeValue, user_id: user._id }));
    }
  }, [storeValue, user._id, dispatch]);

  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };
  const handleSelectionChange = (selectionModel) => {
    const selectedRows = selectionModel.map((selectedRowId) =>
      transactionChangeData?.find((row) => row._id === selectedRowId)
    );
    if (selectedRows && selectedRows.length > 0) {
      setRow(selectedRows.length > 0 ? selectedRows[0] : selectedRows);
      setcheckedData(selectedRows.length > 0 ? selectedRows : selectedRows);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleRowOptionsClick = (event, row) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const rowOptionsOpen = Boolean(anchorEl);
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleSendReplacement = () => {
    setEmail(rowData?.billing?.email);
    setInventory(rowData?.inventory_id);
    setEmailTemplate(rowData?.template_id);
    setQuantity(rowData?.quantity);
    handleRowOptionsClose();
    setOpenReplacement(true);
  };
  const handleResend = () => {
    if (selectedRow) {
      setEmail(selectedRow?.billing?.email);
      setSelectedIds(selectedRow?._id)
      handleRowOptionsClose();
      setOpen(true);
    }
  };
  const handleAccept = () => {
    dispatch(ResendCode(rowData?._id, rowData?.billing?.email));
    handleRowOptionsClose();
  };

  const handleActionChange = (type) => {
    dispatch(updateTransactions(rowData, type));
    handleRowOptionsClose();
  };

  const handleShowDetails = () => {
    handleRowOptionsClose();
    navigate(`/transactions/details`, { state: { rowData } });
  };

  const columns = [
    {
      flex: 0.25,
      field: "plateform",
      minWidth: 50,
      headerName: "Platform",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {/* {row.plateform} */}
                Woocommerce
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.3,
      minWidth: 40,
      field: "store",
      headerName: "Store",
      renderCell: ({ row }) => {
        var store;
        if (stores) {
          store = stores.find((store) => store._id === row.store_id);
        } else {
          store = "";
        }
        return (
          <Typography variant="body2">{store ? store?.name : ""}</Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 40,
      field: "order_id",
      headerName: "Order ID",
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.order_id}</Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: "billing",
      headerName: "Email",
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.billing?.email}</Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "line_items",
      headerName: "Product",
      renderCell: ({ row }) => <ProductCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 40,
      field: "total",
      headerName: "Amount",
      renderCell: ({ row }) => <AmountCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 10,
      field: "quantity",
      headerName: "Qty",
      renderCell: ({ row }) => <QuantityCellRenderer value={row.line_items} />
    },
    {
      flex: 0.2,
      minWidth: 155,
      field: "date_created",
      headerName: "Time",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row && row.date_created
            ? format(new Date(row.date_created), "yyyy-MM-dd HH:mm:ss")
            : ""}
        </Typography>
      )
    },
    {
      flex: 0.15,
      minWidth: 130,
      field: "tx_status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <div>
            <CustomChip
              skin="light"
              size="small"
              label={row.tx_status}
              color={
                row.tx_status === "Out Of Stock" ||
                row.tx_status === "Prod Sell Res"
                  ? "error"
                  : userStatusObj[row.tx_status]
              }
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { lineHeight: "15px" }
              }}
            />
            <IconButton
              size="small"
              onClick={(event) => handleRowOptionsClick(event, row)}
            >
              <Icon icon="mdi:dots-vertical" />
            </IconButton>
          </div>
        );
      }
    }
  ];

  const handleMaskAction = (actionType) => {
    let updateStatus;
    if (actionType === "Cancel") {
      updateStatus = "Cancelled";
    } else if (actionType === "Buyer") {
      updateStatus = "Success";
    } else if (actionType === "Refund") {
      updateStatus = "Refund";
    } else if (actionType === "Fraud") {
      updateStatus = "Fraud";
    }

    const filteredData = checkedData?.filter((transaction) => {
      if (
        actionType === "Buyer" &&
        (transaction.tx_status === "Out Of Stock" ||
          transaction.tx_status === "Prod Sell Res")
      ) {
        return false;
      } else if (
        transaction.tx_status === "Out Of Stock" ||
        transaction.tx_status === "Prod Sell Res" ||
        transaction.tx_status === "Blacklist"
      ) {
        return true;
      } else {
        transaction.tx_status = updateStatus;
        return true;
      }
    });
    dispatch(
      getMassActionDataUpdate(
        filteredData.length > 0 ? filteredData : [],
        updateStatus
      )
    );
  };

  const handleExportAction = () => {
    let csvContent = "";
    csvContent +=
      "Plateform,Store,Order ID,Email,Product,Amount,Qty,Time,Status\n";
    var ExportData =
      searchstatus === true
        ? []
        : updateTranscation.length > 0
        ? updateTranscation.slice(
            paginationModel.page * paginationModel.pageSize,
            (paginationModel.page + 1) * paginationModel.pageSize
          )
        : transactionChangeData?.slice(
            paginationModel.page * paginationModel.pageSize,
            (paginationModel.page + 1) * paginationModel.pageSize
          );

    // Add data rows
    ExportData.forEach((transaction) => {
      var store;
      if (stores) {
        store = stores.find((store) => store._id === transaction.store_id);
      } else {
        store = "";
      }
      var Amount = "";
      var Product = "";
      var Quantity = "";
      transaction?.line_items.forEach((item) => {
        Amount = item?.total ?? 0;
        Product = item?.name ?? "";
        Quantity = item?.quantity ?? "";
      });

      csvContent += `${"Woocommerce"},${store ? store?.name : ""},${
        transaction.order_id
      },${transaction.billing.email},${Product},${Amount},${Quantity},${
        transaction && transaction.date_created
          ? format(new Date(transaction.date_created), "yyyy-MM-dd HH:mm:ss")
          : ""
      },${transaction.tx_status}\n`;
    });
    // Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create URL for Blob
    const url = window.URL.createObjectURL(blob);
    // Create anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "transactions.csv";
    // Simulate click on anchor element
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleReplaceClose = () => {
    setOpenReplacement(false);
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError(true);
      return;
    }
    setOpen(false);
    if(selectedIds && email){
      dispatch(ResendCode(selectedIds, email));
    }
   
  };

  const handleSaveReplacement = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError(true);
      return;
    }
    if (!inventoryValue) {
      return;
    }
    if (!quantity) {
      setQuantityError(true);
      return;
    }
    if (!emailTemplatevalue) {
      return;
    }
    setOpenReplacement(false);
    dispatch(
      sendReplacement(
        rowData,
        email,
        inventoryValue,
        quantity,
        emailTemplatevalue,
        body
      )
    );
  };
  useEffect(() => {
    dispatch(getStores());
    dispatch(getTemplates());
    dispatch(getInventory());
    dispatch(getTransactions());
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Success", { variant: "success" });
    }
    if (addtransactions) {
      enqueueSnackbar("Transactions Added", { variant: "success" });
      dispatch(getTransactions());
      dispatch({ type: FETCH_TRANSCATIONS_RESET });
    }
  }, [error, isUpdated, addtransactions, dispatch, enqueueSnackbar]);

  useEffect(() => {
    dispatch(getWoocommerceTransactions());
    dispatch(getDomainApiData());
    dispatch(getAddApiData());
  }, [dispatch]);
  useEffect(() => {
    let queryLowered = "";
    data?.forEach((item, index) => {
      queryLowered = item?.domain?.toLowerCase();
    });

    const checkBlacklist =
      Array.isArray(transactionChangeData) && queryLowered?.length > 0
        ? transactionChangeData?.filter((transaction) => {
            if (
              transaction?.billing?.email.toLowerCase().includes(queryLowered)
            ) {
              transaction.tx_status = "blacklist";
              return true;
            }
            return false;
          })
        : [];
    if (checkBlacklist?.length > 0) {
      dispatch(
        getMassActionDataUpdate(checkBlacklist.length > 0 ? checkBlacklist : [])
      );
    }
  }, [transactionChangeData, data, dispatch]);

  useEffect(() => {
    let queryLowered = "";
    addipTableData?.forEach((item, index) => {
      if (item?.type.toLowerCase() === "blacklist") {
        queryLowered = item?.ip;
      }
    });

    const checkBlacklist =
      Array.isArray(transactionChangeData) && queryLowered?.length > 0
        ? transactionChangeData?.filter((transaction) => {
            if (transaction?.customer_ip_address.includes(queryLowered)) {
              transaction.tx_status = "blacklist";
              return true;
            }
            return false;
          })
        : [];
    if (checkBlacklist?.length > 0) {
      dispatch(
        getMassActionDataUpdate(checkBlacklist.length > 0 ? checkBlacklist : [])
      );
    }
  }, [transactionChangeData, addipTableData, dispatch]);

  if (Fetch) {
    dispatch(getTransactions());
    dispatch(getCheckAllData());
    dispatch({ type: FETCH_TRANSCATIONS_RESET });
  }
  return (
    <>
      <RouterBreadcrumbs />
      {loading && <BackdropLoader />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Search Filters"
              sx={{
                pb: 4,
                "& .MuiCardHeader-title": { letterSpacing: ".15px" }
              }}
            />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="plan-select">Select Plateform</InputLabel>
                    <Select
                      fullWidth
                      value={plan}
                      id="select-plan"
                      label="Select Plan"
                      labelId="plan-select"
                      onChange={handlePlatformChange}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Plateform" }}
                    >
                      <MenuItem value="629743b4c798c617e89d826e">
                        Woocommerce
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="role-select">Select Store</InputLabel>
                    <Select
                      fullWidth
                      value={storeValue}
                      id="select-role"
                      label="Select Store"
                      labelId="role-select"
                      onChange={handleStoreChange}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Store" }}
                    >
                      {stores && stores.length > 0
                        ? stores.map((store) => (
                            <MenuItem key={store._id} value={store._id}>
                              {store.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Stores Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select">Select Status</InputLabel>
                    <Select
                      fullWidth
                      value={status}
                      id="select-status"
                      label="Select Status"
                      labelId="status-select"
                      onChange={handleStatusChange}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Status" }}
                    >
                      <MenuItem value="Success">Completed</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Refund">Refund</MenuItem>
                      <MenuItem value="Cancelled">Cancelled</MenuItem>
                      <MenuItem value="Replacement">Replacement</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="Product-select">Select Product</InputLabel>
                    <Select
                      fullWidth
                      value={valueProduct}
                      id="select-Product"
                      label="Select Product"
                      labelId="Product-select"
                      onChange={(e) => handleFilter(e.target.value, "product")}
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Product" }}
                    >
                      {checkProduct !== "Inventory" &&
                      products &&
                      products.length > 0
                        ? products.map((product) => (
                            <MenuItem key={product._id} value={product.name}>
                              {product.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Stores Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="Inventory-select">
                      Select Inventory
                    </InputLabel>
                    <Select
                      fullWidth
                      value={valueInventory}
                      id="select-Inventory"
                      label="Select Inventory"
                      labelId="Inventory-select"
                      onChange={(e) =>
                        handleFilter(e.target.value, "Inventory")
                      }
                      sx={{ height: "45px", borderRadius: "1rem" }}
                      inputProps={{ placeholder: "Select Inventory" }}
                    >
                      {checkProduct !== "product" &&
                      inventorys &&
                      inventorys.length > 0
                        ? inventorys.map((inventory) => (
                            <MenuItem key={inventory._id} value={inventory._id}>
                              {inventory.name}
                            </MenuItem>
                          ))
                        : [
                            <MenuItem disabled key="no-inventory">
                              No Inventorys Available
                            </MenuItem>
                          ]}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextField
                    size="small"
                    value={value}
                    sx={{ mr: 2, mb: 2, width: "70%" }}
                    placeholder="Search Email"
                    onChange={(e) => handleFilter(e.target.value, "email")}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    size="small"
                    value={valueorderId}
                    sx={{ mr: 2, mb: 2, width: "100%" }}
                    placeholder="Search OrderID"
                    onChange={(e) => handleFilter(e.target.value, "orderId")}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomDatePicker
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <TableHeader
              value={value}
              valueorderId={valueorderId}
              valueProduct={valueProduct}
              handleFilter={handleFilter}
              handleReset={handleReset}
              toggle={toggleAddUserDrawer}
              handleMaskAction={handleMaskAction}
              handleExportAction={handleExportAction}
              handleAccept={handleAccept}
            />
            <div style={{ fontSize: "5px" }}>
              <DataGrid
                autoHeight
                rows={
                  searchstatus === true
                    ? []
                    : updateTranscation.length > 0
                    ? updateTranscation.slice(
                        paginationModel.page * paginationModel.pageSize,
                        (paginationModel.page + 1) * paginationModel.pageSize
                      )
                    : transactionChangeData?.slice(
                        paginationModel.page * paginationModel.pageSize,
                        (paginationModel.page + 1) * paginationModel.pageSize
                      )
                }
                getRowId={(row) => row._id}
                columns={columns}
                checkboxSelection
                onSelectionModelChange={async (selectionModel) => {
                  const selectedData = await transactionChangeData.find(
                    (row) =>
                      row._id === selectionModel[selectionModel.length - 1]
                  );
                  if (
                    typeof selectedData !== "undefined" &&
                    selectionModel.length > 0
                  ) {
                    setSelectedRow(selectedData);
                  }
                  handleSelectionChange(selectionModel);
                }}
                disableRowSelectionOnClick
                onPageChange={(page) => handlePageChange(page)}
                onPaginationModelChange={handlePaginationModelChange}
                pageSize={paginationModel.pageSize}
                paginationMode={paginationModel}
                pageSizeOptions={[10, 25, 50]}
                pagination={true}
                rowCount={transactionChangeData?.length}
                getRowClassName={(params) =>
                  params.row.line_items.length > 1 ? "single-item-row" : ""
                }
                sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
              />
              <Menu
                keepMounted
                anchorEl={anchorEl}
                open={rowOptionsOpen}
                onClose={handleRowOptionsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                PaperProps={{ style: { minWidth: "8rem" } }}
              >
                {rowData?.tx_status === "Accept" ||
                rowData?.tx_status === "Out Of Stock" ||
                rowData?.tx_status === "Prod Sell Res" ||
                rowData?.tx_status === "blacklist" ||
                rowData?.tx_status === "Cancelled" ||
                rowData?.tx_status === "Fraud" ||
                rowData?.tx_status === "Refund" ? (
                  <div>
                    <MenuItem
                      onClick={handleAccept}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Accept buyer
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleActionChange("Cancelled")}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Mark as Cancelled
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleActionChange("Refund")}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Mark as Refund
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleActionChange("Fraud")}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Mark as Fraud
                    </MenuItem>
                    <MenuItem
                      onClick={handleShowDetails}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Show details
                    </MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem
                      onClick={handleSendReplacement}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Send replacement
                    </MenuItem>
                    <MenuItem
                      onClick={handleResend}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Resend
                    </MenuItem>
                    <MenuItem
                      onClick={handleShowDetails}
                      sx={{ "& svg": { mr: 2 } }}
                    >
                      Show details
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </div>
          </Card>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          {loading && <BackdropLoader />}
          <DialogTitle>Resend codes</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="email"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  id="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailError}
                  helperText={emailError ? "Store Email is required" : ""}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openReplacement} onClose={handleReplaceClose}>
          {loading && <BackdropLoader />}
          <DialogTitle>Send Replacement</DialogTitle>
          <DialogContent>
            <Grid container style={{ marginTop: 5 }} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailError}
                  helperText={emailError ? "Email is required" : ""}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-select">Inventory</InputLabel>
                  <Select
                    fullWidth
                    value={inventoryValue}
                    id="select-role"
                    label="Select Store"
                    labelId="role-select"
                    onChange={(e) => setInventory(e.target.value)}
                    sx={{ height: "50px" }}
                    inputProps={{ placeholder: "Select Store" }}
                  >
                    {inventorys && inventorys.length > 0
                      ? inventorys.map((inventory) => (
                          <MenuItem key={inventory._id} value={inventory._id}>
                            {inventory.name}
                          </MenuItem>
                        ))
                      : [
                          <MenuItem disabled key="no-inventory">
                            No Inventory Available
                          </MenuItem>
                        ]}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Quantity"
                  variant="outlined"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  error={quantityError}
                  helperText={quantityError ? "Quantity is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-select">Email Templates</InputLabel>
                  <Select
                    fullWidth
                    value={emailTemplatevalue}
                    id="select-role"
                    label="Select Store"
                    labelId="role-select"
                    onChange={(e) => setEmailTemplate(e.target.value)}
                    sx={{ height: "50px" }}
                    inputProps={{ placeholder: "Select Store" }}
                  >
                    {templates && templates.length > 0
                      ? templates.map((store) => (
                          <MenuItem key={store._id} value={store._id}>
                            {store.name}
                          </MenuItem>
                        ))
                      : [
                          <MenuItem disabled key="no-inventory">
                            No Templates Available
                          </MenuItem>
                        ]}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Body"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReplaceClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveReplacement} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default Transactions;
