/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 24/06/2024 - 10:47:12
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 24/06/2024
    * - Author          : admin
    * - Modification    : 
**/
import React,{useState} from 'react';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import OptionsMenu from '../../../../@core/components/option-menu';
import ReactApexcharts from 'react-apexcharts';
import { hexToRGBA } from '../../../../@core/utils/hex-to-rgba';

const convertAmountToNumber = (amount) => {
  return parseFloat(amount.replace(/[^0-9.-]+/g, ""));
};

function gettopInventoryAmountData(getData, filter) {
  let data;
  if (filter === "Last Month") {
    data = getData['montly'];
  } else if (filter === "Last 28 Days") {
    data = getData['last28day'];
  } else if (filter === "Last Year") {
    data = getData['yearly'];
  } else {
    data = getData['allData'];
  }
  return data; // Returning the data for further use
}


const TopSellingProducts = (props) => {
  let {transcation_inventory } = props;
  const theme = useTheme();
   const [filter,setFilter] = useState();
  let topInventoryAmountData = gettopInventoryAmountData(transcation_inventory,filter);
  const formattedData = topInventoryAmountData?.map(item => ({
    ...item,
    inventroyTotalAmount: `${parseFloat(item.inventroyTotalAmount.replace('$', ''))}`
  }));
  const all_Data = formattedData.slice().sort((a, b) => convertAmountToNumber(b.inventroyTotalAmount) - convertAmountToNumber(a.inventroyTotalAmount));
  const series = [
    {
      name: 'Sales',
      data: all_Data.map(item => parseFloat(item.inventroyTotalAmount))
    }
  ];

  const numberFormatter = (val) => {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M';
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    }
    return val.toFixed(1);
  };

  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        barHeight: '60%',
        horizontal: true,
        distributed: true,
        startingShape: 'rounded'
      }
    },
    dataLabels: {
      offsetY: 8,
      style: {
        fontWeight: 500,
        fontSize: '0.875rem'
      },
      formatter: numberFormatter
    },
    grid: {
      strokeDashArray: 8,
      borderColor: theme.palette.divider,
      xaxis: {
        lines: { show: true }
      },
      yaxis: {
        lines: { show: false }
      },
      padding: {
        top: -18,
        left: 15,
        right: 33,
        bottom: 10
      }
    },
    colors: [
      '#666cff',
      hexToRGBA(theme.palette.success.light, 1),
      hexToRGBA(theme.palette.warning.light, 1),
      hexToRGBA(theme.palette.info.light, 1),
      hexToRGBA(theme.palette.error.light, 1)
    ],
    legend: { show: false },
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    xaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: all_Data.map(item => item.name),
      labels: {
        formatter: numberFormatter,
        style: {
          fontSize: '0.875rem',
          colors: theme.palette.text.disabled
        }
      }
    },
    yaxis: {
      labels: {
        align: theme.direction === 'rtl' ? 'right' : 'left',
        style: {
          fontWeight: 600,
          fontSize: '0.875rem',
          colors: theme.palette.text.primary
        },
        offsetX: -8
      }
    }
  };

  return (
    <Card sx={{ borderRadius: 3, boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px' }}>
      <CardHeader
        title='Top 5 Inventory By Amount'
        subheaderTypographyProps={{ sx: { lineHeight: 1.429 } }}
        titleTypographyProps={{ sx: { letterSpacing: '0.15px', fontSize: "1.125rem", fontWeight: "600", color: "#636578" } }}
        action={
          <OptionsMenu
            options={['Last 28 Days', 'Last Month', 'Last Year']}
            iconButtonProps={{ size: 'small', className: 'card-more-options' }}
            setFilter={setFilter}
          />
        }
      />
      <CardContent
        sx={{
          p: '0 !important',
          '& .apexcharts-canvas .apexcharts-yaxis-label': { fontSize: '0.875rem', fontWeight: 600, paddingRight: 10 },
          '& .apexcharts-canvas .apexcharts-xaxis-label': { fontSize: '0.875rem', fill: theme.palette.text.disabled, paddingRight: 10 },
          '& .apexcharts-data-labels .apexcharts-datalabel': {
            fontWeight: 500,
            fontSize: '0.875rem',
            fill: theme.palette.common.white
          }
        }}
      >
        <ReactApexcharts type='bar' height={332} series={series} options={options} />
      </CardContent>
    </Card>
  );
};

export default TopSellingProducts;
