/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 04/04/2024 - 18:00:43
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import {
    ADD_STORE_REQUEST,
    UPDATE_STORE_REQUEST,
    ADD_STORE_SUCCESS,
    UPDATE_STORE_SUCCESS,
    DELETE_STORE_SUCCESS,
    UPDATE_STORE_FAIL,
    DELETE_STORE_FAIL,
    ADD_STORE_FAIL,
    CLEAR_ERRORS,
} from '../constants/storeConstants';
import axios from 'axios';

// Add Store
export const addStore = (user_id, platform_id, name, email, store_url, consumer_key, consumer_secret) => async (dispatch) => {
    try {
        dispatch({ type: ADD_STORE_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }
        const { data } = await axios.post(
            '/api/v1/store/add',
            {
            user_id,
            platform_id,
            name,
            email,
            store_url,
            consumer_key,
            consumer_secret
            },
            config
        );
        dispatch({
            type: ADD_STORE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: ADD_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getStores = () => async (dispatch) => {
    try {
        dispatch({ type: ADD_STORE_REQUEST });

        const { data } = await axios.get('/api/v1/stores/all');

        dispatch({
            type: ADD_STORE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const updateStoreList = (storeData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_STORE_REQUEST });
        const config = { header: { "Content-Type": "application/json" } }
        const { data } = await axios.put(`/api/v1/stores/${storeData?._id}`, storeData, config);

        dispatch({
            type: UPDATE_STORE_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Delete Store 
export const deleteStore = (id) => async (dispatch) => {
    try {
        dispatch({ type: ADD_STORE_REQUEST });
        const { data } = await axios.delete(`/api/v1/stores/${id}`);

        dispatch({
            type: DELETE_STORE_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Clear All Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
