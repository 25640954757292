/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 12/04/2024 - 12:06:47
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 12/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState, useCallback, useEffect } from "react";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import BackdropLoader from "../../Layouts/BackdropLoader";
import MetaData from "../../Layouts/MetaData";
import Icon from "../../../@core/components/icon";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../../@core/components/mui/chip";
import TableHeader from "../../Admin/Woocommerce/TableHeader";
import RouterBreadcrumbs from "../../Layouts/Breadcrumbs";
import AddStorePopup from "../../Admin/Woocommerce/AddStorePopup";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  getStores,
  updateStoreList,
  deleteStore
} from "../../../actions/storeAction";
import { getIntegrationsStore } from "../../../actions/userAction";
import { NEW_STORE_RESET } from "../../../constants/storeConstants";

const userStatusObj = {
  Active: "success",
  Inactive: "error"
};

const RowOptions = ({ _id, status }) => {
  var statusData = { _id, status: status === "Active" ? "Inactive" : "Active" };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleActiveInactive = () => {
    dispatch(updateStoreList(statusData));
    handleRowOptionsClose();
  };

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
    handleRowOptionsClose();
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmationOpen(false);
    dispatch(deleteStore(_id));
    handleRowOptionsClose();
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <Icon icon="mdi:dots-vertical" />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem onClick={handleActiveInactive} sx={{ "& svg": { mr: 2 } }}>
          {status === "Active" ? (
            <Icon icon="mdi:account-on-outline" fontSize={20} />
          ) : (
            <Icon icon="mdi:account-off-outline" fontSize={20} />
          )}
          Set as {status === "Active" ? "Inactive" : "Active"}
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ "& svg": { mr: 2 } }}>
          <Icon icon="mdi:delete-outline" fontSize={20} />
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Store?"}</DialogTitle>
        <DialogContent>
          <p className="text-gray-500">
            Do you really want to delete? This process cannot be undone.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleDeleteCancel}
            className="py-2 px-6 rounded shadow bg-gray-400 hover:bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => handleDeleteConfirm(_id)}
            className="py-2 px-6 ml-4 rounded bg-red-600 hover:bg-red-700 text-white shadow"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Stores = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, stores, isUpdated, isDeleted, addstore } =
    useSelector((state) => state.store);
  const [value, setValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [updatestore, setUpdateStores] = useState([]);

  const handleFilter = useCallback(
    (val) => {
      setValue(val);
      const queryLowered = val.toLowerCase();
      const filteredData = stores.filter(
        (store) =>
          store.name.toLowerCase().includes(queryLowered) ||
          store.email.toLowerCase().includes(queryLowered)
      );
      setUpdateStores(filteredData);
    },
    [stores]
  );

  const handleStoreChange = (id, newValue, type) => {
    if (stores && stores.length > 0) {
      let storeIndex;
      if (updatestore.length > 0) {
        storeIndex = updatestore.findIndex((store) => store._id === id);
      } else {
        storeIndex = stores.findIndex((store) => store._id === id);
      }
      if (storeIndex !== -1) {
        const updatedStores =
          updatestore.length > 0 ? [...updatestore] : [...stores];
        updatedStores[storeIndex] = {
          ...updatedStores[storeIndex],
          [type === "name" ? "name" : "email"]: newValue
        };
        // Update the state with the new store data
        setUpdateStores(updatedStores);
      }
    }
  };
  const handleUpdate = (row) => {
    dispatch(updateStoreList(row));
  };
  const columns = [
    {
      flex: 0.2,
      minWidth: 100,
      field: "name",
      headerName: "Store Name",
      renderCell: ({ row }) => (
        <TextField
          type="text"
          variant="standard"
          value={row.name}
          onChange={(e) => handleStoreChange(row._id, e.target.value, "name")}
        />
      )
    },
    {
      flex: 0.2,
      minWidth: 300,
      field: "store_url",
      headerName: "Store Url",
      renderCell: ({ row }) => {
        return (
          <Typography noWrap variant="body2">
            {row.store_url}
          </Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 130,
      field: "email",
      headerName: "Email",
      renderCell: ({ row }) => (
        <TextField
          type="text"
          variant="standard"
          value={row.email}
          onChange={(e) => handleStoreChange(row._id, e.target.value, "email")}
        />
      )
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <CustomChip
            skin="light"
            size="small"
            label={row.status}
            color={userStatusObj[row.status]}
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" }
            }}
          />
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <div>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => handleUpdate(row)}
          >
            Update
          </Button>
          <RowOptions _id={row._id} status={row.status} />
        </div>
      )
    }
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Store Updated", { variant: "success" });
      dispatch({ type: NEW_STORE_RESET });
    }
    if (isDeleted) {
      enqueueSnackbar("Store Deleted", { variant: "success" });
      dispatch({ type: NEW_STORE_RESET });
    }
    dispatch(getStores());
    dispatch(getIntegrationsStore());
  }, [dispatch,enqueueSnackbar,error, addstore, isDeleted, isUpdated]);

  return (
    <>
      <MetaData title="Stores | EZDZsender" />
      {loading && <BackdropLoader />}
      <RouterBreadcrumbs />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <TableHeader
              value={value}
              type="stores"
              placeholder="Search Name/Email"
              handleFilter={handleFilter}
              setOpenModal={setOpenModal}
            />
            <DataGrid
              autoHeight
              rows={
                updatestore.length > 0
                  ? updatestore
                  : stores && stores.length > 0
                  ? stores.map((store) => ({ ...store, id: store._id }))
                  : []
              }
              getRowId={(row) => row._id}
              columns={columns}
              disableRowSelectionOnClick
              pageSizeOptions={[10, 25, 50]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            />
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddStorePopup
          onSave={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </>
  );
};

export default Stores;
