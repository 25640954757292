/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 16/05/2024 - 11:24:41
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 16/05/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCustomers } from "../../actions/customerAction";

const CustomerDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { customers, isUpdated } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch, isUpdated]);

  const customer_id = params.id;

  const columns = [
    {
      flex: 0.2,
      minWidth: 250,
      field: "firstname",
      headerName: "First Name",
      renderCell: ({ row }) => {
        return (
          <Typography noWrap variant="body2">
            {row.first_name}
          </Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "lastname",
      headerName: "Last Name",
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ textTransform: "capitalize" }}
          >
            {row.last_name}
          </Typography>
        );
      }
    },
    {
      flex: 0.15,
      minWidth: 250,
      headerName: "Email",
      field: "email",
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ textTransform: "capitalize" }}
          >
            {row.email}
          </Typography>
        );
      }
    },
    {
      flex: 0.15,
      minWidth: 120,
      headerName: "Orders",
      field: "orders",
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ textTransform: "capitalize" }}
          >
            {row.emailCount}
          </Typography>
        );
      }
    },
    {
      flex: 0.15,
      minWidth: 120,
      headerName: "Total Amount",
      field: "amount_spent",
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ textTransform: "capitalize" }}
          >
            {row.totalAmount}
          </Typography>
        );
      }
    }
  ];

  const rows = customers
    .filter((customer) => customer.id === customer_id)
    .map((customer) => ({
      id: customer.id,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      emailCount: customer.emailCount,
      totalAmount: customer.totalAmount,
      status: customer.status,
    }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            hideFooterPagination
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDetail;
