/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 17:18:47
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const FETCH_STORE_PRODUCT_REQUEST = "FETCH_STORE_PRODUCT_REQUEST";
export const FETCH_STORE_PRODUCT_FAIL = "FETCH_STORE_PRODUCT_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const VENDOR_PRODUCTS_REQUEST = "VENDOR_PRODUCTS_REQUEST";
export const VENDOR_PRODUCTS_SUCCESS = "VENDOR_PRODUCTS_SUCCESS";
export const VENDOR_PRODUCTS_FAIL = "VENDOR_PRODUCTS_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const REMOVE_PRODUCT_DETAILS = "REMOVE_PRODUCT_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";