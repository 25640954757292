/**
 * @description      :
 * @author           : VENDOR
 * @group            :
 * @created          : 18/04/2024 - 17:17:58
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : VENDOR
 * - Modification    :
 **/
import {
  VENDOR_PRODUCTS_FAIL,
  VENDOR_PRODUCTS_REQUEST,
  FETCH_STORE_PRODUCT_REQUEST,
  VENDOR_PRODUCTS_SUCCESS,
  CLEAR_ERRORS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_RESET,
  DELETE_PRODUCT_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_DETAILS,
} from "../constants/productConstants";

export const productsReducer = (
  state = { products: [] },
  { type, payload }
) => {
  switch (type) {
    case VENDOR_PRODUCTS_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case DELETE_PRODUCT_REQUEST:
    case FETCH_STORE_PRODUCT_REQUEST:
      return {
        loading: true,
        products: []
      };
    case VENDOR_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: payload
      };
    case VENDOR_PRODUCTS_FAIL:
      return {
        loading: false,
        error: payload
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: payload
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: payload
      };
    case UPDATE_PRODUCT_FAIL:
    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false
      };
    case DELETE_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: {} },
  { type, payload }
) => {
  switch (type) {
    case PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        product: payload
      };
    case PRODUCT_DETAILS_FAIL:
      return {
        loading: false,
        error: payload
      };
    case REMOVE_PRODUCT_DETAILS:
      return {
        ...state,
        product: {}
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

