/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 21/05/2024 - 17:29:05
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/05/2024
    * - Author          : admin
    * - Modification    : 
**/
import {
    DOMAINIP_API_REQUEST,
    DOMAINIP_API_SUCCESS,
    DOMAINIP_API_FAIL,
    GETDOMAINIPData_API_REQUEST,
    GETDOMAINIPData_API_SUCCESS,
    GETDOMAINIPData_API_FAIL,
    UPDATE_DOMAIN_API_REQUEST,
   UPDATE_DOMAIN_API_SUCCESS,
   UPDATE_DOMAIN_API_FAIL,

   DELETE_DOMAIN_API_REQUEST,
   DELETE_DOMAIN_API_SUCCESS,
   DELETE_DOMAIN_API_FAIL
} from '../constants/domainIpConstants';
export const DomainipReducer = (state = { DomainipData: {} }, { type, payload }) => {
    switch (type) {
        case DOMAINIP_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DOMAINIP_API_SUCCESS:
            return {
                ...state,
                loading: false,
                DomainipData: payload,
            };
        case DOMAINIP_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const getDomainIpData = (state = { DomainipData: {} }, { type, payload }) => {
    switch (type) {
        case GETDOMAINIPData_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GETDOMAINIPData_API_SUCCESS:
            return {
                ...state,
                loading: false,
                DomainIpAll: payload,
            };
        case GETDOMAINIPData_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const updateDomainIpData = (state = { DomainipData: {} }, { type, payload }) => {
    switch (type) {
        case UPDATE_DOMAIN_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DOMAIN_API_SUCCESS:
            return {
                ...state,
                loading: false,
                DomainipData: payload,
            };
        case UPDATE_DOMAIN_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};


export const deleteDomainApi = (state = { DomainipData: {} }, { type, payload }) => {
    switch (type) {
        case DELETE_DOMAIN_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_DOMAIN_API_SUCCESS:
            return {
                ...state,
                loading: false,
                DomainipData: payload,
            };
        case DELETE_DOMAIN_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};