/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 18:39:59
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { getDashbordDataTransactions } from "../../../../actions/transactionsAction";
// ** Icon Imports
import Icon from "../../../../@core/components/icon";
import { useSelector, useDispatch } from "react-redux";
// ** Custom Components Imports
import CustomAvatar from "../../../../@core/components/mui/avatar";

const renderStats = (salesData) => {
  return salesData.map((sale, index) => (
    <Grid item xs={12} sm={2.4} key={index}>
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <CustomAvatar
          skin="light"
          variant="rounded"
          color={sale.color}
          sx={{ mr: 1 }}
        >
          {sale.icon}
        </CustomAvatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h7" sx={{ fontWeight: 600 }}>
            {sale.stats}
          </Typography>
          <Typography variant="caption">{sale.title}</Typography>
        </Box>
      </Box>
    </Grid>
  ));
};

const EcommerceSalesOverview = () => {
  const dispatch = useDispatch();
  const { TranscationDashbordData } = useSelector(
    (state) => state?.transactionsDashbordData
  );
  let totalSuccess = 0;
let totalRefund = 0;
let totalFraud = 0;
let totalAmount = 0;
let uniqueCustomers = 0;

if (
  TranscationDashbordData?.transactions &&
  TranscationDashbordData.transactions?.['daily_data'].length > 0
) {
  const customerSet = new Set();

  TranscationDashbordData.transactions?.['daily_data'].forEach((transaction) => {
    if (transaction.tx_status === "Out Of Stock") {
      return;
    }
    if (transaction && transaction.tx_status === "Success") {
      customerSet.add(transaction.billing?.email); // Use email to identify unique customers
    }
    totalAmount += transaction.tx_status === "Success" ? transaction.total : 0;
    
    switch (transaction.tx_status) {
      case "Success":
        totalSuccess++;
        break;
      case "Refund":
        totalRefund++;
        break;
      case "Fraud":
        totalFraud++;
        break;
      default:
        break;
    }
  });

  uniqueCustomers = customerSet.size; // Get the count of unique customers
}
  
  const totalCustomer = uniqueCustomers;
  const salesData = [
    {
      stats: totalCustomer ? totalCustomer : 0,
      color: "primary",
      title: "Customers",
      icon: <Icon icon="mdi:account-outline" />
    },
    {
      stats: "$"+totalAmount,
      color: "warning",
      title: "Total Amount",
      icon: <Icon icon="mdi:poll" />
    },
    {
      color: "info",
      stats: totalSuccess,
      title: "Transactions",
      icon: <Icon icon="mdi:trending-up" />
    },
    {
      color: "info",
      stats: totalRefund,
      title: "Refund",
      icon: <Icon icon="mdi:trending-up" />
    },
    {
      color: "info",
      stats: totalFraud,
      title: "Fraud",
      icon: <Icon icon="mdi:trending-up" />
    }
  ];

  useEffect(() => {
    dispatch(getDashbordDataTransactions());
  }, [dispatch]);
  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px"
      }}
    >
      <CardHeader
        sx={{ pb: 3.25 }}
        title="Daily Sales Overview"
        titleTypographyProps={{ variant: "h6" }}
        subheader={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": { color: "success.main" }
            }}
          >
            <Typography variant="caption" sx={{ mr: 1.5 }}>
              All Stores
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {renderStats(salesData)}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EcommerceSalesOverview;
