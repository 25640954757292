/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 05/04/2024 - 11:36:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 05/04/2024
 * - Author          : admin
 * - Modification    :
 **/

import React, { useEffect, useState } from "react";
import MetaData from "../Layouts/MetaData";
import BlankLayout from "../../@core/layouts/BlankLayout";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useParams } from "react-router-dom";
import axios from "axios";
const FilesList = () => {
  const { id } = useParams();
  const [sendCode, setSendCode] = useState([])

  const downloadAllFiles = async () => {
    for (const url of sendCode) {
      try {
        console.log("Downloading from url:", url);
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = url.substring(url.lastIndexOf('/') + 1); // Extracting filename from URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/v1/transactions/urls/${id}`);
        if (data?.success) {
          setSendCode(data?.urlsArray);
        }
      } catch (error) {
        console.error("Error fetching transaction URLs:", error);
      }
    };
  
    fetchData();
  }, [id]);
  

  return (
    <div>
      <MetaData title="Files List" />

      <div className="content-center">
        <button onClick={downloadAllFiles}>Download All Files</button>
        
        <ImageList
          sx={{ width: "100%", maxWidth: "1250px", margin: "0 auto" }}
          cols={3}
          rowHeight={200}
        >
          {sendCode.map((item) => (
            <ImageListItem key={item}>
              <a
                href={item}
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              >
                <img
                  src={item}
                  alt=''
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  onError={(e) => {
                    e.target.src = "/images/icons/164x164.png";
                  }}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
};

FilesList.getLayout = (page) => <BlankLayout>{page}</BlankLayout>;

export default FilesList;
