/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 21/06/2024 - 11:27:31
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 21/06/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  IntegrationsStore,
  getIntegrationsStore
} from "../../actions/userAction";

const Integrations = () => {
  const dispatch = useDispatch();
  const { getIntegrationData } = useSelector((state) => state.getintegration);
  const { success, intregationData } = getIntegrationData;
  const [integrations, setIntegrations] = useState([]);
  const [refresh, setRefresh] = useState(false);
  let initialMount = true;

  useEffect(() => {
    if (intregationData && intregationData?.length > 0) {
      setIntegrations(intregationData);
    }
  }, [success, intregationData]);

  useEffect(() => {
    if (initialMount) {
      if (integrations && integrations?.length > 0) {
        dispatch(IntegrationsStore(integrations));
      }
      initialMount = false;
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    dispatch(getIntegrationsStore());
  }, [dispatch]);

  const handleSwitchChange = (id) => {
    setIntegrations((prevIntegrations) =>
      prevIntegrations.map((integration) =>
        integration._id === id
          ? { ...integration, status: !integration.status }
          : integration
      )
    );
    window.location.reload();
    setRefresh(!refresh);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.name}</Typography>;
      }
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={params.row.status}
                  onChange={() => handleSwitchChange(params.row._id)}
                />
              }
              label={params.row.status ? "Active" : "Inactive"}
            />
          </Box>
        );
      }
    }
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={
          integrations && integrations.length > 0
            ? integrations.map((integration) => ({
                ...integration,
                id: integration._id
              }))
            : []
        }
        getRowId={(row) => row._id}
        columns={columns}
        checkboxSelection={false} // Disable checkbox selection
      />
    </div>
  );
};

export default Integrations;
