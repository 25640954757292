/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 12/04/2024 - 14:41:14
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 12/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState, useCallback, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import BackdropLoader from "../../Layouts/BackdropLoader";
import MetaData from "../../Layouts/MetaData";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import CustomChip from "../../../@core/components/mui/chip";
import TableHeader from "../../Admin/Woocommerce/TableHeader";
// import AddUserDrawer from "../../Layouts/Customer/list/AddUserDrawer";
import RouterBreadcrumbs from "../../Layouts/Breadcrumbs";
import AddStorePopup from "../../Admin/Woocommerce/AddStorePopup";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Icon from "../../../@core/components/icon";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  getVendorProducts,
  updateProduct,
  fetchAllStoreProduct
} from "../../../actions/productAction";
import { getStores } from "../../../actions/storeAction";
import { getInventory } from "../../../actions/inventoryAction";
import { getTemplates } from "../../../actions/templatesAction";
import {
  UPDATE_PRODUCT_RESET,
  REMOVE_PRODUCT_DETAILS
} from "../../../constants/productConstants";

const userStatusObj = {
  Active: "success",
  Inactive: "error"
};

const RowOptions = ({ _id, status }) => {
  var statusData = { _id, status: status === "Active" ? "Inactive" : "Active" };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActiveInactive = () => {
     dispatch(updateProduct(statusData));
    handleRowOptionsClose();
  };

  const handleViewDetails = () => {
    handleRowOptionsClose();
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <Icon icon="mdi:dots-vertical" />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem onClick={handleActiveInactive} sx={{ "& svg": { mr: 2 } }}>
          Set as {status === "Active" ? "Inactive" : "Active"}
        </MenuItem>
        <MenuItem
          component={Link}
          sx={{ "& svg": { mr: 2 } }}
          onClick={handleViewDetails}
          to={`/woocommerce/products/view/${_id}`}
        >
          <Icon icon="mdi:eye-outline" fontSize={20} />
          View
        </MenuItem>
      </Menu>
    </>
  );
};

const Products = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, isUpdated, products } = useSelector(
    (state) => state.products
  );
  const { stores } = useSelector((state) => state.store);
  const { templates } = useSelector((state) => state.templates);
  const { inventorys } = useSelector((state) => state.inventory);
  const [value, setValue] = useState("");
  // const [addUserOpen, setAddUserOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [storeName, setStore] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [updateproducts, setUpdateProducts] = useState([]);
  
  const handleStoreChange = useCallback(
    (e) => {
      setValue("");
      let storeValue = e.target.value;
      setStore(storeValue);
      const filteredData = products.filter(
        (product) => product.store_id === storeValue
      );
      setUpdateProducts(filteredData.length > 0 ? filteredData : []);
    },
    [products]
  );
  const handleSyncProduct = useCallback(
    (e) => {
      dispatch(fetchAllStoreProduct(stores));
    },
    [stores, dispatch] 
  );
  
  const handleFilter = useCallback(
    (val) => {
      setValue(val);
      setStore("");
      const queryLowered = val.toLowerCase();
      const filteredData = products.filter((product) =>
        product.name.toLowerCase().includes(queryLowered)
      );
      setUpdateProducts(filteredData.length > 0 ? filteredData : []);
    },
    [products]
  );

  // const toggleAddUserDrawer = () => setAddUserOpen(!addUserOpen);

  const handleProductChange = (id, newValue, type) => {
    if (products && products.length > 0) {
      let storeIndex;
      if (updateproducts.length > 0) {
        storeIndex = updateproducts.findIndex((product) => product._id === id);
      } else {
        storeIndex = products.findIndex((product) => product._id === id);
      }
      if (storeIndex !== -1) {
        const updatedProducts =
          updateproducts.length > 0 ? [...updateproducts] : [...products];
        if (type === "inventory") {
          updatedProducts[storeIndex] = {
            ...updatedProducts[storeIndex],
            inventory: newValue
          };
        } else {
          updatedProducts[storeIndex] = {
            ...updatedProducts[storeIndex],
            [type === "price" ? "price" : "email_template"]: newValue
          };
        }
        setUpdateProducts(updatedProducts);
      }
    }
  };

  const handleUpdate = (row) => {
     dispatch(updateProduct(row));
  };
  const columns = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row.name}
        </Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 80,
      field: "price",
      headerName: "Price",
      renderCell: ({ row }) => (
        <TextField
          type="number"
          variant="standard"
          value={row.price}
          onChange={(e) =>
            handleProductChange(row._id, e.target.value, "price")
          }
        />
      )
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "email_template",
      headerName: "Email template",
      renderCell: ({ row }) => (
        <FormControl fullWidth>
          <Select
            value={row.email_template}
            onChange={(e) =>
              handleProductChange(row._id, e.target.value, "email_template")
            }
          >
            {templates.map((template) => (
              <MenuItem key={template._id} value={template._id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "inventory",
      headerName: "Inventory",
      renderCell: ({ row }) => (
        <FormControl fullWidth>
          <Select
            value={row.inventory}
            onChange={(e) =>
              handleProductChange(row._id, e.target.value, "inventory")
            }
          >
            {inventorys &&
              inventorys.map((inventory) => (
                <MenuItem key={inventory._id} value={inventory._id}>
                  {inventory.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => (
        <CustomChip
          skin="light"
          size="small"
          label={row.status}
          color={userStatusObj[row.status]}
          sx={{
            textTransform: "capitalize",
            "& .MuiChip-label": { lineHeight: "18px" }
          }}
        />
      )
    },
    {
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <div>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => handleUpdate(row)}
          >
            Update
          </Button>
          <RowOptions _id={row._id} status={row.status} />
        </div>
      )
    }
  ];
  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  
  // Handle pagination model change
  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };

  useEffect(() => {
    dispatch({ type: REMOVE_PRODUCT_DETAILS });
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Product Updated", { variant: "success" });
      dispatch({ type: UPDATE_PRODUCT_RESET });
    }
    dispatch(getVendorProducts())
    dispatch(getInventory())
    dispatch(getTemplates())
    dispatch(getStores())
  }, [error, isUpdated, dispatch,enqueueSnackbar])

  return (
    <>
      <MetaData title="Products | EZDZsender" />
      {loading && <BackdropLoader />}
      <RouterBreadcrumbs />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <TableHeader
              value={value}
              storeName={storeName}
              stores={stores}
              type="products"
              placeholder="Search Product Name"
              handleFilter={handleFilter}
              setOpenModal={setOpenModal}
              handleStoreChange={handleStoreChange}
              handleSyncProduct={handleSyncProduct}
            />
            <DataGrid
              autoHeight
              rows={
                updateproducts.length > 0
                  ? updateproducts.slice(
                      paginationModel.page * paginationModel.pageSize,
                      (paginationModel.page + 1) * paginationModel.pageSize
                    )
                  : products.slice(
                      paginationModel.page * paginationModel.pageSize,
                      (paginationModel.page + 1) * paginationModel.pageSize
                    )
              }
              getRowId={(row) => row._id}
              columns={columns}
              disableRowSelectionOnClick
              pageSizeOptions={[10, 25, 50]}
              pagination={true}
              onPageChange={(page) => handlePageChange(page)}
              pageSize={paginationModel.pageSize}
              rowCount={products.length}
              paginationMode={paginationModel}
              onPaginationModelChange={handlePaginationModelChange}
              sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            />
          </Card>
        </Grid>
        {/* <AddUserDrawer open={addUserOpen} toggle={toggleAddUserDrawer} /> */}
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddStorePopup
          onSave={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </>
  );
};

export default Products;
