/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 05/04/2024 - 11:36:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 05/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { clearErrors, forgotPassword } from "../../actions/userAction";
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';
import {FORGOT_PASSWORD_SUCCESS } from '../../constants/userConstants';
// ** Icon Imports
import Icon from "../../@core/components/icon";

// ** Configs
import themeConfig from "../../configs/themeConfig";

// ** Layout Import
import BlankLayout from "../../@core/layouts/BlankLayout";

// ** Demo Imports
// import FooterIllustrationsV1 from '../../views/pages/auth/FooterIllustrationsV1'

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 480 }
}));

const ForgotPassword = () => {
  // ** Hook
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("email", email);  
    dispatch(forgotPassword(formData));
    setEmail("");
  };
  useEffect(() => {
    console.log("error here",error)
    console.log("message here",message)
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (message) {
      enqueueSnackbar(message, { variant: "success" });
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: '',
      });
    }
  }, [dispatch, error, message, enqueueSnackbar]);

  return (
    <>
      <MetaData title="Forgot Password" />

      {loading && <BackdropLoader />}

      <Box className="content-center">
        <Card sx={{ zIndex: 1 }}>
          <CardContent
            sx={{ p: (theme) => `${theme.spacing(5, 7, 8)} !important` }}
          >
            <Box
              sx={{
                mb: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <svg
                width={47}
                fill="none"
                height={26}
                viewBox="0 0 268 150"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fillOpacity="0.4"
                  fill="url(#paint0_linear_7821_79167)"
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fillOpacity="0.4"
                  fill="url(#paint1_linear_7821_79167)"
                  transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                />
                <rect
                  rx="25.1443"
                  width="50.2886"
                  height="143.953"
                  fill={theme.palette.primary.main}
                  transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
                />
                <defs>
                  <linearGradient
                    y1="0"
                    x1="25.1443"
                    x2="25.1443"
                    y2="143.953"
                    id="paint0_linear_7821_79167"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    y1="0"
                    x1="25.1443"
                    x2="25.1443"
                    y2="143.953"
                    id="paint1_linear_7821_79167"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  lineHeight: 1,
                  fontWeight: 700,
                  fontSize: "1.5rem !important"
                }}
              >
                {themeConfig.templateName}
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="h5"
                sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
              >
                Forgot Password? 🔒
              </Typography>
              <Typography variant="body2">
                Enter your email and we&prime;ll send you instructions to reset
                your password
              </Typography>
            </Box>
            <form  onSubmit={handleSubmit}>
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email} 
                autoFocus
                type="email"
                label="Email"
                // error={false}
                sx={{ display: "flex", mb: 4 }}
                required
              />
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mb: 5.25 }}
              >
                Send reset link
              </Button>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  component={Link}
                  to="/"
                  sx={{
                    display: "flex",
                    "& svg": { mr: 1.5 },
                    alignItems: "center",
                    color: "primary.main",
                    textDecoration: "none",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon="mdi:chevron-left" fontSize="2rem" />
                  <span>Back to login</span>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
        {/* <FooterIllustrationsV1 image={`/images/pages/auth-v1-forgot-password-mask-${theme.palette.mode}.png`} /> */}
      </Box>
    </>
  );
};
ForgotPassword.getLayout = (page) => <BlankLayout>{page}</BlankLayout>;

export default ForgotPassword;
