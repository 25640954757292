/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 11/04/2024 - 11:42:05
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 11/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
// ** Custom Components Imports
import ReactApexcharts from "react-apexcharts";
// ** Util Import
import { hexToRGBA } from "../../../../@core/utils/hex-to-rgba";

const series = [
  {
    name: "Earning",
    data: [0, 0, 0]
  },
  {
    name: "Expense",
    data: [0, 0, 0]
  }
];

const AnalyticsTotalRevenue = (props) => {
  const [SeriesData, setSeriesData] = useState(series);
  const { title, storeName, all_daysData } = props;
  const successDailySet = all_daysData?.Earning?.[0] ?? 0;
  const ExpenseDailySet = all_daysData?.Expense?.[0] ?? 0;
  const successMonthSet = all_daysData?.Earning?.[1] ?? 0;
  const ExpenseMonthSet = all_daysData?.Expense?.[1] ?? 0;
  const successYearSet = all_daysData?.Earning?.[2] ?? 0;
  const ExpenseYearSet = all_daysData?.Expense?.[2] ?? 0;

  useEffect(() => {
    const updatedSeries = [
      {
        name: "Earning",
        data: [successDailySet, successMonthSet, successYearSet]
      },
      {
        name: "Expense",
        data: [ExpenseDailySet, ExpenseMonthSet, ExpenseYearSet]
      }
    ];
    setSeriesData(updatedSeries);
  }, [
    successDailySet,
    successMonthSet,
    successYearSet,
    ExpenseDailySet,
    ExpenseMonthSet,
    ExpenseYearSet,
    title
  ]);

  const theme = useTheme();

  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    grid: {
      padding: {
        top: -15,
        left: -14,
        right: -4,
        bottom: -10
      },
      yaxis: {
        lines: { show: false }
      }
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    // colors: [hexToRGBA(theme.palette.primary.main, 1), hexToRGBA(theme.palette.warning.main, 1)],
    colors: ["#666cff", hexToRGBA(theme.palette.warning.main, 1)],
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "48%",
        startingShape: "rounded"
      }
    },
    states: {
      hover: {
        filter: { type: "none" }
      },
      active: {
        filter: { type: "none" }
      }
    },
    xaxis: {
      labels: { show: true },
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: ["Day", "Month", "Year"]
    },
    yaxis: {
      labels: { show: false }
    }
  };
  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px"
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1.5, color: "#636578" }}>
            {storeName}
          </Typography>
        </Box>
        <Typography variant="body2">{title} </Typography>
        <ReactApexcharts
          type="bar"
          height={165}
          options={options}
          series={SeriesData}
        />
      </CardContent>
    </Card>
  );
};

export default AnalyticsTotalRevenue;
