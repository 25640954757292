/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 23/04/2024 - 11:57:48
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 23/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import TableHeader from "../../Admin/Woocommerce/TableHeader";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Icon from "../../../@core/components/icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BackdropLoader from "../../Layouts/BackdropLoader";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Menu from "@mui/material/Menu";
import { format } from "date-fns";
import {
  TextField,
  Button,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import RouterBreadcrumbs from "../Breadcrumbs";
import FileUploaderMultiple from "../forms/form-elements/file-uploader/FileUploaderMultiple";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  getInventoryDetails,
  getDeleteInventoryItems,
  getUpdateInventoryItems,
  getBulkActionInventoryItems,
  getuploadInventory
} from "../../../actions/inventoryAction";

const UpdateInventory = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const inventoryId = params.id;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { loading, isUpdated, isDeleted, inventory, error } = useSelector(
    (state) => state.inventoryDetails
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [name, setName] = useState("");
  const [usage, setUsage] = useState();
  const [sku, setSku] = useState("");
  const [type, setType] = useState("");
  const [IntertoryItemTypeName, setIntertoryItemTypeName] =
    useState("available");
  const [expirationDate, setExpirationDate] = useState("");
  const [textValue, setTextValue] = useState([]);
  const [value, setValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchInventoryItem, setSearchInventoryItem] = useState([]);
  const [searchstatus, setSearchStatus] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [checkType,setcheckType] = useState(false);   
  const [checkerror, setError] = useState();
  const handleChange = (event) => {
    if (event.target.value.length > 0) {
      setError("");
   }
    setTextValue([event.target.value]);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleFiles = useCallback((val) => {
    if (val.length > 0) {
      setError("");
    }
    setTextValue(val);
  }, []);

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setTextValue([]);
  };
  const handleFilter = useCallback(
    (val) => {
      setSearchStatus(false);
      setIntertoryItemTypeName("");
      setValue(val);
      const queryLowered = val.toLowerCase();
      const filteredData =
        inventory && inventory.inventory_item
          ? inventory.inventory_item.filter(
              (inventoryItem) =>
                inventoryItem.value.toLowerCase().includes(queryLowered) &&
                inventoryItem.status === "available"
            )
          : [];
      setSearchInventoryItem(filteredData);
    },
    [inventory]
  );

  const handleIntentoryTypeChange = useCallback(
    (e) => {
      setSearchStatus(false);
      let IntTypeValue = e.target.value;
      setIntertoryItemTypeName(IntTypeValue);
      const filteredData =
        inventory && inventory.inventory_item
          ? inventory.inventory_item.filter(
              (inventoryItem) => inventoryItem.status === IntTypeValue
            )
          : [];
      if (filteredData.length === 0) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setSearchInventoryItem(filteredData.length > 0 ? filteredData : []);
    },
    [inventory]
  );

  const handleMaskDelete = (status) => {
    dispatch(getBulkActionInventoryItems(inventoryId, selectedRows, status));
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleRowOptionsClick = (event, row) => {
    setRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleSendFirst = (row, send_first) => {
    setRowData(row);
    dispatch(getUpdateInventoryItems(row?._id, inventoryId, send_first));
  };
  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
    handleRowOptionsClose();
  };
  const handleStatus = (status) => {
    dispatch(getUpdateInventoryItems(rowData?._id, inventoryId, status));
    handleRowOptionsClose();
  };
  const handleDeleteConfirm = () => {
    setDeleteConfirmationOpen(false);
    dispatch(getDeleteInventoryItems(rowData?._id, inventoryId));
    handleRowOptionsClose();
  };
  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const textString = textValue.join("\n");
    const lines = textString.split("\n");
    const ItemsArray = lines.filter((line) => line.trim().length > 0);
    if (textString.length === 0) {
      setError("Field is required");
      return;
    } else {
      setError("");
    }
    const uploadData = {
      id: inventoryId,
      name: name,
      usage:  usage,
      sku: sku,
      file_type: type,
      expiration_date: expirationDate,
      inventory_item: type === "Files" ? textValue : ItemsArray,
      inventoryItemOld: inventory?.inventory_item
    };
    if (textValue.length > 0) {
      dispatch(getuploadInventory(inventoryId, uploadData));
      enqueueSnackbar("Inventory Success", { variant: "success" });
    }
  };
  const columns = [
    {
      flex: 0.2,
      minWidth: 300,
      field: "store_url",
      headerName: "Value",
      renderCell: ({ row }) => {
        return (
          <Typography noWrap variant="body2">
            {row?.value}
          </Typography>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "name",
      headerName: "Status",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row?.status}
        </Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 170,
      field: "email",
      headerName: "Upload time",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row && row.upload_time
            ? format(new Date(row.upload_time), "yyyy-MM-dd HH:mm:ss")
            : ""}
        </Typography>
      )
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: "expiration_date",
      headerName: "Expiration Date",
      renderCell: ({ row }) => (
        <Typography noWrap variant="body2">
          {row && row.expiration_date
            ? format(new Date(row.expiration_date), "yyyy-MM-dd")
            : "No Expiration Date"}
        </Typography>
      )
    },
    ...(
      usage
        ? [
            {
              flex: 0.2,
              minWidth: 100,
              field: "usage",
              headerName: "Usage",
              renderCell: ({ row }) => (
                <Typography noWrap variant="body2">
                  {row?.usage}
                </Typography>
              )
            }
          ]
        : [
            {
              flex: 0.2,
              minWidth: 100,
              field: "send_first",
              headerName: "Send First",
              renderCell: ({ row }) => (
                <Typography noWrap variant="body2">
                  <IconButton
                    color="primary"
                    onClick={() => handleSendFirst(row, !row?.send_first)}
                  >
                    {row?.send_first ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                </Typography>
              )
            }
          ]
    ),
    {
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <div>
          <IconButton
            size="small"
            onClick={(event) => handleRowOptionsClick(event, row)}
          >
            <Icon icon="mdi:dots-vertical" />
          </IconButton>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (inventory && inventory._id !== inventoryId) {
      dispatch(getInventoryDetails(inventoryId));
    } else {
      setName(inventory?.name);
      setUsage(inventory?.usage);
      setSku(inventory?.sku);
      setcheckType(inventory?.type?.toLowerCase()?.includes('recursive'))
      setType("Text");
      setExpirationDate("");
      setTextValue([]);
      setSearchInventoryItem([]);
      setValue("");
    }
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Inventory Success", { variant: "success" });
      dispatch(getInventoryDetails(inventoryId));
    }
    if (isDeleted) {
      enqueueSnackbar("Inventory Success", { variant: "success" });
      dispatch(getInventoryDetails(inventoryId));
    }
  }, [dispatch,enqueueSnackbar,error, isUpdated, isDeleted, inventoryId, inventory]);
  return (
    <>
      <RouterBreadcrumbs />
      <Card elevation={3}>
        <CardContent>
          <Box p={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                { checkType && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Usage"
                      variant="outlined"
                      type="number"
                     value={usage}
                     onChange={(e) => setUsage(Number(e.target.value))}
                    />
                  </Grid>
            )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="SKU"
                    variant="outlined"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={type}
                      onChange={handleTypeChange}
                      label="Type"
                    >
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Files">Files</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Expiration Date"
                    variant="outlined"
                    type="date"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                {type === "Text" && (
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label=""
                      multiline
                      rows={4}
                      value={textValue}
                      onChange={handleChange}
                      variant="outlined"
                      error={!!checkerror}
                      helperText={checkerror}
                    />
                  </Grid>
                )}
                {type === "Files" && (
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <FileUploaderMultiple handleFiles={handleFiles} />
                      {checkerror && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ mt: 1 }}
                        >
                          {checkerror}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="primary">
                      Upload Inventory
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CardContent>

        <Grid container spacing={6}>
          {loading && <BackdropLoader />}
          <Grid item xs={12}>
            <Card>
              <TableHeader
                value={value}
                IntertoryItemTypeName={IntertoryItemTypeName}
                type="inventory"
                placeholder="Search .."
                handleIntentoryTypeChange={handleIntentoryTypeChange}
                handleMaskDelete={handleMaskDelete}
                handleFilter={handleFilter}
              />
              <DataGrid
                autoHeight
                rows={
                  searchstatus === true
                    ? []
                    : searchInventoryItem.length > 0
                    ? searchInventoryItem
                    : inventory && inventory?.inventory_item?.length > 0
                    ? inventory.inventory_item
                        .filter(
                          (inventoryItem) =>
                            inventoryItem.status === "available"
                        )
                        .map((inventoryItem) => ({
                          ...inventoryItem,
                          id: inventoryItem._id
                        }))
                    : []
                }
                checkboxSelection
                onSelectionModelChange={handleSelectionChange}
                getRowId={(row) => row._id}
                columns={columns}
                disableRowSelectionOnClick
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
              />

              <Menu
                keepMounted
                anchorEl={anchorEl}
                open={rowOptionsOpen}
                onClose={handleRowOptionsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                PaperProps={{ style: { minWidth: "8rem" } }}
              >
                <MenuItem
                  onClick={() => handleStatus("Faulty")}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  Mark as faulty
                </MenuItem>
                {/* <MenuItem
                  onClick={() => handleStatus("SendFirst")}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  Mark as send first
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleStatus("Expired")}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  Mark as expired
                </MenuItem>
                <MenuItem
                  onClick={() => handleStatus("Used")}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  Mark as used
                </MenuItem>
                <MenuItem
                  onClick={handleDeleteClick}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  <Icon icon="mdi:delete-outline" fontSize={20} />
                  Delete
                </MenuItem>
              </Menu>
              <Dialog
                open={deleteConfirmationOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Delete Store?"}
                </DialogTitle>
                <DialogContent>
                  <p className="text-gray-500">
                    Do you really want to delete? This process cannot be undone.
                  </p>
                </DialogContent>
                <DialogActions>
                  <button
                    onClick={handleDeleteCancel}
                    className="py-2 px-6 rounded shadow bg-gray-400 hover:bg-gray-500 text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleDeleteConfirm()}
                    className="py-2 px-6 ml-4 rounded bg-red-600 hover:bg-red-700 text-white shadow"
                  >
                    Delete
                  </button>
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default UpdateInventory;
