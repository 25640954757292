/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 06/06/2024 - 15:25:49
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 06/06/2024
 * - Author          : admin
 * - Modification    :
 **/

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Box,
  ButtonGroup,
  Select
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { styled } from "@mui/system";
import { clearErrors, addTemplates } from "../../../actions/templatesAction";
import { useDispatch, useSelector } from "react-redux";
import { NEW_TEMPLATES_RESET } from "../../../constants/templatesConstants";
import { getSmtpData } from "../../../actions/smtpAction";
const AddTemplatesPopup = ({ templateData, onSave, onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const state = useSelector((state) => state);
  const { allSmtpData } = state?.smtpAllData ? state?.smtpAllData : "";
  const { smtpData } = allSmtpData ? allSmtpData : "";
  const { user } = state.user;
  const user_id = user?._id;
  const [open, setOpen] = useState(true);
  const { addtemplates, error, templates } = useSelector(
    (state) => state.templates
  );
  const [errors, setErrors] = useState({
    name: ""
  });

  const [formData, setformData] = useState({
    name: "",
    type: "Html",
    smtp: "Default",
    platform_id: "629743b4c798c617e89d826e",
    user_id: user_id
  });

  const handleForm = (name, value) => {
    setformData({ ...formData, [name]: value });
    if (value.trim() === "") {
      setErrors({ ...errors, [name]: "This field is required" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleSave = () => {
    let valid = true;
    if (formData.name.trim() === "") {
      setErrors({ ...errors, name: "Name is required" });
      valid = false;
    }
    if (valid) {
      dispatch(addTemplates(formData));
    }
  };
  useEffect(() => {
    dispatch(getSmtpData(user_id));
  }, [dispatch, user_id]);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (addtemplates) {
      navigate(`/update/template/` + templates?._id);
      setformData("");
      dispatch({ type: NEW_TEMPLATES_RESET });
    }
  }, [
    dispatch,
    error,
    addtemplates,
    navigate,
    enqueueSnackbar,
    templates?._id
  ]);

  const handleClose = () => {
    onCancel();
    setOpen(false);
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0
    }
  }));
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create template</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <label htmlFor="empty-text-field">
              <span style={{ color: "gray" }}>Name:</span>
            </label>
            <TextField
              fullWidth
              label=""
              variant="outlined"
              value={formData.name}
              onChange={(e) => handleForm("name", e.target.value)}
              error={Boolean(errors.name)}
              helperText={errors.name}
              InputProps={{
                sx: { borderRadius: 3 }
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="empty-text-field">
              <span style={{ color: "gray" }}>Type:</span>
            </label>
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="send-type-label"
                value={formData.type}
                onChange={(e) => handleForm("type", e.target.value)}
                label=""
                sx={{ borderRadius: 3 }}
                required
              >
                <MenuItem value="Html">Html</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <label htmlFor="empty-text-field">
              <span style={{ color: "gray" }}>Smtp:</span>
            </label>
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="send-type-label"
                value={formData.smtp}
                onChange={(e) => handleForm("smtp", e.target.value)}
                label=""
                sx={{ borderRadius: 3 }}
                required
              >
                {smtpData && smtpData?.length > 0 ? (
                  smtpData.map((item) => (
                    <MenuItem key={item._id} value={item.smtpname}>
                      {item.smtpname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="no-smtp">No SMTP Available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", mt: 2 }}>
              <ButtonGroup>
                <StyledButton
                  onClick={handleSave}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </StyledButton>
                <StyledButton
                  onClick={handleClose}
                  type="reset"
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </StyledButton>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddTemplatesPopup;
