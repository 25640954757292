/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 04/06/2024 - 13:00:29
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/06/2024
    * - Author          : admin
    * - Modification    : 
**/
export const AddIP_API_REQUEST ="AddIP_API_REQUEST";
export const AddIP_API_SUCCESS ="AddIP_API_SUCCESS";
export const AddIP_API_FAIL ="AddIP_API_FAIL";

export const GetIPData_API_REQUEST ="GetIPData_API_REQUEST";
export const GetIPData_API_SUCCESS = "GetIPData_API_SUCCESS";
export const GetIPData_API_FAIL ="GetIPData_API_FAIL";


export const UPDATE_API_REQUEST ="UPDATE_API_REQUEST";
export const UPDATE_API_SUCCESS = "UPDATE_API_SUCCESS";
export const UPDATE_API_FAIL ="UPDATE_API_FAIL";


export const DELETE_API_REQUEST ="DELETE_API_REQUEST";
export const DELETE_API_SUCCESS = "DELETE_API_SUCCESS";
export const DELETE_API_FAIL ="DELETE_API_FAIL";
