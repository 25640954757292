/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 04/06/2024 - 18:05:27
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 04/06/2024
    * - Author          : admin
    * - Modification    : 
**/
/**
 * @description      : Form component to update IP data in the application.
 * @created          : 24/04/2024 - 13:42:54
 * @updated          : 04/06/2024 - 14:32:54
 * - Author          : admin
 * - Modification    : Refined code structure and improved readability.
 **/

import {
  TextField,
  Grid,
  CardHeader,
  MenuItem,
  FormControl,
  Select,
  Button,
  CardContent,
  InputLabel,
  Box,
  Paper
} from "@mui/material";
import { getAddApiData, updateAddIpData } from "../../actions/addIpAction";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom';
const UpdateAppIPForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { addipData } = useSelector((state) => state.getAddIpData);
  const params = useParams();
  const ApiId = params.id;
  const dummyData = addipData?.data;

  const [data, setData] = useState({
    ip: "",
    type: "WhiteList",
    status: "Active",
    expires: ""
  });

  const result = dummyData?.find(item => item._id === ApiId);

  const handleFormChange = (name, value) => {
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    dispatch(getAddApiData());
  }, [dispatch]);

  useEffect(() => {
    if (result) {
      setData(result);
    }
  }, [result]);

  const updateData = (event) => {
    event.preventDefault();
    dispatch(updateAddIpData(data, ApiId));
    enqueueSnackbar("Update Successfully", { variant: "success" });
    navigate('/whiteblackAddIp');
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <CardHeader title="Update Add IP" />
            <form onSubmit={updateData} encType="multipart/form-data">
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="IP"
                      placeholder="IP"
                      value={data.ip}
                      onChange={(e) => handleFormChange("ip", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        label="Type"
                        value={data.type}
                        onChange={(e) => handleFormChange("type", e.target.value)}
                      >
                        <MenuItem value="WhiteList">WhiteList</MenuItem>
                        <MenuItem value="BlackList">BlackList</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        label="Status"
                        value={data.status}
                        onChange={(e) => handleFormChange("status", e.target.value)}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Expires"
                      placeholder="Expiration"
                      value={data.expires}
                      onChange={(e) => handleFormChange("expires", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                      Submit
                    </Button>
                    <Button type="reset" onClick={() => setData(result)} variant="outlined" color="secondary">
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default UpdateAppIPForm;
