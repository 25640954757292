/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 14:23:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const ADD_CUSTOMERS_REQUEST = "ADD_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const UPDATE_CUSTOMERS_REQUEST = "UPDATE_CUSTOMERS_REQUEST";
export const UPDATE_CUSTOMERS_SUCCESS = "UPDATE_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const ADD_CUSTOMERS_SUCCESS = "ADD_CUSTOMERS_SUCCESS";
export const DELETE_CUSTOMERS_SUCCESS = "DELETE_CUSTOMERS_SUCCESS";
export const ADD_CUSTOMERS_FAIL = "ADD_CUSTOMERS_FAIL";
export const UPDATE_CUSTOMERS_FAIL = "UPDATE_CUSTOMERS_FAIL";
export const DELETE_CUSTOMERS_FAIL = "DELETE_CUSTOMERS_FAIL";
export const FETCH_CUSTOMERS_FAIL = "FETCH_CUSTOMERS_FAIL";
export const NEW_CUSTOMERS_RESET = "NEW_CUSTOMERS_RESET";
export const FETCH_CUSTOMERS_RESET = "FETCH_CUSTOMERS_RESET";


export const CLEAR_ERRORS = "CLEAR_ERRORS";