/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 05/06/2024 - 10:31:42
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 05/06/2024
    * - Author          : admin
    * - Modification    : 
**/
export const DOMAINIP_API_REQUEST ="DOMAINIP_API_REQUEST";
export const DOMAINIP_API_SUCCESS ="DOMAINIP_API_SUCCESS";
export const DOMAINIP_API_FAIL ="DOMAINIP_API_FAIL";

export const GETDOMAINIPData_API_REQUEST ="GETDOMAINIPData_API_REQUEST";
export const GETDOMAINIPData_API_SUCCESS ="GETDOMAINIPData_API_SUCCESS";
export const GETDOMAINIPData_API_FAIL ="GETDOMAINIPData_API_FAIL";

export const UPDATE_DOMAIN_API_REQUEST ="UPDATE_DOMAIN_API_REQUEST";
export const UPDATE_DOMAIN_API_SUCCESS ="UPDATE_DOMAIN_API_SUCCESS";
export const UPDATE_DOMAIN_API_FAIL ="UPDATE_DOMAIN_API_FAIL";

export const DELETE_DOMAIN_API_REQUEST ="DELETE_DOMAIN_API_REQUEST";
export const DELETE_DOMAIN_API_SUCCESS ="DELETE_DOMAIN_API_SUCCESS";
export const DELETE_DOMAIN_API_FAIL ="DELETE_DOMAIN_API_FAIL";

