/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 16/04/2024 - 16:26:03
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 16/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isAdmin }) => {

    const { loading, isAuthenticated, user } = useSelector(state => state.user);

    return (
        <>
            {loading === false && (
                isAuthenticated === false ? <Navigate to="/" /> : isAdmin ? user.role !== "vendor" ? <Navigate to="/" /> : children : children
            )}
        </>
    );
};

export default ProtectedRoute;
