/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 01/08/2024 - 18:21:40
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 01/08/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Icon from "../../@core/components/icon";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import CustomChip from "../../../src/@core/components/mui/chip";
import {
  AddipApi,
  getAddApiData,
  deleteAddAPiData
} from "../../actions/addIpAction";
import { getStores } from "../../actions/storeAction";
import {
  Button,
  Box,
  MenuItem,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  CardContent
} from "@mui/material";
import Actions from "./Actions";

const userStatusObj = {
  Active: "success",
  Inactive: "error"
};

const Addip = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { addipData } = useSelector((state) => state.getAddIpData);
  const dummyData = addipData?.data || [];
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [formData, setFormData] = useState({
    ip: "",
    type: "WhiteList",
    expires: "",
    status: "Active"
  });
  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  useEffect(() => {
    dispatch(getAddApiData());
    dispatch(getStores());
  }, [dispatch]);

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    setPaginationModel((prevModel) => ({ ...prevModel, pageSize: newPageSize }));
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClose = () => {
    setOpenModal(false);
    setFormData({
      ip: "",
      type: "WhiteList",
      expires: "",
      status: "Active"
    });
  };

  const handleFormChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const submitData = async (pre) => {
    pre.preventDefault();
    await  dispatch(AddipApi(formData));  
    dispatch(getAddApiData());
    enqueueSnackbar("Inserted data successfully", { variant: "success" });
    setFormData({
      ip: "",
      type: "WhiteList",
      expires: "",
      status: "Active"
    });
    setOpenModal(false);
  };

  const DialogBoxhandle = () => {
    setOpenModal(true);
  };

  const deleteAddIpHandler = async (id) => {
    await dispatch(deleteAddAPiData(id));
    enqueueSnackbar("Delete  Successfully", { variant: "error" });
    dispatch(getAddApiData());
  };

  const columns = [
    {
      field: "ip",
      headerName: "IP",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.ip}</div>
      )
    },
    {
      field: "type",
      headerName: "TYPE",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.type}</div>
      )
    },
    {
      field: "expires",
      headerName: "EXPIRES IN",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.expires}</div>
      )
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomChip
          skin="light"
          size="small"
          label={params.row.status}
          color={userStatusObj[params.row.status]}
          sx={{
            textTransform: "capitalize",
            "& .MuiChip-label": { lineHeight: "18px" }
          }}
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      flex: 0.3,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"update/addip"}
            deleteHandler={deleteAddIpHandler}
            id={params.row.id}
          />
        );
      }
    }
  ];

  const filteredRows = dummyData.filter(
    (row) =>
      row.ip.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.type.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.status.toLowerCase().includes(searchValue.toLowerCase())
  );
 // const handlePaginationModelChange = (newModel) => {
  //  setPaginationModel(newModel);
  //};

  const handleExportAction = () => {
    let csvContent = "";
    csvContent += "PLATFORM,IP,TYPE,STATUS,EXPIRES\n";
    var ExportData = filteredRows?.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );

    ExportData?.forEach((transaction) => {
      var Ip = "";
      var Type = "";
      var Status = "";
      var Expires = "";
      Ip = transaction.ip ?? "";
      Type = transaction?.type ?? "";
      Status = transaction?.status ?? "";
      Expires = transaction?.expires ?? "";
      csvContent += `${"Woocommerce"},${
        Ip
      },${Type},${Status},${Expires}\n`;
    });
    // Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create URL for Blob
    const url = window?.URL.createObjectURL(blob);
    // Create anchor element
    const a = document?.createElement("a");
    a.href = url;
    a.download = "White&Blacklist.csv";
    // Simulate click on anchor element
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box bgcolor="#f5f5f5" borderRadius="xl" boxShadow={3} p={2} mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" style={{ color: "grey" }}>
                  White & Black List
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleExportAction}
                    startIcon={<Icon icon="mdi:export-variant" fontSize={20} />}
                    style={{ marginRight: 10 }}
                  >
                    EXPORT
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={DialogBoxhandle}
                    component={Link}
                  >
                    + ADD NEW RECORD
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControl size="small">
                <label htmlFor="empty-text-field">
                  <Typography variant="h5" style={{ color: "grey" }}>
                    <span style={{ fontSize: "15px" }}>Entities per page</span>
                  </Typography>
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  style={{ marginRight: "8px", color: "grey" }}
                >
                  Search:
                </Typography>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <DataGrid
                autoHeight
                rows={filteredRows.map((store) => ({
                  ...store,
                  id: store._id
                }))}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                paginationMode="client"
                pagination
                onPageChange={(page) => handlePageChange(page)}
                onPageSizeChange={(size) => setPageSize(size)}
                pageSize={paginationModel.pageSize}
                rowsPerPageOptions={[10, 25, 50]}
                rowCount={filteredRows.length}
                style={{ backgroundColor: "#f5f5f5" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog box */}

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ADD IP"}</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <form onSubmit={submitData} encType="multipart/form-data">
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="IP Address"
                          placeholder="IP Address"
                          value={formData.ip}
                          onChange={(e) =>
                            handleFormChange("ip", e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Select
                            label="Type"
                            value={formData.type}
                            placeholder="Type"
                            onChange={(e) =>
                              handleFormChange("type", e.target.value)
                            }
                            required
                          >
                            <MenuItem value="WhiteList">WhiteList</MenuItem>
                            <MenuItem value="BlackList">BlackList</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Select
                            label="Status"
                            value={formData.status}
                            onChange={(e) =>
                              handleFormChange("status", e.target.value)
                            }
                            required
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Expiration"
                          placeholder="Expiration"
                          value={formData.expires}
                          onChange={(e) =>
                            handleFormChange("expires", e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button type="submit" variant="text" sx={{ mr: 3 }}>
                          Submit
                        </Button>
                        <Button
                          type="reset"
                          variant="text"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Addip;
