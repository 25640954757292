/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 21/05/2024 - 17:29:05
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/05/2024
    * - Author          : admin
    * - Modification    : 
**/
import {
    SMTP_API_REQUEST,
    SMTP_API_SUCCESS,
    SMTP_API_FAIL,
    Alert_API_REQUEST,
    Alert_API_SUCCESS,
    Alert_API_FAIL,
    SMTP_ALLDATA_REQUEST,
    SMTP_ALLDATA_SUCCESS,
    SMTP_DATA_FAIL,
    SMTP_UPDATE_REQUEST,
    SMTP_UPDATE_SUCCESS,
    SMTP_UPDATE_FAIL ,
    SMTP_DELTE_REQUEST,
    DELETE_SMTP_DELTE_SUCCESS,
    DELETE_SMTP_DELTE_FAIL
} from '../constants/smtpConstants';
export const smtpDetailReducer = (state = { smtp: {} }, { type, payload }) => {
    switch (type) {
        case SMTP_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SMTP_API_SUCCESS:
            return {
                ...state,
                loading: false,
                smtp: payload,
            };
        case SMTP_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const alertReducer = (state = { smtp: {} }, { type, payload }) =>{
    switch (type) {
        case Alert_API_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case Alert_API_SUCCESS:
            return {
                ...state,
                loading: false,
                alert: payload,
            };

            
        case Alert_API_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}


export const smtpAllData = (state = { smtp: {} }, { type, payload }) =>{
    switch (type) {
        case SMTP_ALLDATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SMTP_ALLDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                allSmtpData: payload,
            };

            
        case SMTP_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}


export const smtpUpdateData = (state = { smtp: {} }, { type, payload }) =>{
    switch (type) {
        case SMTP_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SMTP_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                smtpUpdateData: payload,
            };

            
        case SMTP_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}

export const smtpDeleteData = (state = { smtp: {} }, { type, payload }) =>{
    switch (type) {
        case SMTP_DELTE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SMTP_DELTE_SUCCESS:
            return {
                ...state,
                loading: false,
                smtpUpdateData: payload,
            };

            
        case DELETE_SMTP_DELTE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};