/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 18:00:43
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_REQUEST,
  UPDATE_CUSTOMERS_REQUEST,
  DELETE_CUSTOMERS_SUCCESS,
  ADD_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_SUCCESS,
  ADD_CUSTOMERS_FAIL,
  DELETE_CUSTOMERS_FAIL,
  UPDATE_CUSTOMERS_FAIL,
  CLEAR_ERRORS
} from "../constants/customerConstants";
import axios from "axios";

//Add Customer
export const addCustomer =
  (
    user_id,
    platform_id,
    inventory_id,
    email,
    product_id,
    item_name,
    quantity,
    unit_price,
    template_id
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_CUSTOMERS_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post(
        "/api/v1/customers/add",
        {
          user_id,
          platform_id,
          inventory_id,
          email,
          product_id,
          item_name,
          quantity,
          unit_price,
          template_id
        },
        config
      );
      dispatch({
        type: ADD_CUSTOMERS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ADD_CUSTOMERS_FAIL,
        payload: error.response.data.message
      });
    }
  };

// List Customer
export const getCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_CUSTOMERS_REQUEST });

    const { data } = await axios.get("/api/v1/customers/all");
    dispatch({
      type: ADD_CUSTOMERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ADD_CUSTOMERS_FAIL,
      payload: error.response.data.message
    });
  }
};

// Get WoocommerceCustomer
export const getWoocommerceCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CUSTOMERS_REQUEST });
    const { data } = await axios.get("/api/v1/fetch/customers/all");
    dispatch({
      type: FETCH_CUSTOMERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_CUSTOMERS_SUCCESS,
      payload: error.response.data.message
    });
  }
};

// Delete Customer
export const deleteCustomers = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CUSTOMERS_REQUEST });
    const { data } = await axios.delete(`/api/v1/customers/${id}`);

    dispatch({
      type: DELETE_CUSTOMERS_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMERS_FAIL,
      payload: error.response.data.message
    });
  }
};

//Update Customer
export const updateCustomers = (rowData, type) => async (dispatch) => {
     console.log("user_id",rowData.id)
     console.log("raw_data",rowData)
  try {
    dispatch({ type: UPDATE_CUSTOMERS_REQUEST });
    const config = { header: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/v1/customers/${rowData?.customer_id}`,
      { data: rowData, type: type },
      config
    );
    dispatch({
      type: UPDATE_CUSTOMERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMERS_FAIL,
      payload: error.response.data.message
    });
  }
};

// Clear All Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
