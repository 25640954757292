/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 06/04/2024 - 12:07:15
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 06/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import { useEffect, useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../Layouts/Header/Header';
import MetaData from '../Layouts/MetaData';
const Dashboard = ({ activeTab, children }) => {

    const [onMobile, setOnMobile] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(true);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setOnMobile(true);
        }
    }, [])

    return (
        <>
        <MetaData title="Dashboard | EZDZsender" />
            <main className="flex min-h-screen p-5  sm:min-w-full">
            <Header setToggleSidebar={setToggleSidebar} />
                {!onMobile && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />}
                {toggleSidebar && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar}/>}
                
                <div className="mt-15 w-full sm:w-9/10 sm:ml-72 min-h-screen">
                    <div className="flex flex-col gap-6 sm:m-8 p-2 pb-6 overflow-hidden">
                        <button onClick={() => setToggleSidebar(true)} className="sm:hidden bg-gray-700 w-10 h-10 rounded-full shadow text-white flex items-center justify-center"><MenuIcon /></button>
                        {children}
                    </div>
                </div>
            </main>
        </>
    );
};

export default Dashboard;
