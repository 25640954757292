/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 24/04/2024 - 13:42:54
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 24/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  TextField,
  Grid,
  CardHeader,
  MenuItem,
  FormControl,
  Select,
  Button,
  InputLabel,
  CardContent,
  Paper,
  Box
} from "@mui/material";
import { useState, useEffect } from "react";
import {getDomainApiData,updateDomainIpData } from "../../actions/domainIpAction";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom';
const EmailDomainForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    domain: "",
    type: "Whitelist",
    status: "Active",
  });
  const allData = useSelector((state) => state);
  const {DomainIpAll} = allData?.getDomainIpData;
  const dummyData = DomainIpAll?.data || [];
  const params = useParams();
  const DomainIpId = params.id;
  const handleFormChange = (name, value) => {
    setFormData((preState) => ({ ...preState, [name]: value }));
  };
  const result = dummyData?.find(item => item._id === DomainIpId);
  useEffect(() => {
    dispatch(getDomainApiData());
  }, [dispatch]);
 
  useEffect(() => {
    if (result) {
      setFormData(result);
    }
  }, [result]);
  
  const updateData = (event) => {
    event.preventDefault();
    dispatch(updateDomainIpData(formData, DomainIpId));
    enqueueSnackbar("Update Successfully", { variant: "success" });
    navigate('/whiteblackEmaildomain');
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Grid container spacing={6}>
            {/* Account Details Card */}
            <Grid item xs={12}>
              <CardHeader title="Update Email/Domain" />
              <form onSubmit={updateData}>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email / Domain"
                        placeholder="Email / Domain"
                        value={formData.domain}
                        onChange={(e) => {
                          handleFormChange("domain", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          label="type"
                          value={formData.type}
                          placeholder="WhiteList"
                          onChange={(e) =>
                            handleFormChange("type", e.target.value)
                          }
                        >
                          <MenuItem value="Whitelist">WhiteList</MenuItem>
                          <MenuItem value="BlackList">BlackList</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="status"
                          value={formData.status}
                          placeholder="status"
                          onChange={(e) =>
                            handleFormChange("status", e.target.value)
                          }
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                    <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                      Submit
                    </Button>
                    <Button type="reset" onClick={() => setFormData(result)} variant="outlined" color="secondary">
                      Cancel
                    </Button>
                  </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default EmailDomainForm;
