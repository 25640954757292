/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 06/04/2024 - 15:12:04
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 06/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import themeConfig from "../../configs/themeConfig";

const FormSidebar = ({ title, tag }) => {
  const theme = useTheme();
  return (
    <div className="bg-primary-color px-9 py-10 hidden sm:flex flex-col gap-4 w-2/5">
      <h1 className="font-medium text-white-color text-3xl">{title}</h1>
      <p className="text-gray-200 text-lg pr-2">{tag}</p>
      <div className="register-image">
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <svg
          width={47}
          fill="none"
          height={26}
          viewBox="0 0 268 150"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fill={theme.palette.primary.main}
            transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
          />
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fillOpacity="0.4"
            fill="url(#paint0_linear_7821_79167)"
            transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
          />
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fill={theme.palette.primary.main}
            transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
          />
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fill={theme.palette.primary.main}
            transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
          />
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fillOpacity="0.4"
            fill="url(#paint1_linear_7821_79167)"
            transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
          />
          <rect
            rx="25.1443"
            width="50.2886"
            height="143.953"
            fill={theme.palette.primary.main}
            transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
          />
          <defs>
            <linearGradient
              y1="0"
              x1="25.1443"
              x2="25.1443"
              y2="143.953"
              id="paint0_linear_7821_79167"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              y1="0"
              x1="25.1443"
              x2="25.1443"
              y2="143.953"
              id="paint1_linear_7821_79167"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <Typography
          variant="h6"
          sx={{
            ml: 2,
            lineHeight: 1,
            color: "#fff",
            fontWeight: 700,
            fontSize: "1.5rem !important"
          }}
        >
          {themeConfig.templateName}
        </Typography>
      </Box>
    </div>
      
    </div>
  );
};

export default FormSidebar;
