/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 22/04/2024 - 18:08:24
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 22/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useState,useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_INVENTORY_RESET } from '../../../constants/inventoryConstants';
import { clearErrors, addInventory } from "../../../actions/inventoryAction";
import {
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';
import './AddInventoryPopup.css'; // Import CSS file for styling

const AddInventoryPopup = ({ onSave, onCancel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.user);
    const { addinventory, error,inventorys } = useSelector((state) => state.inventory);
    const [name, setName] = useState('');
    const [sku, setSKU] = useState('');
    const [type, setType] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (addinventory) {
            // enqueueSnackbar("Inventory Added", { variant: "success" });
            navigate(`/update/inventory/`+inventorys?._id);
            // onSave({ name, sku, type });
            setName('');
            setSKU('');
            setType('');
            setErrors({});
            dispatch({ type: NEW_INVENTORY_RESET });
        }
    }, [inventorys?._id,dispatch, error, addinventory, navigate, enqueueSnackbar]);

    const handleSave = () => {
        if (validateForm()) {
            dispatch(addInventory(user?._id, '629743b4c798c617e89d826e', name,sku,type))
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        }

        if (!sku.trim()) {
            newErrors.sku = 'SKU is required';
            valid = false;
        }

        if (!type.trim()) {
            newErrors.type = 'Type is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    return (
        <div className="popup-container"  style={{ width: "400px" }}>
            <div className="popup-inner">
                <Typography variant="h6">Add New Inventory</Typography>
                <div className="input-container">
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                        fullWidth
                    />
                </div>
                <div className="input-container">
                    <TextField
                        label="SKU"
                        value={sku}
                        onChange={(e) => setSKU(e.target.value)}
                        error={!!errors.sku}
                        helperText={errors.sku}
                        fullWidth
                    />
                </div>
                <div className="input-container">
                    <FormControl fullWidth>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            error={!!errors.type}
                        >
                            <MenuItem value="Unique - single unique code per customer">Unique - single unique code per customer</MenuItem>
                            <MenuItem value="Recursive - codes are send in a loop">Recursive - codes are send in a loop</MenuItem>
                        </Select>
                    </FormControl>
                    {errors.type && <Typography variant="caption" color="error">{errors.type}</Typography>}
                </div>
                <div className="button-container">
                    <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '8px' }}>Save</Button>
                    <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                </div>
            </div>
        </div>
    );
};

export default AddInventoryPopup;
