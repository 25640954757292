/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 18:43:50
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { getCheckAllData,getTransactions } from "../../../../actions/transactionsAction";
import { useSelector, useDispatch } from "react-redux";
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f5f5f7" // Set your desired background color here
  }
}));

const columns = [
  {
    flex: 0.25,
    field: "plateform",
    minWidth: 150,
    headerName: "ALERT MESSAGE",
    renderCell: ({ row }) => {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {row.altmsg}
            </Typography>
          </Box>
        </Box>
      );
    }
  },
  {
    flex: 0.2,
    minWidth: 90,
    field: "time",
    headerName: "TIME",
    renderCell: ({ row }) => <Typography variant="body2">{row?.time?.split("T")[0]}</Typography>
  }
];
const EcommerceActivityTimeline = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [alertData, setAlertData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await dispatch(getTransactions());
      await dispatch(getCheckAllData());
    }
    fetchData();
  }, [dispatch]);
  const generateUniqueId = () => {
    return  `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };
  useEffect(() => {
    const { checkTranscationData } = state?.transactionsCheckgetReducer || {};
    const {emailsSent} = checkTranscationData?.results ?? "";
    const [typeInventory, typePending] = [
      emailsSent?.find(item => item?.type === "inventory"),
      emailsSent?.find(item => item?.type === "pending")
    ];
    const checklowinventry =  typeInventory?.type ?? "";
    const pendingMail =  typePending?.type ?? "";
    const alertMessages = [
      {
        
        id: generateUniqueId() ?? "",  
        altmsg: pendingMail,        
        time: typePending?.createdAt ?? ""  
      },
      {
        id: generateUniqueId() ?? "",  
        altmsg: checklowinventry,    
        time: typeInventory?.createdAt ?? ""  
      }
    ];
    setAlertData(alertMessages);
  }, [state?.transactionsCheckgetReducer]);

  return (
    <>
      <Card
        sx={{
          borderRadius: 3,
          boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px"
        }}
      >
        <StyledDataGrid
          autoHeight
          hideFooter
          rows={alertData}
          columns={columns}
          disableRowSelectionOnClick
          pagination={undefined}
        />
      </Card>
    </>
  );
};

export default EcommerceActivityTimeline;
