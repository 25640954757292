/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 18:00:43
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_TRANSCATIONS_REQUEST,
  FETCH_TRANSCATIONS_REQUEST,
  UPDATE_TRANSCATIONS_REQUEST,
  DELETE_TRANSCATIONS_SUCCESS,
  ADD_TRANSCATIONS_SUCCESS,
  GET_TRANSCATIONS_SUCCESS,
  UPDATE_TRANSCATIONS_SUCCESS,
  FETCH_TRANSCATIONS_SUCCESS,
  ADD_TRANSCATIONS_FAIL,
  DELETE_TRANSCATIONS_FAIL,
  UPDATE_TRANSCATIONS_FAIL,
  CHECK_TRANSCATIONS_REQUEST,
  CHECK_TRANSCATIONS_SUCCESS,
  CHECK_TRANSCATIONS_FAIL,
  CHECK_TRANSCATIONS_DASHBORD_REQUEST,
  CHECK_TRANSCATIONS_DASHBORD_SUCCESS,
  CHECK_TRANSCATIONS_DASHBORD_FAIL,
  CHECK_TRANSCATIONS_MassAction_REQUEST,
  CHECK_TRANSCATIONS_MassAction_SUCCESS,
  CHECK_TRANSCATIONS_MassAction_FAIL,
  CLEAR_ERRORS
} from "../constants/transactionsConstants";
import axios from "axios";

//Add Transactions
export const addTransactions =
  (
    email,
    inventoryValue,
    itemName,
    quantity,
    price,
    emailTemplatevalue,
    body,
    platform,
    store
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_TRANSCATIONS_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post(
        "/api/v1/transactions/add",
        {
          email,
          inventoryValue,
          itemName,
          quantity,
          price,
          emailTemplatevalue,
          body,
          platform,
          store
        },
        config
      );
      dispatch({
        type: ADD_TRANSCATIONS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ADD_TRANSCATIONS_FAIL,
        payload: error.response.data.message
      });
    }
  };

//Add sendReplacement
export const sendReplacement =
  (rowData, email, inventoryValue, quantity, emailTemplatevalue, body) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_TRANSCATIONS_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post(
        "/api/v1/transactions/add",
        {
          rowData,
          email,
          inventoryValue,
          quantity,
          emailTemplatevalue,
          body
        },
        config
      );
      dispatch({
        type: ADD_TRANSCATIONS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ADD_TRANSCATIONS_FAIL,
        payload: error.response.data.message
      });
    }
  };

// List Transactions
export const getTransactions = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_TRANSCATIONS_REQUEST });

    const { data } = await axios.get("/api/v1/transactions/all");

    dispatch({
      type: GET_TRANSCATIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ADD_TRANSCATIONS_FAIL,
      payload: error.response.data.message
    });
  }
};

// Get WoocommerceTransactions
export const getWoocommerceTransactions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_TRANSCATIONS_REQUEST });
    const { data } = await axios.get("/api/v1/fetch/transactions/all");
    dispatch({
      type: FETCH_TRANSCATIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_TRANSCATIONS_SUCCESS,
      payload: error.response.data.message
    });
  }
};

// Delete Transactions
export const deleteTransactions = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TRANSCATIONS_REQUEST });
    const { data } = await axios.delete(`/api/v1/transactions/${id}`);

    dispatch({
      type: DELETE_TRANSCATIONS_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_TRANSCATIONS_FAIL,
      payload: error.response.data.message
    });
  }
};

//Update Transactions
export const updateTransactions =
  (transactionsData, type) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TRANSCATIONS_REQUEST });
      const config = { header: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `/api/v1/transactions/${transactionsData?._id}`,
        { transactionsData, type },
        config
      );

      dispatch({
        type: UPDATE_TRANSCATIONS_SUCCESS,
        payload: data.success
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSCATIONS_FAIL,
        payload: error.response.data.message
      });
    }
  };

// Clear All Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const ResendCode = (order_id, email) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TRANSCATIONS_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post(
      "/api/v1/resend/code",
      {
        order_id,
        email
      },
      config
    );
    dispatch({
      type: UPDATE_TRANSCATIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TRANSCATIONS_FAIL,
      payload: error.response.data.message
    });
  }
};

export const getCheckAllData = () => async (dispatch) => {
  try {
    dispatch({ type: CHECK_TRANSCATIONS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post("/api/v1/CheckAlertProduct", config);

    dispatch({
      type: CHECK_TRANSCATIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CHECK_TRANSCATIONS_FAIL,
      payload: error.response?.data?.message || error.message
    });
  }
};

export const getMassActionDataUpdate = (massActionData) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_TRANSCATIONS_MassAction_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.post(
      "/api/v1/updateAction",
      massActionData,
      config
    );
    dispatch({
      type: CHECK_TRANSCATIONS_MassAction_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CHECK_TRANSCATIONS_MassAction_FAIL,
      payload: error.response?.data?.message || error.message
    });
  }
};



export const getDashbordDataTransactions = () => async (dispatch) => {
  try {
    dispatch({ type: CHECK_TRANSCATIONS_DASHBORD_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const { data } = await axios.get("/api/v1/DaliyData", config);
    dispatch({
      type: CHECK_TRANSCATIONS_DASHBORD_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CHECK_TRANSCATIONS_DASHBORD_FAIL,
      payload: error.response?.data?.message || error.message
    });
  }
};
