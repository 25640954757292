/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:16:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_STORE_SUCCESS,
  ADD_STORE_REQUEST,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
  DELETE_STORE_SUCCESS,
  ADD_STORE_FAIL,
  UPDATE_STORE_FAIL,
  DELETE_STORE_FAIL,
  CLEAR_ERRORS,
  NEW_STORE_RESET
} from "../constants/storeConstants";

export const storeReducer = (state = { store: [] }, { type, payload }) => {
  switch (type) {
    case ADD_STORE_REQUEST:
    case UPDATE_STORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_STORE_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        addstore: payload?.addstore,
        stores: payload.stores
      };
    case UPDATE_STORE_SUCCESS:
      return {
        loading: false,
        isUpdated: payload
      };
    case DELETE_STORE_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case ADD_STORE_FAIL:
    case UPDATE_STORE_FAIL:
    case DELETE_STORE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case NEW_STORE_RESET:
      return {
        loading: false,
        addstore: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
