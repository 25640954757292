/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 16:48:34
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Grid, Box, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
const TableHeader = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // ** Props
  const {
    handleFilter,
    setOpenModal,
    setRowValue,
    value,
    type,
    placeholder,
    handleStoreChange,
    handleIntentoryTypeChange,
    handleMaskDelete,
    handleSyncProduct,
    storeName,
    stores,
    IntertoryItemTypeName
  } = props;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box
      sx={{
        p: 2,
        pb: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Grid container spacing={6}>
        <Grid item md={type === "stores" || type === "templates" ? 10 : 4}>
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <TextField
              size="small"
              value={value}
              sx={{ mr: 6, mb: 1 }}
              placeholder={placeholder}
              onChange={(e) => handleFilter(e.target.value)}
            />
          </Box>
        </Grid>
        {type === "products" && (
          <>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="store-select">Select Store</InputLabel>
                <Select
                  fullWidth
                  value={storeName}
                  id="select-store"
                  label="Select Store"
                  labelId="store-select"
                  onChange={handleStoreChange}
                  placeholder="Select Store"
                  sx={{ height: "40px" }}
                  inputProps={{ placeholder: "Select Store" }}
                >
                  {stores &&
                    stores.map((store) => (
                      <MenuItem key={store._id} value={store._id}>
                        {store.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSyncProduct}
              >
                Sync Product
              </Button>
            </Grid>
          </>
        )}

        {type === "inventory" && (
          <>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="store-select">Select</InputLabel>
                <Select
                  fullWidth
                  value={IntertoryItemTypeName}
                  id="select-store"
                  label="Select Store"
                  labelId="store-select"
                  onChange={handleIntentoryTypeChange}
                  placeholder="Select"
                  sx={{ height: "40px" }}
                  inputProps={{ placeholder: "Select" }}
                >
                  <MenuItem value="available">Available</MenuItem>
                  <MenuItem value="Faulty">Faulty</MenuItem>
                  <MenuItem value="SendFirst">SendFirst</MenuItem>
                  <MenuItem value="Expired">Expired</MenuItem>
                  <MenuItem value="Used">Used</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {type === "stores" || type === "templates" ? (
          <Grid item md={2}>
            <Box>
              <Button
                sx={{ mb: 1 }}
                onClick={() => {setOpenModal(true) 
                  setRowValue("")}}
                variant="contained"
              >
                Add New
              </Button>
            </Box>
          </Grid>
        ) : (
          ""
        )}

        {type === "inventory" ? (
          <Grid item md={4}>
            <Box>
              <Button
                sx={{ mb: 1 }}
                variant="contained"
                onClick={handleMenuOpen}
              >
                Mass Action
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {handleMaskDelete("Faulty"); 
                  setAnchorEl(null) }}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  All Mark as faulty
                </MenuItem>
                <MenuItem
                  onClick={() => {handleMaskDelete("SendFirst");
                  setAnchorEl(null)}}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  All Mark as send first
                </MenuItem>
                <MenuItem
                  onClick={() => {handleMaskDelete("Expired"); setAnchorEl(null)}}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  All Mark as expired
                </MenuItem>
                <MenuItem
                  onClick={() => { handleMaskDelete("Used"); setAnchorEl(null)}}
                  sx={{ "& svg": { mr: 2 } }}
                >
                  All Mark as used
                </MenuItem>
                <MenuItem onClick={() => { handleMaskDelete("Delete");setAnchorEl(null)}}>
                  All Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
};

export default TableHeader;
