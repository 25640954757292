/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 20/07/2024 - 11:32:28
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 20/07/2024
    * - Author          : admin
    * - Modification    : 
**/

import WebFont from "webfontloader";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import { Routes, Route, useLocation } from "react-router-dom";
import { loadUser } from "./actions/userAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import UpdatePassword from "./components/User/UpdatePassword";
import ForgotPassword from "./components/User/ForgotPassword";
import FilesList from "./components/User/FilesList";
import ResetPassword from "./components/User/ResetPassword";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Dashboard from "./components/Admin/Dashboard";
import MainDataList from "./components/Admin/MainDataList";
import Inventory from "./components/Admin/Inventory";
import UpdateInventory from "./components/Layouts/Inventory/UpdateInventory";
import Transactions from "./components/Admin/Transactions";
import TransactionsPending from "./components/Admin/TransactionsPending";
import NewTransactions from "./components/Admin/NewTransactions";
import UpdateProduct from "./components/Admin/UpdateProduct";
import UserTable from "./components/Admin/UserTable";
import UpdateUser from "./components/Admin/UpdateUser";
import Settings from "./components/Admin/Settings";
import Security from "./components/Admin/Security";
import Templates from "./components/Admin/Templates";
import Integrations from "./components/Admin/Integrations";
import WoocommerceStores from "./components/Admin/Woocommerce/Stores";
import WoocommerceProducts from "./components/Admin/Woocommerce/Products";
import ViewProduct from "./components/Admin/Woocommerce/ViewProduct";
import NotFound from "./components/NotFound";
import TransactionDetails from "./components/Admin/TransactionDetails";
import CustomerDetail from "./components/Admin/CustomerDetail";
import Smtp from "./components/Admin/Smtp";
import Alerts from "./components/Admin/Alerts";

import Addip from "./components/Admin/Addip";
import Emaildomain from "./components/Admin/EmailDomain";
import UpdateIPForm from "./components/Admin/UpdateAppIPForm";
import EmailDomainForm from "./components/Admin/EmailDomainUpdateForm";
import UpdateSmtp from "./components/Admin/UpdateSmtp";
import UpdateTemplatePopUp from "./components/Admin/UpdateTemplate";
import SalesReport from "./components/Admin/SalesReport";
import ReplacementTemplate from "./components/Admin/ReplacementTemplate";
function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:300,400,500,600,700"]
      }
    });
  });

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
      </ProtectedRoute>
          }
        ></Route> */}

        {/* <Route
          path="/account/update"
          element={
            <ProtectedRoute>
              <UpdateProfile />
      </ProtectedRoute>
          }
        ></Route> */}
        <Route
          path="/password/update"
          element={
            <ProtectedRoute>
              <UpdatePassword />
            </ProtectedRoute>
          }
        ></Route>

        <Route path="/password/forgot" element={<ForgotPassword />} />

        <Route path="/password/reset/:token" element={<ResetPassword />} />

        <Route path="/files/:id" element={<FilesList />} />

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={0}>
                <MainDataList />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/inventory"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={1}>
                <Inventory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/update/inventory/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={1}>
                <UpdateInventory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/transactions"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={5}>
                <Transactions />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/transactions/pending"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={5}>
                <TransactionsPending />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/transactions/new"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={5}>
                <NewTransactions />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/transactions/details"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={5}>
                <TransactionDetails />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/product/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={11}>
                <UpdateProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/whiteblackAddIp"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                {/* <WhiteBlack /> */}
                <Addip />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/whiteblack/detail/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <CustomerDetail />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/whiteblack/update/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>{/* // <CustomerDetail /> */}</Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        {/* <Route
          path="/whiteblack/addip"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <Addip />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route> */}

        {/* <Route
          path="/blackwhitelist/AddIPform"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <AddIPForm />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route> */}

        <Route
          path="/update/addip/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <UpdateIPForm />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/whiteblackEmaildomain"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <Emaildomain />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/update/emaildomain/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <EmailDomainForm />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        {/* <Route
          path="/blackwhitelist/EmailDomainForm"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={6}>
                <EmailDomainForm />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route> */}

        <Route
          path="/customer"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={7}>
                <UserTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/customer/detail/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={7}>
                <CustomerDetail />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/customer/update/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={7}>{/* <CustomerDetail /> */}</Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/user/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={11}>
                <UpdateUser />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/settings"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <Settings />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/settings/security"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <Security />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/settings/alerts"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <Alerts />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/settings/smtp"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <Smtp />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/update/smtp/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={4}>
                <UpdateSmtp />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/templates"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={3}>
                <Templates />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/templates/replacement"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={3}>
              <ReplacementTemplate/>
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/update/template/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={3}>
                <UpdateTemplatePopUp />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/integrations"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={9}>
                <Integrations />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Sales"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={8}>
                <SalesReport />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/woocommerce/stores"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={2}>
                <WoocommerceStores />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/woocommerce/products"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={2}>
                <WoocommerceProducts />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/woocommerce/products/view/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard activeTab={2}>
                <ViewProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
