/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 25/07/2024 - 12:39:56
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/07/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Button,
  Divider,
  Checkbox
} from "@mui/material";
import BackdropLoader from "../../Layouts/BackdropLoader";
import MetaData from "../../Layouts/MetaData";
import RouterBreadcrumbs from "../../Layouts/Breadcrumbs";
import {
  clearErrors,
  updateProduct,
  getProductDetails
} from "../../../actions/productAction";
import { UPDATE_PRODUCT_RESET, REMOVE_PRODUCT_DETAILS } from "../../../constants/productConstants";

const ViewProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, product } = useSelector((state) => state.productDetails);
  const { error, isUpdated } = useSelector((state) => state.products);

  const [sendToNewCustomer, setSendToNewCustomer] = useState(false);
  const [quantityPerTransaction, setQuantityPerTransaction] = useState();
  const [amountPerTransaction, setAmountPerTransaction] = useState();
  const [transactionNumber, setTransactionNumber] = useState();
  const [transactionNumberWithinDays, setTransactionNumberWithinDays] = useState();
  const [amountEachCustomer, setAmountEachCustomer] = useState();
  const [amountWithinFieldByEachCustomer, setAmountWithinFieldByEachCustomer] = useState();

  // State to control checkboxes
  const [quantityPerTransactionEnabled, setQuantityPerTransactionEnabled] = useState(false);
  const [amountPerTransactionEnabled, setAmountPerTransactionEnabled] = useState(false);
  const [transactionNumberEnabled, setTransactionNumberEnabled] = useState(false);
  const [amountEachCustomerEnabled, setAmountEachCustomerEnabled] = useState(false);

  useEffect(() => {
    if (product) {
      setSendToNewCustomer(product?.res_send_to_newCustomer);
      setQuantityPerTransaction(product?.res_quantity_perTransaction);
      setQuantityPerTransactionEnabled(!!product?.res_quantity_perTransaction);
      setAmountPerTransaction(product?.res_amount_perTransaction);
      setAmountPerTransactionEnabled(!!product?.res_amount_perTransaction);
      setTransactionNumber(product?.res_transaction_numberdays);
      setTransactionNumberEnabled(!!product?.res_transaction_numberdays);
      setTransactionNumberWithinDays(product?.res_transaction_within_days);
      setAmountEachCustomer(product?.res_amount_eachCustomer);
      setAmountEachCustomerEnabled(!!product?.res_amount_eachCustomer);
      setAmountWithinFieldByEachCustomer(product?.res_amount_within_eachCustomer);
    }
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Selling Restrictions Updated", { variant: "success" });
      dispatch({ type: REMOVE_PRODUCT_DETAILS });
      dispatch({ type: UPDATE_PRODUCT_RESET });
      navigate(`/woocommerce/products`);
    }
  }, [error, isUpdated, product, dispatch, enqueueSnackbar, navigate]);

  const handleSave = () => {
    const restrictionsData = {
      _id: product?._id,
      res_send_to_newCustomer: sendToNewCustomer,
      res_quantity_perTransaction: quantityPerTransactionEnabled ? quantityPerTransaction : null,
      res_amount_perTransaction: amountPerTransactionEnabled ? amountPerTransaction : null,
      res_transaction_numberdays: transactionNumberEnabled ? transactionNumber : null,
      res_transaction_within_days: transactionNumberEnabled ? transactionNumberWithinDays : null,
      res_amount_eachCustomer: amountEachCustomerEnabled ? amountEachCustomer : null,
      res_amount_within_eachCustomer: amountEachCustomerEnabled ? amountWithinFieldByEachCustomer : null
    };
    dispatch(updateProduct(restrictionsData));
  };

  useEffect(() => {
    dispatch(getProductDetails(id));
  }, [id, dispatch]);

  return (
    <>
      <MetaData title="Products | EZDZsender" />
      {loading && <BackdropLoader />}
      <RouterBreadcrumbs />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <label htmlFor="empty-text-field">Product Name</label>
              <TextField
                id="empty-text-field"
                aria-label="Empty Label"
                value={product?.name}
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="empty-text-field">Price</label>
              <TextField
                id="empty-text-field"
                aria-label="Empty Label"
                type="number"
                value={product?.price}
                disabled
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Set Selling Restrictions</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setSendToNewCustomer(e.target.checked)}
                    checked={sendToNewCustomer}
                  />
                }
                label="Don't send to new customers"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quantityPerTransactionEnabled}
                    onChange={(e) => setQuantityPerTransactionEnabled(e.target.checked)}
                  />
                }
                label="Max Quantity Per Transaction"
              />
              <TextField
                type="number"
                value={quantityPerTransaction}
                onChange={(e) => setQuantityPerTransaction(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!quantityPerTransactionEnabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={amountPerTransactionEnabled}
                    onChange={(e) => setAmountPerTransactionEnabled(e.target.checked)}
                  />
                }
                label="Max Amount Per Transaction"
              />
              <TextField
                type="number"
                value={amountPerTransaction}
                onChange={(e) => setAmountPerTransaction(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!amountPerTransactionEnabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={transactionNumberEnabled}
                    onChange={(e) => setTransactionNumberEnabled(e.target.checked)}
                  />
                }
                label="Tx No"
              />
              <TextField
                type="text"
                value={transactionNumber}
                onChange={(e) => setTransactionNumber(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!transactionNumberEnabled}
              />
            </Grid>
            <Grid item xs={1} style={{ paddingTop: "55px", alignItems: "center" }}>
              {" "}
              Within
            </Grid>
            <Grid item xs={5}>
              <TextField
                type="number"
                value={transactionNumberWithinDays}
                onChange={(e) => setTransactionNumberWithinDays(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!transactionNumberEnabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={amountEachCustomerEnabled}
                    onChange={(e) => setAmountEachCustomerEnabled(e.target.checked)}
                  />
                }
                label="$ Amount"
              />
              <TextField
                type="number"
                value={amountEachCustomer}
                onChange={(e) => setAmountEachCustomer(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!amountEachCustomerEnabled}
              />
            </Grid>
            <Grid item xs={1} style={{ paddingTop: "55px", alignItems: "center" }}>
              {" "}
              Within
            </Grid>
            <Grid item xs={5}>
              <TextField
                type="number"
                value={amountWithinFieldByEachCustomer}
                onChange={(e) => setAmountWithinFieldByEachCustomer(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!amountEachCustomerEnabled}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ViewProduct;
