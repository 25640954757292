/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 10/04/2024 - 14:42:33
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 10/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
// ** Custom Components
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f5f5f7" // Set your desired background color here
  }
}));
const columns = [
  {
    flex: 0.3,
    minWidth: 200,
    field: "Name",
    headerName: "Name",
    renderCell: ({ row }) => (
      <Typography variant="body2">{row?.email?.split("@")[0] || ""}</Typography>
    )
  },
  {
    flex: 0.3,
    minWidth: 100,
    field: "TX NO.",
    headerName: "TX NO.",
    renderCell: ({ row }) => (
      <Typography variant="body2">{row?.transactionCount|| ""}</Typography>
    )
  },

];

const TopCustomerByTransactions = ({AllTranscationData}) => {
  const rows = AllTranscationData || [];
  return (
    <>
      <CardHeader
        sx={{
          padding: 0,
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px"
        }}
        title="Top 10 Customers by Transactions"
        titleTypographyProps={{ sx: { letterSpacing: '0.15px',fontSize: "1.125rem", fontWeight:"600",color:"#636578"  } }}

      />
      <Card sx={{ padding: 0, borderRadius: 3 }}>
          <StyledDataGrid
            autoHeight
            hideFooter
            rows={rows}
            getRowId={(row) => row.id || row.id}
            columns={columns}
            disableRowSelectionOnClick
            pagination={undefined}
          />
      </Card>
    </>
  );
};

export default TopCustomerByTransactions;
