/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 22/07/2024 - 14:22:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 22/07/2024
    * - Author          : admin
    * - Modification    : 
**/
import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alertHandle } from "../../actions/smtpAction";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { useSnackbar } from "notistack";
import {
  Card,
  Grid,
  CardContent,
  Divider,
  CardHeader,
  Box,
  TextField,
  InputAdornment,
  Typography
} from "@mui/material";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";

function Alerts() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [error, setError] = useState(null);
  const [alertState, setAlertState] = useState({
    newHeldOrder: false,
    lowInventory: false,
    lowInventoryLimit: 1,
    customer_data: {
      firstname: user?.user?.firstname || "",
      email: user?.user?.email || "",
      _id: user?.user?._id || ""
    }
  });

  const { alert } = useSelector((state) => state.alert);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(alertHandle());
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (alert?.updatedAlertData) {
      setAlertState((prevState) => ({
        ...prevState,
        ...alert?.updatedAlertData
      }));
    }
  }, [alert?.updatedAlertData]);

  const handleSwitchChange = (event) => {
    try {
      const { name, checked } = event.target;
      const newState = { ...alertState, [name]: checked };
      setAlertState(newState);
      dispatch(alertHandle(newState));
      enqueueSnackbar("Notification sent successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Notification not sent successfully", { variant: "error" });
    }
  };

  const handleLimitChange = (event) => {
    try {
      const { value } = event.target;
      const newValue = Math.max(1, Number(value)); // Ensure the value does not go below 1
      const newState = {
        ...alertState,
        lowInventoryLimit: newValue
      };
      setAlertState(newState);
      dispatch(alertHandle(newState));
      enqueueSnackbar("Notification sent successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Notification not sent successfully", { variant: "error" });
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="My Alerts" />
            <CardContent sx={{ pt: 0 }} />
            <Divider />
            <CardContent>
              <Grid container>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={alertState.newHeldOrder}
                        onChange={handleSwitchChange}
                        name="newHeldOrder"
                      />
                    }
                    label="New Held Order"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={alertState.lowInventory}
                        onChange={handleSwitchChange}
                        name="lowInventory"
                      />
                    }
                    label="Low Inventory"
                  />
                </FormGroup>
              </Grid>
              <Grid container spacing={3}>
                {alertState.lowInventory && (
                  <Grid item xs={12} sm={6}>
                    <Box display="block">
                      <Box display="flex" alignItems="center" mb={3}>
                        <TextField
                          label=""
                          type="number"
                          value={alertState.lowInventoryLimit}
                          onChange={handleLimitChange}
                          name="lowInventoryLimit"
                          id="lowInventoryLimitInput"
                          min="1"
                          InputProps={{
                            inputProps: { min: 1, max: "" },
                            startAdornment: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  backgroundColor: "#E1E8EB",
                                  height: 55,
                                  width: 250,
                                  marginLeft: -13,
                                  border: "0px solid green",
                                  marginRight: 5
                                }}
                              >
                                <InputAdornment position="start">
                                  <div style={{ marginLeft: 10 }}>
                                    Low Inventory Limit
                                  </div>
                                </InputAdornment>
                              </div>
                            )
                          }}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {error && <div>Error: {error.message}</div>}
    </>
  );
}

export default Alerts;
