/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 18:00:43
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_INVENTORY_REQUEST,
  INVENTORY_DETAILS_REQUEST,
  UPLOAD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  INVENTORY_DETAILS_SUCCESS,
  UPLOAD_INVENTORY_SUCCESS,
  UPLOAD_INVENTORY_ITEMS_SUCCESS,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_ITEMS_SUCCESS,
   UPLOAD_INVENTORY_FAIL,
  INVENTORY_DETAILS_FAIL,
  DELETE_INVENTORY_FAIL,
  DELETE_INVENTORY__ITEMS_FAIL,
  ADD_INVENTORY_FAIL,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  UPDATE_INVENTORY_REQUEST,
  CLEAR_ERRORS
} from "../constants/inventoryConstants";
import axios from "axios";

// Add Inventory
export const addInventory =
  (user_id, platform_id, name, sku, type) => async (dispatch) => {
    try {
      dispatch({ type: ADD_INVENTORY_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios.post(
        "/api/v1/inventory/add",
        {
          user_id,
          platform_id,
          name,
          sku,
          type
        },
        config
      );
      dispatch({
        type: ADD_INVENTORY_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ADD_INVENTORY_FAIL,
        payload: error.response.data.message
      });
    }
  };
// List Inventory
export const getInventory = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_INVENTORY_REQUEST });

    const { data } = await axios.get("/api/v1/inventorys/all");

    dispatch({
      type: ADD_INVENTORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ADD_INVENTORY_FAIL,
      payload: error.response.data.message
    });
  }
};
// Delete Inventory
export const deleteInventory = (id) => async (dispatch) => {
  try {
    dispatch({ type: INVENTORY_DETAILS_REQUEST });
    const { data } = await axios.delete(`/api/v1/inventorys/${id}`);

    dispatch({
      type: DELETE_INVENTORY_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_INVENTORY_FAIL,
      payload: error.response.data.message
    });
  }
};
// Details Inventory
export const getInventoryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: INVENTORY_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/inventory/details/${id}`);

    dispatch({
      type: INVENTORY_DETAILS_SUCCESS,
      payload: data.inventory
    });
  } catch (error) {
    dispatch({
      type: INVENTORY_DETAILS_FAIL,
      payload: error.response.data.message
    });
  }
};

export const getuploadInventory = (inventoryId, uploadData) => async (dispatch) => {
    try {
      const formData = new FormData();
      formData.set("name", uploadData?.name);
      formData.set("sku", uploadData?.sku);
      formData.set("usage", uploadData?.usage);
      formData.set("file_type", uploadData?.file_type);
      formData.set("expiration_date", uploadData?.expiration_date);
      formData.set("inventoryItemOld",
        JSON.stringify(uploadData?.inventoryItemOld)
      );
      
      if (uploadData?.file_type === "Files") {
        uploadData?.inventory_item.forEach((file, index) => {
                formData.append(`inventory_item`, file);
              });
      }else{
        formData.set("inventory_item",  JSON.stringify(uploadData?.inventory_item));
      }      
      dispatch({ type:   UPLOAD_INVENTORY_REQUEST });
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.put(
        `/api/v1/upload/inventory/${inventoryId}`,
        formData,
        config
      );
      dispatch({ type: UPLOAD_INVENTORY_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPLOAD_INVENTORY_FAIL,
        payload: error.response.data.message
      });
    }
};

export const getDeleteInventoryItems = (_id, inventoryId) => async (dispatch) => {
    try {
      dispatch({ type: INVENTORY_DETAILS_REQUEST });

      const { data } = await axios.delete(
        `/api/v1/inventory/items/${inventoryId}/${_id}`
      );

      dispatch({
        type: DELETE_INVENTORY_ITEMS_SUCCESS,
        payload: data.success
      });
    } catch (error) {
      dispatch({
        type: DELETE_INVENTORY__ITEMS_FAIL,
        payload: error.response.data.message
      });
    }
};

export const getBulkActionInventoryItems = (inventoryId,selectedRows,status) => async (dispatch) => {
  try {
    dispatch({ type: INVENTORY_DETAILS_REQUEST });
    const config = { header: { "Content-Type": "application/json" } };
      const { data } = await axios.put(`/api/v1/bulkinventory/items/${inventoryId}`,
        {
          status:status,
          selectedRows:selectedRows
        },
        config
      );
      dispatch({
        type: DELETE_INVENTORY_ITEMS_SUCCESS,
        payload: data.success
      });

  } catch (error) {
    dispatch({
      type: DELETE_INVENTORY__ITEMS_FAIL,
      payload: error.response.data.message
    });
  }
};

export const getUpdateInventoryItems = (_id, inventoryId, status) => async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_INVENTORY_REQUEST });
      const config = { header: { "Content-Type": "application/json" } };
      const { data } = await axios.put(`/api/v1/inventory/items/${inventoryId}/${_id}`,
        {status:status},
        config
      );
      dispatch({
        type: UPLOAD_INVENTORY_ITEMS_SUCCESS,
        payload: data.success
      });
    } catch (error) {
      dispatch({
        type: INVENTORY_DETAILS_FAIL,
        payload: error.response.data.message
      });
    }
};
export const updateInventory = (updatedInventoryData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_INVENTORY_REQUEST });
    const config = { header: { "Content-Type": "application/json" } };
    const { data } = await axios.put(`/api/v1/updateInventory`,
      updatedInventoryData,
      config
    );
    dispatch({
      type: UPDATE_INVENTORY_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: UPDATE_INVENTORY_FAIL,
      payload: error.response.data.message
    });
  }

}
// Clear All Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
