/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 09/04/2024 - 11:57:41
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 09/04/2024
    * - Author          : admin
    * - Modification    : 
**/

import { useContext } from 'react'
import { SettingsContext } from '../../@core/context/settingsContext'

export const useSettings = () => useContext(SettingsContext)
