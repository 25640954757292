/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 04/04/2024 - 18:00:43
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_TEMPLATES_REQUEST,
  UPDATE_TEMPLATES_REQUEST,
  ADD_TEMPLATES_SUCCESS,
  DELETE_TEMPLATES_SUCCESS,
  UPDATE_TEMPLATES_SUCCESS,
  ADD_TEMPLATES_FAIL,
  DELETE_TEMPLATES_FAIL,
  UPDATE_TEMPLATES_FAIL,
  CLEAR_ERRORS
} from "../constants/templatesConstants";
import axios from "axios";

// List Templates
export const getTemplates = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_TEMPLATES_REQUEST });

    const { data } = await axios.get("/api/v1/templates/all");

    dispatch({
      type: ADD_TEMPLATES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ADD_TEMPLATES_FAIL,
      payload: error.response.data.message
    });
  }
};

export const addTemplates = (addTemplateData) => async (dispatch) =>{
  try {
    dispatch({ type: ADD_TEMPLATES_REQUEST });
    const config = { header: { "Content-Type": "application/json" } }
    const { data } = await axios.post("/api/v1/templates/add",addTemplateData,config);

    dispatch({
      type: ADD_TEMPLATES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ADD_TEMPLATES_FAIL,
      payload: error.response.data.message
    });
  }
}

// Delete Templates 
export const deleteTemplates = (id) => async (dispatch) => {
  try {
      dispatch({ type: ADD_TEMPLATES_REQUEST });
      const { data } = await axios.delete(`/api/v1/templates/${id}`);

      dispatch({
          type: DELETE_TEMPLATES_SUCCESS,
          payload: data.success,
      });
  } catch (error) {
      dispatch({
          type: DELETE_TEMPLATES_FAIL,
          payload: error.response.data.message,
      });
  }
}

//Update Template
export const updateTemplateList = (templateData) => async (dispatch) => {
  try {
      dispatch({ type: UPDATE_TEMPLATES_REQUEST });
      const config = { header: { "Content-Type": "application/json" } }
      const { data } = await axios.put(`/api/v1/templates/${templateData?._id}`, templateData, config);

      dispatch({
          type: UPDATE_TEMPLATES_SUCCESS,
          payload: data.success,
      });
  } catch (error) {
      dispatch({
          type: UPDATE_TEMPLATES_FAIL,
          payload: error.response.data.message,
      });
  }
};


// Clear All Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
