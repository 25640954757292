/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 11/04/2024 - 17:02:26
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/04/2024
    * - Author          : admin
    * - Modification    : 
**/
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const breadcrumbNameMap = {
  '/inventory': 'Inventory',
  '/update': 'Inventory',
  '/update/inventory': 'Details',
  '/customer':'Customer',
  '/woocommerce':'Woocommerce',
  '/woocommerce/stores':'Stores',
  '/woocommerce/products':'Products',
  '/woocommerce/products/view':'Details',
  '/transactions':'Transactions',
  '/transactions/new':'New',
  '/transactions/pending':'Pending',
  '/transactions/details':'Details',
  '/templates':'Templates',
  '/whiteblackEmaildomain':'Whiteblack/Add Email/Domain',
  '/whiteblackAddIp':'Whiteblack/Add Ip',
  '/settings':'Settings',
  '/settings/smtp':'SMTP',
  '/settings/security':'Security',
  '/settings/alerts':'Alerts',
  '/templates/replacement':'Replacement',
  '/Sales':'Sales Report',
};
function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function RouterBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const paths = pathnames.map((_, index) => `/${pathnames.slice(0, index + 1).join('/')}`);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter  underline="hover" color="inherit" to="/admin/dashboard">
        Home
      </LinkRouter>
      {paths.map((path, index) => {
        const last = index === paths.length - 1;
        const breadcrumbText = breadcrumbNameMap[path] || '';
        // const linkTo = last ? null : path;+
        return last ? (
          <Typography color="text.primary" key={path}>
            {breadcrumbText}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit"  key={path}>
            {breadcrumbText}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}

export default RouterBreadcrumbs;
