/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 09/04/2024 - 18:43:50
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 09/04/2024
 * - Author          : admin
 * - Modification    :
 **/
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f5f5f7" // Set your desired background color here
  }
}));


const columns = [
  {
    flex: 0.25,
    field: "plateform",
    minWidth: 150,
    headerName: "NAME",
    renderCell: ({ row }) => {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {row?.email?.split("@")[0]}
            </Typography>
          </Box>
        </Box>
      );
    }
  },
  {
    flex: 0.3,
    minWidth: 140,
    field: "amount",
    headerName: "AMOUNT",
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.totalAmount}</Typography>
    )
  },
 
];
const EcommerceTable = ({ AllTranscationData }) => {
  const rows = AllTranscationData || [];
  return (
    <>
      <CardHeader
        sx={{
          padding: 0,
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px"
        }}
        title="Top 10 Customers by Amount"
        titleTypographyProps={{ sx: { letterSpacing: '0.15px',fontSize: "1.125rem", fontWeight:"600",color:"#636578"  } }}

      />
      <Card sx={{ borderRadius: 3 }}>
        <StyledDataGrid
          autoHeight
          hideFooter
          rows={rows}
          getRowId={(row) => row._id || row.id}
          columns={columns}
          disableRowSelectionOnClick
          pagination={undefined}
        />
      </Card>
    </>
  );
};

export default EcommerceTable;
