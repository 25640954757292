/**
 * @description      : Advanced table with all features including dropdown, add button, search bar, pagination, and export button.
 * @author           : admin
 * @group            :
 * @created          : 30/05/2024 - 14:37:53
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 30/05/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Icon from "../../@core/components/icon";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { format } from "date-fns";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import CustomChip from "../../../src/@core/components/mui/chip";
import {
  Button,
  Box,
  MenuItem,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  Dialog,
  InputLabel,
  DialogTitle,
  DialogContent,
  InputAdornment,
  IconButton
} from "@mui/material";
import Actions from "./Actions";
import { smtpApi, getSmtpData, deleteSmtpData } from "../../actions/smtpAction";
import { useNavigate } from "react-router-dom";

const initialState = {
  user_id: "",
  smtpname: "",
  from: "",
  replyto: "",
  cc: "",
  host: "",
  username: "",
  Password: "",
  port: "",
  encryption: "tls",
  status: "Active"
};

const userStatusObj = {
  Active: "success",
  Inactive: "error"
};

const Smtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const state = useSelector((state) => state);
  const { allSmtpData } = state?.smtpAllData ? state?.smtpAllData : "";
  const { smtpData } = allSmtpData ? allSmtpData : "";
  const { user } = useSelector((state) => state.user);
  const [formData, setFormData] = useState(initialState);
  const user_id = user ? user?._id : "";
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [UpdateSmtp, setUpdateSmtp] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePageChange = (page) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };
  const handleMenuClick = () => {
    let csvContent = "";
    csvContent +=
      "Smtp Name,From,Reply TO,CC,Host,User Name,Password,Port,Encription,Status\n";
    const ExportData = smtpData?.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );
    ExportData?.forEach((smtp) => {
      const smtpName = smtp?.smtpname ?? "";
      const from = smtp?.from ?? "";
      const replyTO = smtp?.replyto ?? "";
      const cc = smtp?.cc ?? "";
      const host = smtp?.host ?? "";
      const userName = smtp?.username ?? "";
      const password = smtp?.password ?? "";
      const port = smtp?.port ?? "";
      const encription = smtp?.encryption ?? "";
      const status = smtp?.status ?? "";
      csvContent += `${smtpName},${from},${replyTO},${cc},${host},${userName},${password},${port},${encription},${status},${
        smtp?.createdAt
          ? format(new Date(smtp.createdAt), "yyyy-MM-dd HH:mm:ss")
          : ""
      }\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "smtps.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const deleteAddIpHandler = async (id) => {
   await dispatch(deleteSmtpData(id));
    dispatch(getSmtpData(user_id));
  };
  useEffect(() => {
    setTimeout(() => {
      dispatch(getSmtpData(user_id));
    }, 500);
  }, [dispatch, user_id]);
  const [errors, setErrors] = useState({
    smtpname: "",
    from: "",
    replyto: "",
    cc: "",
    host: "",
    username: "",
    Password: "",
    port: "",
    encryption: "",
    status: ""
  });
  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    const queryLowered = event.target.value.toLowerCase();
    const filteredData = smtpData.filter(
      (smtp) =>
        smtp.host.toLowerCase().includes(queryLowered) ||
        smtp.smtpname.toLowerCase().includes(queryLowered) ||
        smtp.username.toLowerCase().includes(queryLowered)
    );
    setUpdateSmtp(filteredData);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    dispatch(
      smtpApi({
        user_id: user_id,
        smtpname: "Default",
        from: "noreply@ezdzsend.com",
        replyto: "marsfw24+test1@gmail.com",
        cc: "mars@gmail.com",
        host: "srv1.ezdzsend.com",
        username: "noreply@ezdzsend.com",
        Password: "Freelancer2024$",
        port: "587",
        encryption: "tls",
        status: "Active"
      })
    );
  }, [user_id, dispatch]);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = { ...formData, user_id };
    setFormData(updatedFormData);

    let valid = true;
    const newErrors = { ...errors };

    if (formData.smtpname.trim() === "") {
      newErrors.smtpname = "Smtp Name is required";
      valid = false;
    }
    if (formData.from.trim() === "") {
      newErrors.from = "This field is required";
      valid = false;
    }
    if (formData.replyto.trim() === "") {
      newErrors.replyto = "This field is required";
      valid = false;
    }
    if (formData.cc.trim() === "") {
      newErrors.cc = "This field is required";
      valid = false;
    }
    if (formData.host.trim() === "") {
      newErrors.host = "Host Name is required";
      valid = false;
    }
    if (formData.username.trim() === "") {
      newErrors.username = "Username is required";
      valid = false;
    }
    if (formData.Password.trim() === "") {
      newErrors.Password = "Password is required";
      valid = false;
    }
    if (formData.port.trim() === "") {
      newErrors.port = "Port is required";
      valid = false;
    }
    setErrors(newErrors);
    if (valid) {
      dispatch(smtpApi(updatedFormData));
      navigate("/settings/smtp");
      enqueueSnackbar("Insert Smtp Data successful", { variant: "success" });
      handleClose(false);
      dispatch(getSmtpData(user_id));
    }
  };
  const DialogBoxhandle = () => {
    setOpenModal(true);
  };
  const columns = [
    {
      field: "Smtp Name",
      headerName: "Smtp Name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.smtpname}</div>
      )
    },
    {
      field: "From",
      headerName: "From",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.from}</div>
      )
    },
    {
      field: "Reply TO",
      headerName: "Reply TO",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.replyto}</div>
      )
    },
    {
      field: "CC",
      headerName: "CC",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">{params.row.cc}</div>
      )
    },
    {
      field: "Host",
      headerName: "Host",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">xxxx</div>
      )
    },
    {
      field: "User Name",
      headerName: "User Name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">xxxx</div>
      )
    },
    {
      field: "Password",
      headerName: "Password",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">xxxx</div>
      )
    },
    {
      field: "Port",
      headerName: "Port",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">xxxx</div>
      )
    },
    {
      field: "Encription",
      headerName: "Encription",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">xxxx</div>
      )
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomChip
          skin="light"
          size="small"
          label={params.row.status}
          color={userStatusObj[params.row.status]}
          sx={{
            textTransform: "capitalize",
            "& .MuiChip-label": { lineHeight: "18px" }
          }}
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"update/smtp"}
            deleteHandler={deleteAddIpHandler}
            id={params.row.id}
          />
        );
      }
    }
  ];
  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        <RouterBreadcrumbs />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box bgcolor="#f5f5f5" borderRadius="xl" boxShadow={3} p={2} mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" style={{ color: "grey" }}>
                  SMTP
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Icon icon="mdi:export-variant" fontSize={20} />}
                    onClick={handleMenuClick}
                    style={{ marginRight: 10 }}
                  >
                    EXPORT
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={DialogBoxhandle}
                    component={Link}
                  >
                    + ADD NEW RECORD
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControl size="small">
                <label htmlFor="empty-text-field">
                  <Typography variant="h5" style={{ color: "grey" }}>
                    <span style={{ fontSize: "15px" }}> Entites per page</span>
                  </Typography>
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  style={{ marginRight: "8px", color: "grey" }}
                >
                  Search:
                </Typography>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <DataGrid
                autoHeight
                rows={
                  UpdateSmtp?.length > 0
                    ? UpdateSmtp?.map((store) => ({ ...store, id: store._id }))
                    : smtpData && smtpData.length > 0
                    ? smtpData.map((store) => ({ ...store, id: store._id }))
                    : []
                }
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                onPageChange={(page) => handlePageChange(page)}
                onPaginationModelChange={handlePaginationModelChange}
                pageSize={paginationModel.pageSize}
                paginationMode={paginationModel}
                pageSizeOptions={[10, 25, 50]}
                pagination={true}
                style={{ backgroundColor: "#f5f5f5" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        classes={{ minHeight: "80vh", maxHeight: "80vh" }}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"SMTP Configuration"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ margin: "5px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SMTP Name"
                  name="smtpname"
                  value={formData.smtpname}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.smtpname)}
                  helperText={errors.smtpname}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="From"
                  name="from"
                  value={formData.from}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.from)}
                  helperText={errors.from}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Reply To"
                  name="replyto"
                  value={formData.replyto}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.replyto)}
                  helperText={errors.replyto}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CC"
                  name="cc"
                  value={formData.cc}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.cc)}
                  helperText={errors.cc}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Host"
                  name="host"
                  value={formData.host}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.host)}
                  helperText={errors.host}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.username)}
                  helperText={errors.username}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="Password"
                  value={formData.Password}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Port"
                  name="port"
                  value={formData.port}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(errors.port)}
                  helperText={errors.port}
                  InputProps={{
                    sx: { borderRadius: 3 }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Encryption</InputLabel>
                  <Select
                    label="Encryption"
                    name="encryption"
                    value={formData.encryption}
                    onChange={handleChange}
                  >
                    <MenuItem value="encryption">No encryption</MenuItem>
                    <MenuItem value="ssl">SSL</MenuItem>
                    <MenuItem value="tls">TLS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                  Submit
                </Button>
                <Button
                  type="reset"
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Smtp;
