/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 11/04/2024 - 10:59:17
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/04/2024
    * - Author          : admin
    * - Modification    : 
**/
// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import ErrorIcon from '@mui/icons-material/Error';
const CardStatsVertical = props => {
  // ** 
  const { title, stats = 'positive' } = props
  return (
<Card sx={{ borderRadius: 3,boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px' }}>
  <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ mb: 1 }}>
      <ErrorIcon sx={{ color: 'red', width: 70, height: 70 }} />
    </Box>
    <Typography variant='h6' sx={{ mb: 1 }}>
      {stats}
    </Typography>
    <Typography variant='body2' sx={{ mb: 0 }}>
      {title}
    </Typography>
  </CardContent>
</Card>
  )
}

export default CardStatsVertical
