/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 10/04/2024 - 17:01:57
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 10/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import { useState, Fragment,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { logoutUser } from "../../../actions/userAction";
import {MenuItem,Menu,Box,Typography,Divider} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
const Header = ({ setToggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [imgsrc, setImgSrc] = useState(null);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const BadgeContentSpan = styled("span")(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  }));
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = url => {
    if (url) {
      navigate('/settings');
    }
    setAnchorEl(null)
  }
  useEffect(() => {
    setImgSrc( 
      user?.profile);
  },[user]);
  const handleLogout = () => {
    dispatch(logoutUser());
    enqueueSnackbar("Logout Successfully", { variant: "success" });
    navigate("/");
  };

  return (
    <header className="bg-primary-blue fixed top-0 py-2.4 z-10">
      <div className="w-full sm:w-11/12 px-1 sm:px-4 m-auto flex justify-between items-center relative">
        <div className="flex items-center flex-1">
        <Link className="h-7 mr-1 sm:mr-4" to="/">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              alt="EZDZsender Logo"
              onClick={() => setToggleSidebar(true)}
            >
              <MenuIcon />
            </IconButton>
          </Link>
        </div>
        <div className="flex items-center justify-between ml-1 sm:ml-0 gap-0.5 sm:gap-7 relative">
          {isAuthenticated === false ? (
            <Link
              to="/login"
              className="px-3 sm:px-9 py-0.5 text-primary-blue font-medium rounded-sm cursor-pointer"
            >
              {" "}
              John
            </Link>
          ) : (
            <span className="userDropDown flex items-center text-white font-medium gap-1 cursor-pointer">
              <Fragment>
                <Badge
                  overlap="circular"
                  // onClick={handleDropdownOpen}
                  sx={{ ml: 2, cursor: "pointer" }}
                  badgeContent={<BadgeContentSpan />}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                >
                  <Avatar
                    alt="John Doe"
                    onClick={handleDropdownOpen}
                    sx={{ width: 40, height: 40 }}
                    src={imgsrc ? imgsrc:"/images/avatars/1.png"} 
                  />
                </Badge>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                 onClose={() => handleDropdownClose()}
                  sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal:  "right" ? "right" : "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right" ? "right" : "left"
                  }}
                >
                  <Box sx={{ pt: 2, pb: 3, px: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}
                     onClick={() => handleDropdownClose('/settings')}
                    >
                      <Badge
                        overlap="circular"
                        badgeContent={<BadgeContentSpan />}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                      >
                        <Avatar
                          alt="John Doe"
                          src={imgsrc ? imgsrc:"/images/avatars/1.png"} 
                          sx={{ width: "2.5rem", height: "2.5rem" }}
                        />
                      </Badge>
                      <Box
                        sx={{
                          display: "flex",
                          ml: 3,
                          alignItems: "flex-start",
                          flexDirection: "column"
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                        {user.username && user.username.split(" ", 1)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.8rem", color: "text.disabled" }}
                        >
                          Admin
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      py: 2,
                      "& svg": {
                        mr: 2,
                        fontSize: "1.375rem",
                        color: "text.primary"
                      }
                    }}
                  >
                   <span>{<LogoutIcon />}</span>
                   <span>Logout</span>
                  </MenuItem>
                </Menu>
              </Fragment>
            </span>
          )}
        </div>
      </div>
    </header>

  );
};

export default Header;
