/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 16/05/2024 - 11:24:41
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 16/05/2024
 * - Author          : admin
 * - Modification    :
 **/
import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import RouterBreadcrumbs from "../Layouts/Breadcrumbs";
import { format } from "date-fns";
import { getStores } from "../../actions/storeAction";
import { updateCustomers } from "../../actions/customerAction";
import { NEW_CUSTOMERS_RESET } from "../../constants/customerConstants";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import {
  ResendCode,
  sendReplacement,
  updateTransactions
} from "../../actions/transactionsAction";

const TransactionDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isUpdated } = useSelector((state) => state.customers);
  const { stores } = useSelector((state) => state.store);
  const { rowData } = location.state || {};
  const [selectedButton, setSelectedButton] = useState("");
  const [logs, setLogs] = useState([]);
  let store = "";

  if (stores && rowData) {
    const StoreData = stores.find((store) => store._id === rowData?.store_id);
    store = StoreData?.name || "";
  }

  const handleBlackWhite = (e, type) => {
    setSelectedButton(type);
    if (type === "Allowlisted") {
      rowData.tx_status = "whitelist";
      type = "Success";
      dispatch(updateTransactions(rowData, type));
    } else if (type === "Blocklisted") {
      rowData.tx_status = "blacklist";
      type = "Blacklist";
      dispatch(updateTransactions(rowData, type));
    }
    enqueueSnackbar(type + "  Success", { variant: "success" });
    setTimeout(() => {
      navigate("/transactions");
    }, 2000);
  };
  const handleBlackWhiteIP = (e, type) => {
    setSelectedButton(type);
    if (type === "IPAllowlisted") {
      rowData.tx_status = "whitelist";
      type = "Success";
      dispatch(updateTransactions(rowData, type));
    } else if (type === "IPBlocklisted") {
      rowData.tx_status = "blacklist";
      type = "Blacklist";
      dispatch(updateTransactions(rowData, type));
    }
    enqueueSnackbar(type + "  Success", { variant: "success" });
    setTimeout(() => {
      navigate("/transactions");
    }, 2000);
  };

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);
    if (buttonType === "Replacement") {
      dispatch(
        sendReplacement(
          rowData,
          rowData?.billing?.email,
          rowData?.billing?.inventory_id,
          rowData?.billing?.quantity,
          rowData?.billing?.template_id,
          ""
        )
      );
    } else if (buttonType === "Resend") {
      dispatch(ResendCode(rowData?._id, rowData?.billing?.email));
    } else {
      dispatch(updateTransactions(rowData, buttonType));
    }
    enqueueSnackbar(buttonType + "Success", { variant: "success" });
    setTimeout(() => {
      navigate("/transactions");
    }, 2000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/v1/logs/${rowData?._id}`);
        if (data?.success) {
          setLogs(data?.logs);
        }
        dispatch(getStores());
        if (isUpdated) {
          enqueueSnackbar("Customer updated", { variant: "success" });
          dispatch({ type: NEW_CUSTOMERS_RESET });
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };
  
    fetchData();
  }, [rowData?._id, isUpdated, dispatch, enqueueSnackbar]);
  

  

  const sendFiles = rowData?.line_items?.map((item) => item.sendfile) || [];
  const sumOfPrices = useMemo(
    () =>
      rowData?.line_items.reduce(
        (acc, item) => acc + item.total * item.quantity,
        0
      ),
    [rowData?.line_items]
  );

  const columns = useMemo(
    () => [
      { flex: 0.2, minWidth: 200, field: "name", headerName: "Product" },
      { flex: 0.2, minWidth: 40, field: "quantity", headerName: "Quantity" },
      { flex: 0.2, minWidth: 10, field: "total", headerName: "Price" }
    ],
    []
  );

  const footerRow = {
    id: "footer",
    name: "Subtotal",
    quantity: "",
    total: Number(sumOfPrices).toFixed(2)
  };
  const PaymentMethod = {
    id: "PaymentMethod",
    name: "Payment method",
    quantity: "",
    total: rowData?.payment_method_title
  };
  const Total = {
    id: "total",
    name: "Total",
    quantity: "",
    total: Number(sumOfPrices).toFixed(2)
  };

  return (
    <>
      <RouterBreadcrumbs />
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardHeader
                title={`#${rowData?.transaction_id}`}
                subheader={`Order ID : ${rowData?.order_id}`}
                sx={{
                  pb: 0,
                  "& .MuiCardHeader-title": { letterSpacing: ".15px" }
                }}
              />
              <DataGrid
                autoHeight
                rows={[...rowData?.line_items, footerRow, PaymentMethod, Total]}
                columns={columns}
                getRowId={(row) => row._id || row.id}
                hideFooterPagination
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Platform
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">{store}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Store
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">Woocommerce</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Customer Name
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">
                {rowData?.billing?.first_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Customer IP
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                {rowData?.customer_ip_address}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant={
                  selectedButton === "IPAllowlisted" ? "contained" : "outlined"
                }
                onClick={(e) => handleBlackWhiteIP(e, "IPAllowlisted")}
                sx={{ mr: 1 }}
              >
                Add to WhiteList
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant={
                  selectedButton === "IPBlocklisted" ? "contained" : "outlined"
                }
                onClick={(e) => handleBlackWhiteIP(e, "IPBlocklisted")}
                sx={{ mr: 1 }}
              >
                Add to BlackList
              </Button>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Customer Email
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body1">{rowData?.billing?.email}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant={
                  selectedButton === "Allowlisted" ? "contained" : "outlined"
                }
                onClick={(e) => handleBlackWhite(e, "Allowlisted")}
                sx={{ mr: 1 }}
              >
                Add to WhiteList
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant={
                  selectedButton === "Blocklisted" ? "contained" : "outlined"
                }
                onClick={(e) => handleBlackWhite(e, "Blocklisted")}
                sx={{ mr: 1 }}
              >
                Add to BlackList
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Time
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">
                {format(new Date(rowData?.date_created), "yyyy-MM-dd HH:mm:ss")}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Code Download Link
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {sendFiles.map((url, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  sx={{ color: "#007bff" }}
                >
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {url}
                  </a>
                </Typography>
              ))}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">{rowData?.tx_status}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Left side - Buttons */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        SEND AS
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant={
                          selectedButton === "Replacement"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleButtonClick("Replacement")}
                        sx={{ mr: 1 }}
                      >
                        Replacement
                      </Button>
                      <Button
                        variant={
                          selectedButton === "Resend" ? "contained" : "outlined"
                        }
                        onClick={() => handleButtonClick("Resend")}
                        sx={{ mr: 1 }}
                      >
                        Resend
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        MARK AS
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant={
                          selectedButton === "Refund" ? "contained" : "outlined"
                        }
                        onClick={() => handleButtonClick("Refund")}
                        sx={{ mr: 1 }}
                      >
                        Refund
                      </Button>
                      <Button
                        variant={
                          selectedButton === "Fraud" ? "contained" : "outlined"
                        }
                        onClick={() => handleButtonClick("Fraud")}
                        sx={{ mr: 1 }}
                      >
                        Fraud
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        MARK AS
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant={
                          selectedButton === "Accept" ? "contained" : "outlined"
                        }
                        onClick={() => handleButtonClick("Accept")}
                        sx={{ mr: 1 }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant={
                          selectedButton === "Cancel" ? "contained" : "outlined"
                        }
                        onClick={() => handleButtonClick("Cancel")}
                        sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right side - Normal table */}
                <Grid item xs={12} md={6}>
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: "269px" }}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>IP Logs</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {logs.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {format(
                                new Date(item.created_at),
                                "yyyy-MM-dd HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell>{item.ip_address}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default TransactionDetails;
