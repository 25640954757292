/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 18/04/2024 - 14:23:49
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 18/04/2024
    * - Author          : admin
    * - Modification    : 
**/
export const ADD_TRANSCATIONS_REQUEST = "ADD_TRANSCATIONS_REQUEST";
export const FETCH_TRANSCATIONS_REQUEST = "FETCH_TRANSCATIONS_REQUEST";
export const UPDATE_TRANSCATIONS_REQUEST = "UPDATE_TRANSCATIONS_REQUEST";
export const UPDATE_TRANSCATIONS_SUCCESS = "UPDATE_TRANSCATIONS_SUCCESS";
export const FETCH_TRANSCATIONS_SUCCESS = "FETCH_TRANSCATIONS_SUCCESS";
export const ADD_TRANSCATIONS_SUCCESS = "ADD_TRANSCATIONS_SUCCESS";
export const GET_TRANSCATIONS_SUCCESS = "GET_TRANSCATIONS_SUCCESS";
export const DELETE_TRANSCATIONS_SUCCESS = "DELETE_TRANSCATIONS_SUCCESS";
export const ADD_TRANSCATIONS_FAIL = "ADD_TRANSCATIONS_FAIL";
export const UPDATE_TRANSCATIONS_FAIL = "UPDATE_TRANSCATIONS_FAIL";
export const DELETE_TRANSCATIONS_FAIL = "DELETE_TRANSCATIONS_FAIL";
export const FETCH_TRANSCATIONS_FAIL = "FETCH_TRANSCATIONS_FAIL";
export const NEW_TRANSCATIONS_RESET = "NEW_TRANSCATIONS_RESET";
export const FETCH_TRANSCATIONS_RESET = "FETCH_TRANSCATIONS_RESET";

export const CHECK_TRANSCATIONS_REQUEST = "CHECK_TRANSCATIONS_REQUEST";
export const CHECK_TRANSCATIONS_SUCCESS = "CHECK_TRANSCATIONS_SUCCESS";
export const CHECK_TRANSCATIONS_FAIL = "CHECK_TRANSCATIONS_FAIL";


export const CHECK_TRANSCATIONS_DASHBORD_SUCCESS = "CHECK_TRANSCATIONS_DASHBORD_SUCCESS";
export const CHECK_TRANSCATIONS_DASHBORD_REQUEST = "CHECK_TRANSCATIONS_DASHBORD_REQUEST";
export const CHECK_TRANSCATIONS_DASHBORD_FAIL = "CHECK_TRANSCATIONS_DASHBORD_FAIL";

export const CHECK_TRANSCATIONS_MassAction_REQUEST = "CHECK_TRANSCATIONS_MassAction_REQUEST";
export const CHECK_TRANSCATIONS_MassAction_SUCCESS = "CHECK_TRANSCATIONS_MassAction_SUCCESS";
export const CHECK_TRANSCATIONS_MassAction_FAIL = "CHECK_TRANSCATIONS_MassAction_FAIL";

export const  GET_SALESData_REQUEST  ="GET_SALESData_REQUEST";
export const  GET_SALESData_SUCCESS ="GET_SALESData_SUCCESS";
export const  GET_SALESData_FAIL ="GET_SALESData_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";