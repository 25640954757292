/**
 * @description      :
 * @author           : admin
 * @group            :
 * @created          : 18/04/2024 - 17:16:29
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 18/04/2024
 * - Author          : admin
 * - Modification    :
 **/
import {
  ADD_INVENTORY_REQUEST,
  INVENTORY_DETAILS_REQUEST,
  UPDATE_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  INVENTORY_DETAILS_SUCCESS,
  UPDATE_INVENTORY_SUCCESS,
  UPLOAD_INVENTORY_SUCCESS,
  UPLOAD_INVENTORY_ITEMS_SUCCESS,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_ITEMS_SUCCESS,
  ADD_INVENTORY_FAIL,
  UPDATE_INVENTORY_FAIL,
  INVENTORY_DETAILS_FAIL,
  DELETE_INVENTORY_FAIL,
  DELETE_INVENTORY__ITEMS_FAIL,
  REMOVE_INVENTORY_DETAILS,
  CLEAR_ERRORS,
  NEW_INVENTORY_RESET,
  UPLOAD_INVENTORY_RESET,
} from "../constants/inventoryConstants";

export const inventoryReducer = (
  state = { inventorys: [] },
  { type, payload }
) => {
  switch (type) {
    case ADD_INVENTORY_REQUEST:
    case UPDATE_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_INVENTORY_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        addinventory: payload?.addinventory,
        inventorys: payload.inventorys
      };
    case UPDATE_INVENTORY_SUCCESS:
      return {
        loading: false,
        isUpdated: payload
      };
    case DELETE_INVENTORY_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case ADD_INVENTORY_FAIL:
    case UPDATE_INVENTORY_FAIL:
    case DELETE_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case NEW_INVENTORY_RESET:
      return {
        loading: false,
        addinventory: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export const inventoryDetailsReducer = (
  state = { inventory: {} },
  { type, payload }
) => {
  switch (type) {
    case INVENTORY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case INVENTORY_DETAILS_SUCCESS:
      return {
        loading: false,
        inventory: payload
      };
      case UPLOAD_INVENTORY_ITEMS_SUCCESS:
        return {
          loading: false,
          isUpdated: payload
      };
      case DELETE_INVENTORY_ITEMS_SUCCESS:
      return {
        loading: false,
        isDeleted: payload
      };
    case UPLOAD_INVENTORY_SUCCESS:
      return {
        loading: false,
        inventory: payload
    };
    case UPLOAD_INVENTORY_RESET:
      return {
        loading: false,
        isUpdated: false,
        isDeleted: false,
        success: false
      };
    case INVENTORY_DETAILS_FAIL:
    case DELETE_INVENTORY__ITEMS_FAIL:
      return {
        loading: false,
        error: payload
      };
    case REMOVE_INVENTORY_DETAILS:
      return {
        ...state,
        inventory: {}
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
