/**
    * @description      : 
    * @author           : admin
    * @group            : 
    * @created          : 22/05/2024 - 15:34:50
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 22/05/2024
    * - Author          : admin
    * - Modification    : 
**/
export const SMTP_API_REQUEST ="SMTP_API_REQUEST";
export const SMTP_API_SUCCESS ="SMTP_API_SUCCESS";
export const SMTP_API_FAIL ="SMTP_API_FAIL";


export const Alert_API_REQUEST ="Alert_API_REQUEST";
export const Alert_API_SUCCESS ="Alert_API_SUCCESS";
export const Alert_API_FAIL ="Alert_API_FAIL";

export const SMTP_ALLDATA_REQUEST ="SMTP_ALLDATA_REQUEST";
export const SMTP_ALLDATA_SUCCESS ="SMTP_ALLDATA_SUCCESS";
export const SMTP_DATA_FAIL ="SMTP_DATA_FAIL";

export const SMTP_UPDATE_REQUEST ="SMTP_UPDATE_REQUEST";
export const SMTP_UPDATE_SUCCESS ="SMTP_UPDATE_SUCCESS";
export const SMTP_UPDATE_FAIL ="SMTP_UPDATE_FAIL";

export const SMTP_DELTE_REQUEST ="SMTP_DELTE_REQUEST";
export const DELETE_SMTP_DELTE_SUCCESS ="DELETE_SMTP_DELTE_SUCCESS";
export const DELETE_SMTP_DELTE_FAIL ="DELETE_SMTP_DELTE_FAIL";
